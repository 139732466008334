import { AppInsightsProvider } from 'utils/telemetry/app-insights-provider';
import { SkinnyWidgetQueryParams } from 'api/types/common.types';
import { Widget } from 'containers/SkinnyBannerContainer/types';
import { api } from 'api/apibase.service';
import { convertDefaultZoneToUtc } from 'utils/date';
import { useGlobalAlertStore } from 'common/store/client/global-alert/use-global-alert.store';
import { useQuery } from '@tanstack/react-query';

export const useWidgets = (params: SkinnyWidgetQueryParams) => {
  const openAlert = useGlobalAlertStore((store) => store.openAlert);
  return useQuery<Widget[]>(
    ['widgets', params],
    async (): Promise<Widget[]> => {
      const res = await api.base.get('/widgets', {
        params: {
          ...params,
          date: params.date ? convertDefaultZoneToUtc(params.date) : undefined,
        },
      });
      return res.data as Widget[];
    },
    {
      onError: (error) => {
        openAlert({
          text: 'Something went wrong, please try again later',
          severity: 'error',
        });
        AppInsightsProvider.trackError(error as Error);
      },
    },
  );
};
