import { ListItemIcon } from '@mui/material';
import { SkinnyBannerWidgetTypes } from 'containers/SkinnyBannerContainer/types/component.type';
import {
  mappedWidgetsProperties,
} from 'containers/SkinnyBannerContainer/components/PageConfigurator/CreateNewWidgetModalContent/mapped-widgets-properties';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import React, { FC } from 'react';

interface CustomCheckBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  'data-test'?: string;
}

const widgetsTypes = Object.values(mappedWidgetsProperties);

interface Props {
  types: SkinnyBannerWidgetTypes[];
  setTypes: (type: SkinnyBannerWidgetTypes[]) => void;
}

export const WidgetType: FC<Props> = ({ types, setTypes }) => {
  const handleToggle = (value: SkinnyBannerWidgetTypes) => () => {
    const currentIndex = types.indexOf(value);
    const newSelectedType = [...types];

    if (currentIndex === -1) {
      newSelectedType.push(value);
    } else {
      newSelectedType.splice(currentIndex, 1);
    }
    setTypes(newSelectedType);
  };

  return (
    <List>
      {widgetsTypes.map((widget) => {
        const labelId = `checkbox-list-secondary-label-${widget.widgetName}`;
        return (
          <ListItem key={widget.widgetName} disablePadding>
            <ListItemButton onClick={handleToggle(widget.widgetName)}>
              <ListItemIcon>
                <Checkbox
                  edge="end"
                  onChange={handleToggle(widget.widgetName)}
                  checked={types.indexOf(widget.widgetName) !== -1}
                  inputProps={{
                    'aria-labelledby': widget.widgetName,
                    'data-test': `check-${widget.widgetName}`,
                  } as CustomCheckBoxProps}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${widget.title}`} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};
