import * as styles from 'common/components/GenericModal/styles';
import {
  ActionButtons,
  Container,
  Content,
  Image,
} from 'common/components/GenericModal/styles';
import {
  Box, Button, Divider,
} from '@mui/material';
import {
  CreateGeneralTextWidgetData,
  GeneralTextWidget,
  GeneralTextWidgetFormData,
  UpdateGeneralTextWidgetData,
} from 'containers/HomePageContainer/types/general-text-widgets';
import { DateTimePickerField } from 'common/components/DateTimePickerField';
import { Form, Formik } from 'formik';
import { GeneralTextWidgetConfiguration } from 'containers/HomePageContainer/components/PageConfigurator/generalTextModalContent/generalTextConfiguration';
import { Loader } from 'common/components/Loader';
import { LoadingButton } from '@mui/lab';
import { PageType } from 'common/enums/page-type.enum';
import { SourceSelect } from 'common/components/SourceSelect';
import { TextField } from 'common/components/TextField';
import { createGeneralTextWidgetSchema } from 'containers/HomePageContainer/components/PageConfigurator/generalTextModalContent/generaltextSchema';
import { mapGeneralTextFormDataToRequest } from 'containers/HomePageContainer/components/PageConfigurator/generalTextModalContent/mappers/map-generalTextwidget-data-to-request';
import { mapWidgetDataToFormInitialValues } from 'containers/HomePageContainer/components/PageConfigurator/generalTextModalContent/mappers/map-widget-data-to-form';
import { useCreateWidgetMutation } from 'containers/HomePageContainer/store/server/use-create-widget-mutation';
import {
  useDefaultInitialValues,
} from 'containers/HomePageContainer/components/PageConfigurator/generalTextModalContent/hook/use-default-initial-values';
import { useSearch } from 'containers/HomePageContainer/hooks/use-search';
import { useUpdateWidgetMutation } from 'containers/HomePageContainer/store/server/use-update-widget-mutation';
import { useWidget } from 'containers/HomePageContainer/store/server/use-widget';
import CancelIcon from '@mui/icons-material/Cancel';
import GeneralTextWidgetImage from 'assets/widgets/thumbnails/General_Text_Widget.png';
import React, { FC, useCallback } from 'react';
import SaveIcon from '@mui/icons-material/Save';

export interface GeneralTextModalContentProps {
  closeModal: () => void;
  id?: string;
  pageType: PageType;
}

export const GeneralTextModalContent: FC<GeneralTextModalContentProps> = ({
  closeModal,
  id,
  pageType,
}) => {
  const defaultInitialValues = useDefaultInitialValues();
  const { culture } = useSearch();
  const { data, isLoading } = useWidget<GeneralTextWidget>(id);
  const createMutation = useCreateWidgetMutation(closeModal);
  const updateMutation = useUpdateWidgetMutation(closeModal);

  const handleSubmit = useCallback((values: GeneralTextWidgetFormData) => {
    if (id) {
      updateMutation.mutate({
        id,
        data: mapGeneralTextFormDataToRequest(values) as UpdateGeneralTextWidgetData,
      });
    } else {
      createMutation
        .mutate(mapGeneralTextFormDataToRequest(values, culture) as CreateGeneralTextWidgetData);
    }
  }, [id, culture, createMutation, updateMutation]);

  if (id && isLoading) {
    return <Container><Loader isLoading /></Container>;
  }

  return (
    <Formik
      initialValues={data ? mapWidgetDataToFormInitialValues(data) : defaultInitialValues}
      validationSchema={createGeneralTextWidgetSchema}
      onSubmit={handleSubmit}
    >
      {({ dirty }) => (
        <Form>
          <Container>
            <Content>
              <Box display="flex">
                <Image src={GeneralTextWidgetImage} alt="general text widget" />
                <Box pl={3.75}>
                  <TextField name="name" label="Widget Name" fullWidth />
                  <Box display="flex">
                    <DateTimePickerField name="activeFrom" label="Valid from" sx={styles.dateTimePicker} />
                    <DateTimePickerField name="activeTo" label="Valid to" sx={styles.dateTimePicker} />
                    {pageType !== PageType.Plp && (
                      <SourceSelect name="sources" />
                    )}
                  </Box>
                </Box>
              </Box>
              <Divider sx={styles.divider} />
              <GeneralTextWidgetConfiguration />
            </Content>
            <ActionButtons>
              <Button
                onClick={closeModal}
                variant="outlined"
                size="large"
                startIcon={<CancelIcon />}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={createMutation.isLoading || updateMutation.isLoading}
                disabled={data && !dirty}
                variant="contained"
                type="submit"
                size="large"
                startIcon={<SaveIcon />}
              >
                Save
              </LoadingButton>
            </ActionButtons>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

GeneralTextModalContent.defaultProps = {
  id: '',
};
