export enum HomePageWidgetType {
  HomePage = 'homepage',
  CarouselDetailed = 'carouselDetailed',
  GridWidget = 'gridWidget',
  Banner = 'banner',
  SecondaryBanner = 'secondaryBanner',
  CarouselSimplified = 'carouselSimplified',
  CarouselBanners = 'carouselBanners',
  ChipWidget = 'chipWidget',
  GeneralTextWidget = 'generalText',
  NotificationBannerWidget = 'notificationBanner',
  BVSocial = 'bvsocial',
  BannerWithProductList = 'bannerWithProductList',
  VideoWithText = 'videoWithText',
}
