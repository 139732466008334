import { AppInsightsProvider } from 'utils/telemetry/app-insights-provider';
import { Widget, WidgetCreationData } from 'containers/SkinnyBannerContainer/types';
import { api } from 'api/apibase.service';
import { getWidgetLayout } from 'containers/SkinnyBannerContainer/utils/get-widget-layout';
import { useGlobalAlertStore } from 'common/store/client/global-alert/use-global-alert.store';
import { useMutation } from '@tanstack/react-query';
import { useSkinnyBannerWidgetsStore } from 'containers/SkinnyBannerContainer/store/client/use-skinny-banner-widgets.store';
import shallow from 'zustand/shallow';

interface CreateWidgetSuccessResponse<T> {
  data: T;
}

export const useCreateWidgetMutation = <T extends Widget>(
  closeModal: () => void,
) => {
  const widgets = useSkinnyBannerWidgetsStore((store) => store.widgets, shallow);
  const setWidgets = useSkinnyBannerWidgetsStore((store) => store.setWidgets);
  const openAlert = useGlobalAlertStore((store) => store.openAlert);

  return useMutation<CreateWidgetSuccessResponse<T>, Error, WidgetCreationData>(
    (data: WidgetCreationData) => api.base.put('/widgets', data),
    {
      onSuccess: ({ data }) => {
        setWidgets([
          ...(widgets || []),
          {
            id: data.id,
            widgetId: data.widgetId,
            name: data.name,
            type: data.type,
            layout: getWidgetLayout(data),
            activeFrom: data.activeFrom,
            activeTo: data.activeTo,
            sources: data.sources,
          },
        ]);
        openAlert({
          text: 'Widget was created successfully',
          severity: 'success',
        });
        closeModal();
      },
      onError: (error) => {
        AppInsightsProvider.trackError(error);
        openAlert({
          text: `Error occurred during creation, please try again. Error: ${String(error)}`,
          severity: 'error',
        });
      },
    },
  );
};
