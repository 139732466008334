import * as Yup from 'yup';

const stringShouldNotBeEmpty = 'This field is required, must be a non empty string';
const valueMustBeString = 'The value must be a string';
const dateShouldBeSet = 'Date should be set';
const valueMustBeDate = 'The value must be a date';
const activeToMustBeAfterActiveFrom = 'Valid to must be after Valid from';
const sourceArrayShouldNotBeEmpty = 'Source must contain at least one item';
const sourceIsRequired = 'Source is required';

const conditionOptions = {
  is: true,
  then: Yup.string().required(stringShouldNotBeEmpty),
};

export const shopByCategoryWidgetSchema = Yup.object().shape({
  name: Yup.string().min(1).required()
    .typeError(valueMustBeString)
    .required(stringShouldNotBeEmpty),
  categoryTitleDesktop: Yup.string().min(1)
    .typeError(valueMustBeString)
    .when('isActiveDesktop', conditionOptions),
  categoryTitleMobile: Yup.string().min(1)
    .typeError(valueMustBeString)
    .when('isActiveMobile', conditionOptions),
  viewAllButtonTextMobile: Yup.string().min(1)
    .typeError(valueMustBeString)
    .when('isActiveMobile', conditionOptions),
  viewAllButtonTextDesktop: Yup.string().min(1)
    .typeError(valueMustBeString)
    .when('isActiveDesktop', conditionOptions),
  catalogUrlMobile: Yup.string().min(1)
    .typeError(valueMustBeString)
    .when('isActiveMobile', conditionOptions),
  catalogUrlDesktop: Yup.string().min(1)
    .typeError(valueMustBeString)
    .when('isActiveDesktop', conditionOptions),
  sources: Yup.array().of(Yup.string())
    .min(1, sourceArrayShouldNotBeEmpty)
    .required(sourceIsRequired),
  activeFrom: Yup.date()
    .typeError(valueMustBeDate)
    .required(dateShouldBeSet),
  activeTo: Yup.date()
    .typeError(valueMustBeDate)
    .when('activeFrom', (activeFrom) => Yup.date().min(
      activeFrom,
      activeToMustBeAfterActiveFrom,
    ))
    .required(dateShouldBeSet),
});
