import {
  MappedWidgetsPropertiesType,
  mappedWidgetsProperties,
} from 'containers/HomePageContainer/components/PageConfigurator/CreateNewWidgetModalContent/mapped-widgets-properties';
import { TableCell, TableRow } from '@mui/material';
import { Widget } from 'containers/PlpPageContainer/types';
import { convertDateToISOString, convertUtcToDefaultZone } from 'utils/date';
import React, {
  FC,
} from 'react';

interface Props {
  widget: Widget;
  selected: boolean;
  handleClick: (widget: Widget) => void;
}

export const Item: FC<Props> = ({ widget, selected, handleClick }) => {
  const handleOnClick = () => {
    handleClick(widget);
  };

  const createdFor = () => {
    const desktop = widget.content.desktop.available ? 'Desktop' : '';
    const mobile = widget.content.mobile.available ? 'Mobile' : '';
    const comma = widget.content.mobile.available && widget.content.desktop.available ? ',' : '';
    return `${desktop}${comma} ${mobile}`;
  };

  return (
    <TableRow
      onClick={handleOnClick}
      selected={selected}
      hover
      data-test="widget-list-item"
    >
      <TableCell sx={{ width: 100, textOverflow: 'ellipsis', overflow: 'hidden' }}>
        {widget.name}
      </TableCell>
      <TableCell
        data-test={`widget-type-${widget.type}`}
      >
        {mappedWidgetsProperties[widget.type as MappedWidgetsPropertiesType].title}
      </TableCell>
      <TableCell>
        {createdFor()}
      </TableCell>
      <TableCell>
        {convertDateToISOString(new Date(convertUtcToDefaultZone(widget.activeFrom)))}
      </TableCell>
      <TableCell>
        {convertDateToISOString(new Date(convertUtcToDefaultZone(widget.activeTo)))}
      </TableCell>
    </TableRow>
  );
};
