import { Box } from '@mui/material';
import { CreateNewWidgetModalContent } from 'containers/SkinnyBannerContainer/components/PageConfigurator/CreateNewWidgetModalContent';
import { ModalContentType } from 'common/enums/modal-content-type';
import { SkinnyBannerModalContent } from 'containers/SkinnyBannerContainer/components/PageConfigurator/SkinnyBannerModalContent';
import { WidgetList } from 'containers/SkinnyBannerContainer/components/WidgetList';
import React from 'react';

interface Parameters {
  type: ModalContentType;
  closeModal: () => void;
  id?: string;
}

export function getSkinnyBannerModalContent({
  type,
  closeModal,
  id,
}: Parameters) {
  if (
    type === ModalContentType.CreateSkinnyBannerWidget
    || type === ModalContentType.ModifySkinnyBannerWidget
  ) {
    return (
      <SkinnyBannerModalContent closeModal={closeModal} id={id} />
    );
  }
  if (type === ModalContentType.CreateWidget) {
    return (
      <CreateNewWidgetModalContent />
    );
  }
  if (type === ModalContentType.SelectWidget) {
    return (
      <WidgetList handleClose={closeModal} />
    );
  }
  return <Box>{type}</Box>;
}
