import { PageType } from 'common/enums/page-type.enum';
import { WidgetLayout } from 'common/enums/widget-layout.enum';
import Chip from '@mui/material/Chip';
import React, { FC } from 'react';
import Stack from '@mui/material/Stack';

export interface Props {
  layout: WidgetLayout;
  sources: string[];
  pageType: PageType;
  position: number;
}

export const Chips: FC<Props> = ({
  layout,
  sources = ['organic'],
  pageType,
  position = 1,
}) => (
  <Stack direction="row" spacing={1}>
    {pageType !== PageType.Plp && sources.map((source) => <Chip key={`${source}`} label={source} size="small" />)}
    {pageType === PageType.Plp && (<Chip sx={{ backgroundColor: position === 1 ? '#8ED1FC' : '#F78DA7' }} label={position === 1 ? 'Top' : 'Bottom'} size="small" />)}
    {layout === WidgetLayout.Desktop && (<Chip label="Desktop" size="small" />)}
    {layout === WidgetLayout.Mobile && (<Chip label="Mobile" size="small" />)}
    {layout === WidgetLayout.Common && (
      <>
        <Chip label="Mobile" size="small" />
        {' '}
        <Chip label="Desktop" size="small" />
      </>
    )}
  </Stack>
);
