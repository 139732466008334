import {
  ApplicationInsights,
  IDependencyTelemetry,
} from '@microsoft/applicationinsights-web';
import { SeverityLevel } from 'utils/telemetry/severity-level.enum';

export class AppInsightsProvider {
  private static telemetryProvider: AppInsightsProvider;

  private static AI: ApplicationInsights;

  constructor() {
    if (!AppInsightsProvider.telemetryProvider) {
      AppInsightsProvider.telemetryProvider = this;
    }
  }

  public static init(aiInstrumentationKey: string): void {
    this.AI = new ApplicationInsights({
      config: {
        instrumentationKey: aiInstrumentationKey,
      },
    });
    this.AI.loadAppInsights();
    this.AI.trackPageView();
  }

  public static trackMessage(
    error: string,
    severityLevel?: SeverityLevel,
  ): void {
    const ex = new Error(error);
    this.AI.trackException({ exception: ex, severityLevel });
  }

  public static trackError(
    error: Error,
    severityLevel: SeverityLevel = SeverityLevel.Error,
  ): void {
    this.AI.trackException({ exception: error, severityLevel });
  }

  public static trackEvent(name: string): void {
    this.AI.trackEvent({ name });
  }

  public static trackDependency(
    dependency: IDependencyTelemetry | Omit<IDependencyTelemetry, 'id'>,
  ): void {
    this.AI.trackDependencyData({
      id: `${new Date().getTime()} + ${Math.random()}`,
      ...dependency,
    });
  }
}
