import * as styles from 'common/components/GenericModal/styles';
import {
  ActionButtons,
  Container,
  Content,
  Image,
} from 'common/components/GenericModal/styles';
import {
  Box,
  Button, Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import {
  CreateNotificationBannerData,
  NotificationBannerFormData,
  NotificationBannerWidget,
  UpdateNotificationBannerData,
} from 'containers/HomePageContainer/types/notification-banner';
import { DEVICE_ACTIVATION_SWITCH_WARNING } from 'containers/HomePageContainer/components/PageConfigurator/constants';
import { DateTimePickerField } from 'common/components/DateTimePickerField';
import { DndProvider } from 'react-dnd';
import { DndStackContainer } from 'containers/DndStackContainer/DndStackContainer';
import { DraggableItemType } from 'containers/DndStackContainer/enums/draggable-item-type.enum';
import { DraggableNotificationBannerCard } from 'containers/DndStackContainer/types';
import {
  Field, FieldProps, Form, Formik,
} from 'formik';
import { FormikErrorsListener } from 'common/components/FormikErrorsListener';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Loader } from 'common/components/Loader';
import { LoadingButton } from '@mui/lab';
import { NotificationBannerWidgetSchema } from 'containers/HomePageContainer/components/PageConfigurator/NotificationBannerModalContent/notification-banner-widget.schema';
import { PageSelect } from 'common/components/PageSelect';
import { PageType } from 'common/enums/page-type.enum';
import { TextField } from 'common/components/TextField/index';
import { areWidgetItemsEqualToInitialItems } from 'containers/HomePageContainer/utils/are-widget-items-equal-to-initial-items';
import { mapItemsToInitialValues, mapWidgetDataToFormInitialValues } from 'containers/HomePageContainer/components/PageConfigurator/NotificationBannerModalContent/mappers/map-widget-data-to-form-initial-values';
import { mapNotificationBannerFormDataToRequest } from 'containers/HomePageContainer/components/PageConfigurator/NotificationBannerModalContent/mappers/map-notification-banner-form-data-to-request';
import { useCreateWidgetMutation } from 'containers/HomePageContainer/store/server/use-create-widget-mutation';
import {
  useDefaultInitialValues,
} from 'containers/HomePageContainer/components/PageConfigurator/NotificationBannerModalContent/hooks/use-default-initial-values';
import { useGlobalAlertStore } from 'common/store/client/global-alert/use-global-alert.store';
import { useSearch } from 'containers/HomePageContainer/hooks/use-search';
import { useUpdateWidgetMutation } from 'containers/HomePageContainer/store/server/use-update-widget-mutation';
import { useWidget } from 'containers/HomePageContainer/store/server/use-widget';
import { v4 as uuidv4 } from 'uuid';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import NotificationBannerImage from 'assets/widgets/thumbnails/Notification_Banner_Widget.png';
import React, {
  Dispatch,
  FC, SetStateAction, useCallback, useEffect, useMemo, useState,
} from 'react';
import SaveIcon from '@mui/icons-material/Save';

export interface NotificationBannerContentProps {
  id?: string;
  closeModal: () => void;
  pageType: PageType;
}

export const NotificationBannerContent: FC<NotificationBannerContentProps> = ({
  closeModal,
  id,
  pageType,
}) => {
  const defaultInitialValues = useDefaultInitialValues();
  const { culture } = useSearch();
  const { data, isLoading } = useWidget<NotificationBannerWidget>(id);
  const createMutation = useCreateWidgetMutation(closeModal);
  const updateMutation = useUpdateWidgetMutation(closeModal);
  const [
    desktopNotification,
    setDesktopNotification,
  ] = useState<DraggableNotificationBannerCard[]>([]);
  const [
    mobileNotification,
    setMobileNotification,
  ] = useState<DraggableNotificationBannerCard[]>([]);
  const [isValid, setIsValid] = useState(true);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const openAlert = useGlobalAlertStore((store) => store.openAlert);
  const [isActiveDesktopNotification, setIsActiveDesktopNotification] = useState(true);
  const [isActiveMobileNotification, setIsActiveMobileNotification] = useState(true);

  const widgetsDirty = useMemo(
    () => !areWidgetItemsEqualToInitialItems(
      desktopNotification,
      mapItemsToInitialValues,
      data?.content?.desktop?.items,
    )
      || !areWidgetItemsEqualToInitialItems(
        mobileNotification,
        mapItemsToInitialValues,
        data?.content?.mobile?.items,
      ),
    [data, desktopNotification, mobileNotification],
  );

  useEffect(() => {
    if (data) {
      setIsActiveDesktopNotification(Boolean(data.content?.desktop?.available));
      setIsActiveMobileNotification(Boolean(data.content?.mobile?.available));
      setDesktopNotification(mapItemsToInitialValues(data.content?.desktop?.items));
      setMobileNotification(mapItemsToInitialValues(data.content?.mobile?.items));
    }
  }, [data]);

  const setNotificationBannerCallback = useCallback((
    prev: DraggableNotificationBannerCard[],
  ) => [...prev, {
    id: uuidv4(),
    bannerText: '',
    frBannerText: '',
    frButtonText: '',
    bannerBGColor: '',
    buttonBGColor: '',
    buttonText: '',
    buttonCTALink: '',
    isDeliveryCutoffTime: true,
    isPromoMessage: false,
    cutoffTime: 4,
    promoEndTime: '',
    isValid: false,
    onSubmitCallback: undefined,
    sources: ['organic'],
  }], []);

  const openFormInvalidAlert = useCallback(() => {
    openAlert({
      text: 'Highlighted fields are invalid, they should be filled with proper values',
      severity: 'error',
    });
  }, [openAlert]);

  const openOccasionsInvalidAlert = useCallback(() => {
    openAlert({
      text: 'Highlighted fields in notification banner are invalid, they should be filled with proper values',
      severity: 'error',
    });
  }, [openAlert]);

  const openOccasionsEmptyAlert = useCallback(() => {
    openAlert({
      text: 'Highlighted fields in notification banner are empty, please add at least 1 banner in active device categories',
      severity: 'error',
    });
  }, [openAlert]);

  const dndItemsHandleOnSubmit = useCallback(() => {
    desktopNotification.forEach((banner) => { banner.onSubmitCallback?.(); });
    mobileNotification.forEach((banner) => { banner.onSubmitCallback?.(); });
  }, [desktopNotification, mobileNotification]);

  const areSomeOccasionsInvalid = useCallback(
    () => {
      if (desktopNotification.some((banner) => !banner.isValid)
            || mobileNotification.some((banner) => !banner.isValid)) {
        return true;
      }
      return false;
    },
    [desktopNotification, mobileNotification],
  );

  const areSomeOccasionsEmpty = useCallback(
    () => {
      if (isActiveDesktopNotification && desktopNotification.length === 0) {
        return true;
      }
      if (isActiveMobileNotification && mobileNotification.length === 0) {
        return true;
      }
      return false;
    },
    [
      desktopNotification,
      mobileNotification,
      isActiveDesktopNotification,
      isActiveMobileNotification],
  );

  const isSubmitWithEmptyOccasions = useCallback((
    banners: DraggableNotificationBannerCard[],
  ) => submitAttempted && banners.length === 0, [submitAttempted]);

  const handleActivityChange = useCallback((
    value: boolean,
    setState: Dispatch<SetStateAction<boolean>>,
  ) => {
    if (value || (isActiveDesktopNotification && isActiveMobileNotification)) {
      setState(value);
    } else {
      openAlert(DEVICE_ACTIVATION_SWITCH_WARNING);
    }
  }, [isActiveDesktopNotification, isActiveMobileNotification, openAlert]);

  const handleSubmit = (values: NotificationBannerFormData) => {
    if (!isValid) {
      openFormInvalidAlert();
    }
    if (areSomeOccasionsInvalid()) {
      openOccasionsInvalidAlert();
      // return;
    }
    if (areSomeOccasionsEmpty()) {
      openOccasionsEmptyAlert();
      return;
    }

    if (id) {
      updateMutation.mutate({
        id,
        data: mapNotificationBannerFormDataToRequest(
          values,
          desktopNotification,
          mobileNotification,
        ) as UpdateNotificationBannerData,
      });
    } else {
      createMutation.mutate(mapNotificationBannerFormDataToRequest(
        values,
        desktopNotification,
        mobileNotification,
        culture,
      ) as CreateNotificationBannerData);
    }
  };

  if (id && isLoading) {
    return <Container><Loader isLoading /></Container>;
  }

  return (
    <Container>
      <Content>
        <Formik
          initialValues={data ? mapWidgetDataToFormInitialValues(data) : defaultInitialValues}
          validationSchema={NotificationBannerWidgetSchema}
          onSubmit={handleSubmit}
        >
          {({ dirty }) => (
            <Form>
              <FormikErrorsListener
                onErrors={() => setIsValid(false)}
                onNoErrors={() => setIsValid(true)}
              />
              <Box display="flex">
                <Image src={NotificationBannerImage} alt="shop by occasion widget" />
                <Box pl={3.75}>
                  <TextField name="name" label="Widget Name" fullWidth />
                  <Box display="flex">
                    <DateTimePickerField name="activeFrom" label="Valid from" sx={styles.dateTimePicker} />
                    <DateTimePickerField name="activeTo" label="Valid to" sx={styles.dateTimePicker} />
                  </Box>
                </Box>
              </Box>
              <Divider sx={styles.divider} />
              <Grid container spacing={10}>
                <Grid item xs={6}>
                  <Field name="isActiveDesktop">
                    {({ field }: FieldProps<boolean>) => (
                      <FormControlLabel
                        control={(
                          <Switch
                            checked={isActiveDesktopNotification}
                            onClick={() => handleActivityChange(
                              !isActiveDesktopNotification,
                              setIsActiveDesktopNotification,
                            )}
                            {...field}
                          />
                        )}
                        label="Desktop"
                        labelPlacement="start"
                        sx={styles.availabilitySwitch}
                        {...field}
                      />
                    )}
                  </Field>
                  <Box sx={styles.pageSelectWrapper}>
                    <TextField
                      label="Auto Slide Interval (milliseconds)"
                      name="autoSlideIntervalDesktop"
                      fullWidth
                    />
                    <PageSelect name="pageDesktop" />
                  </Box>
                </Grid>
                <Grid item xs={6} pt={2}>
                  <Field name="isActiveMobile">
                    {({ field }: FieldProps<boolean>) => (
                      <FormControlLabel
                        control={(
                          <Switch
                            checked={isActiveMobileNotification}
                            onClick={() => handleActivityChange(
                              !isActiveMobileNotification,
                              setIsActiveMobileNotification,
                            )}
                            {...field}
                          />
                        )}
                        label="Mobile"
                        labelPlacement="start"
                        sx={styles.availabilitySwitch}
                        {...field}
                      />
                    )}
                  </Field>
                  <Box sx={styles.pageSelectWrapper}>
                    <TextField
                      label="Auto Slide Interval (milliseconds)"
                      name="autoSlideIntervalMobile"
                      fullWidth
                    />
                    <PageSelect name="pageMobile" />
                  </Box>
                </Grid>
              </Grid>
              <ActionButtons>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={closeModal}
                  startIcon={<CancelIcon />}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={createMutation.isLoading || updateMutation.isLoading}
                  disabled={data && !(dirty || widgetsDirty)}
                  variant="contained"
                  type="submit"
                  size="large"
                  startIcon={<SaveIcon />}
                  onClick={() => {
                    setSubmitAttempted(true);
                    dndItemsHandleOnSubmit();
                    if (!isValid) {
                      openFormInvalidAlert();
                    }
                  }}
                >
                  Save
                </LoadingButton>
              </ActionButtons>
            </Form>
          )}
        </Formik>
        <Grid container spacing={10}>
          <Grid item xs={6} pt={2}>
            <Typography variant="subtitle1" my={3}>
              {`${desktopNotification.length} Notifications in desktop:`}
            </Typography>
            <DndProvider backend={HTML5Backend}>
              <DndStackContainer
                itemType={DraggableItemType.NotificationBannerWidget}
                items={desktopNotification}
                setItems={setDesktopNotification}
                columnHeaders={['', '']}
                pageType={pageType}
              />
            </DndProvider>
            {desktopNotification.length < 3 && (
              <Button
                onClick={() => setDesktopNotification(setNotificationBannerCallback)}
                sx={styles.getAddButton(
                  isActiveDesktopNotification && isSubmitWithEmptyOccasions(desktopNotification),
                )}
                variant="outlined"
                startIcon={<AddIcon />}
                fullWidth
              >
                Add Notification
              </Button>
            )}
          </Grid>
          <Grid item xs={6} pt={2}>
            <Typography variant="subtitle1" my={3}>
              {`${mobileNotification.length} Notifications in mobile:`}
            </Typography>
            <DndProvider backend={HTML5Backend}>
              <DndStackContainer
                itemType={DraggableItemType.NotificationBannerWidget}
                items={mobileNotification}
                setItems={setMobileNotification}
                columnHeaders={['', '']}
                pageType={pageType}
              />
            </DndProvider>
            {mobileNotification.length < 3 && (
              <Button
                onClick={() => setMobileNotification(setNotificationBannerCallback)}
                sx={styles.getAddButton(
                  isActiveMobileNotification && isSubmitWithEmptyOccasions(mobileNotification),
                )}
                variant="outlined"
                startIcon={<AddIcon />}
                fullWidth
              >
                Add Notification
              </Button>
            )}
          </Grid>
        </Grid>
      </Content>
    </Container>
  );
};

NotificationBannerContent.defaultProps = {
  id: '',
};
