import { BubbleTextDeviceDataItem, BubbleTextFormData, BubbleTextWidget } from 'containers/HomePageContainer/types/bubble-text';
import { DraggableBubbleTextCard } from 'containers/DndStackContainer/types';
import { convertDaylightSavingsTimeToDefaultTime, convertUtcToDefaultZone } from 'utils/date';

export const mapItemsToInitialValues = (
  items: BubbleTextDeviceDataItem[] = [],
  sources: string[] = [],
): DraggableBubbleTextCard[] => (items || []).map((item) => ({
  id: item.id,
  title: item.title,
  frTitle: item.frTitle,
  link: item.url,
  isValid: true,
  sources,
}));

export const mapWidgetDataToFormInitialValues = (
  data: BubbleTextWidget,
): BubbleTextFormData => ({
  name: data.name,
  activeFrom: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeFrom)),
  activeTo: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeTo)),
  isActiveDesktop: Boolean(data.content?.desktop?.available),
  isActiveMobile: Boolean(data.content?.mobile?.available),
  bubbleTextTitleDesktop: data.content?.desktop.title,
  bubbleTextTitleMobile: data.content?.mobile.title,
  bubbleTextFrTitleDesktop: data.content?.desktop.frTitle,
  bubbleTextFrTitleMobile: data.content?.mobile.frTitle,
  sources: data.sources,
  position: data.position,
});
