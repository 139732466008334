import * as styles from 'containers/DndSkinnyContainer/components/DndPageConfigCard/styles';
import { Box } from '@mui/material';
import { Chips } from 'containers/DndSkinnyContainer/components/DndPageConfigCard/components/Chips';
import { DndItemWrapper } from 'containers/DndSkinnyContainer/components/DndItemWrapper';
import { DraggablePageConfigCard } from 'containers/DndSkinnyContainer/types';
import { MappedWidgetsPropertiesType, mappedWidgetsProperties } from 'containers/SkinnyBannerContainer/components/PageConfigurator/CreateNewWidgetModalContent/mapped-widgets-properties';
import { NameCaption } from 'containers/DndSkinnyContainer/components/DndPageConfigCard/components/NameCaption';
import { PageType } from 'common/enums/page-type.enum';
import { camelCaseToTitleCase } from 'utils/string/title-case';
import { getEditModalType } from 'containers/SkinnyBannerContainer/utils/get-edit-modal-type';
import { isActiveWidget } from 'utils/date';
import { parseSearch } from 'containers/HomePageContainer/utils/parse-search';
import { useGlobalAlertStore } from 'common/store/client/global-alert/use-global-alert.store';
import { useLocation } from 'react-router-dom';
import { useModal } from 'common/components/GenericModal/store/client/use-modal.store';
import React, { FC, useEffect } from 'react';

export interface DndPageConfigCardProps extends DraggablePageConfigCard {
  isDragging: boolean;
  deleteItem: (id: string) => void;
}

export const DndPageConfigCard: FC<DndPageConfigCardProps> = ({
  id, name, isDragging, layout, deleteItem, activeFrom, activeTo, type, overrideTag, sources,
}) => {
  const modalType = getEditModalType(type);
  const openModal = useModal((store) => store.openModal);

  const { search } = useLocation();
  const { date } = parseSearch(search);
  const isActive = isActiveWidget(activeTo as string, date);
  const openAlert = useGlobalAlertStore((store) => store.openAlert);

  useEffect(() => {
    if (!isActive) {
      openAlert({
        text: 'Highlighted widgets are no longer active, Please edit it to make it active or delete the widget',
        severity: 'error',
      });
    }
  }, [isActive, openAlert]);

  const handleEdit = () => openModal({
    type: modalType,
    title: camelCaseToTitleCase(String(modalType)),
    id,
    pageType: PageType.SkinnyBannerPage,
  });

  return (
    <DndItemWrapper
      layout={layout!}
      isDragging={isDragging}
      isActive={isActive}
      deleteItem={deleteItem}
      onEdit={handleEdit}
      id={id!}
    >
      <Box sx={styles.box}>
        <Box
          component="img"
          sx={styles.image}
          src={mappedWidgetsProperties[type as MappedWidgetsPropertiesType].img}
        />
        <Box flexGrow={1} overflow="hidden">
          <NameCaption
            activeFrom={activeFrom!}
            activeTo={activeTo!}
            name={name!}
            isActive={isActive}
          />
        </Box>
        <Box pr={1.5} pl={1.5}>
          <Chips layout={layout!} overrideTag={overrideTag!} sources={sources} />
        </Box>
      </Box>
    </DndItemWrapper>
  );
};
