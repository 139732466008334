import { SecondaryBannerFormDeviceContent } from 'containers/HomePageContainer/types/secondary-banner';

export const deviceContentDefaultInitialValues: SecondaryBannerFormDeviceContent = {
  available: true,
  items: [{
    bannerName: '',
    altText: '',
    frAltText: '',
    src: '',
    frSrc: '',
    url: '',
    sequence: 0,
  }],
};
