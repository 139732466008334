import {
  Box,
  FormHelperText,
  SxProps,
  TextField,
} from '@mui/material';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { DndItem, DraggableNotificationBannerCard } from 'containers/DndStackContainer/types';
import { useField } from 'formik';
import React, { FC, useState } from 'react';

interface DateTimePickerFieldProps {
  name: string;
  label?: string;
  sx?: SxProps;
  setFieldValue: (id: string, state: DndItem) => void;
  id: string;
  newState: DraggableNotificationBannerCard;
}

export const DateTimePickerField: FC<DateTimePickerFieldProps> = ({
  label, sx, newState, setFieldValue, id, ...props
}) => {
  const [field, meta, helpers] = useField<string>(props);
  const [promoEnd, setPromoEnd] = useState<string>(newState?.promoEndTime || '');

  if (newState.promoEndTime === '') {
    field.value = '';
  }

  return (
    <Box display="flex" flexDirection="column" sx={sx}>
      <DesktopDateTimePicker
        value={promoEnd || field.value}
        onChange={(date) => { helpers.setValue(date || ''); setPromoEnd(date || ''); setFieldValue(id, { ...newState, promoEndTime: String(date) }); }}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
      {meta.error ? (
        <FormHelperText error>{meta.error}</FormHelperText>
      ) : null}
    </Box>
  );
};

DateTimePickerField.defaultProps = {
  label: '',
  sx: {},
};
