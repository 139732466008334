import { BVSocialDeviceContent, BVSocialFormData } from 'containers/HomePageContainer/types/bvsocial';
import { useInitCommonValues } from 'utils/hooks/use-init-common-values';

const deviceContentDefaultInitialValues: BVSocialDeviceContent = {
  available: true,
};

export const useDefaultInitialValues = (): BVSocialFormData => ({
  ...useInitCommonValues(),
  content: {
    desktop: deviceContentDefaultInitialValues,
    mobile: deviceContentDefaultInitialValues,
  },
});
