import {
  BannerWithProductListFormData, UpdateBannerWithProductListData,
} from 'containers/HomePageContainer/types/banner-with-product-list';
import { HomePageWidgetType } from 'containers/HomePageContainer/enum/component.enum';
import { convertDefaultTimeToDaylightSavingsTime, convertDefaultZoneToUtc } from 'utils/date';

export const mapProductCarouselUpdateFormDataToRequest = (
  data: BannerWithProductListFormData,
): UpdateBannerWithProductListData => ({
  ...data,
  activeFrom: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeFrom)),
  activeTo: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeTo)),
  eventName: data.name,
  type: HomePageWidgetType.BannerWithProductList,
});
