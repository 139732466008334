import * as Yup from 'yup';
import { PageType } from 'common/enums/page-type.enum';

const stringShouldNotBeEmpty = 'This field is required, must be a non empty string';
const valueMustBeString = 'The value must be a string';

export const dndOccasionCardSchema = Yup.object().shape({
  name: Yup.string().min(1).required()
    .typeError(valueMustBeString)
    .required(stringShouldNotBeEmpty),
  link: Yup.string().min(1)
    .typeError(valueMustBeString)
    .when('pageType', {
      is: PageType.HomePage,
      then: Yup.string().required(stringShouldNotBeEmpty),
    }),
  image: Yup.string().min(1).required()
    .typeError(valueMustBeString)
    .required(stringShouldNotBeEmpty),
});
