import { BVSocial, BVSocialFormData } from 'containers/HomePageContainer/types/bvsocial';
import { convertDaylightSavingsTimeToDefaultTime, convertUtcToDefaultZone } from 'utils/date';

export const mapWidgetDataToFormInitialValues = (
  data: BVSocial,
): BVSocialFormData => ({
  name: data.name,
  activeFrom: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeFrom)),
  activeTo: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeTo)),
  sources: data.sources,
  content: data.content,
});
