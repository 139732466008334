import { AppInsightsProvider } from 'utils/telemetry/app-insights-provider';
import { AxiosError } from 'axios';
import {
  Widget,
  WidgetUpdateParameters,
} from 'containers/HomePageContainer/types';
import { api } from 'api/apibase.service';
import { getWidgetLayout } from 'containers/HomePageContainer/utils/get-widget-layout';
import { useGlobalAlertStore } from 'common/store/client/global-alert/use-global-alert.store';
import { useHomePageWidgetsStore } from 'containers/HomePageContainer/store/client/use-home-page-widgets.store';
import { useMutation } from '@tanstack/react-query';

interface UpdateWidgetSuccessResponse<T> {
  data: T;
}

type ErrorResponse = {
  message: string[];
};

export const useUpdateWidgetMutation = <T extends Widget>(
  closeModal: () => void,
) => {
  const updateWidget = useHomePageWidgetsStore((store) => store.updateWidget);
  const openAlert = useGlobalAlertStore((store) => store.openAlert);

  return useMutation<UpdateWidgetSuccessResponse<T>, AxiosError, WidgetUpdateParameters>(
    ({ id, data }: WidgetUpdateParameters) => api.base.post(`/widgets/${id}`, data),
    {
      onSuccess: ({ data }) => {
        updateWidget(
          data.id,
          {
            name: data.name,
            layout: getWidgetLayout(data),
            activeFrom: data.activeFrom,
            activeTo: data.activeTo,
            sources: data.sources,
          },
        );
        openAlert({
          text: 'Widget was updated successfully',
          severity: 'success',
        });
        closeModal();
      },
      onError: (error) => {
        let errorMessage = String(error);
        if (error.response?.data) {
          const errorResponse = (error.response?.data as ErrorResponse);
          if (errorResponse.message.length) {
            [errorMessage] = errorResponse.message;
          }
        }
        AppInsightsProvider.trackError(error);
        openAlert({
          text: `Error occurred during update, please try again. Error: ${errorMessage}`,
          severity: 'error',
        });
      },
    },
  );
};
