import { SxProps, Theme } from '@mui/material';
import { WidgetLayout } from 'common/enums/widget-layout.enum';
import { theme } from 'utils/theme';

export const getGridContainer = (
  layout: WidgetLayout,
): SxProps<Theme> => ({
  height: 1,
  width: 1,
  display: 'flex',
  justifyContent: layout === WidgetLayout.Mobile ? 'flex-end' : 'normal',
});

export const gridItem: SxProps = {
  height: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

export const draggedItemPlaceholder: SxProps = {
  width: 1,
  height: 1,
  borderRadius: 4,
  boxShadow: 'inset 0px 0px 0px 4px lightgrey',
};

export const getDraggedItemCard = (isDragging: boolean, isActive: boolean) => ({
  opacity: isDragging ? 0 : 1,
  height: 1,
  borderRadius: 4,
  border: '2px solid transparent',
  cursor: 'ns-resize',
  backgroundColor: isActive ? 'transpaent' : '#ffdada',
  transition: 'border-color .2s',
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
});

export const tooltip: SxProps = {
  borderRadius: 4,
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.common.white,
  // this adds specificity to the selector to override default styles
  '&&&': {
    mb: 0.5,
  },
};
