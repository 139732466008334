import {
  format, isAfter, isEqual, isValid,
} from 'date-fns';
import { getTimezoneOffset, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export const DATE_TIME_FORMAT = 'Pp';
const TIME_ZONE = window.TIME_ZONE || '';

export function convertDateToISOString(date: Date): string {
  return format(date, DATE_TIME_FORMAT);
}

export function getValidDate(date: string): Date {
  const correctDate = date ? new Date(date) : new Date();
  return isValid(correctDate) ? correctDate : new Date();
}

export function convertUtcToDefaultZone(date: Date | string): string {
  return utcToZonedTime(date, TIME_ZONE).toISOString();
}

export function convertDefaultZoneToUtc(date: Date | string): string {
  return zonedTimeToUtc(date, TIME_ZONE).toISOString();
}

export function isActiveWidget(
  toDate: string,
  date: string,
): boolean {
  const activeTo = new Date(toDate);
  const cleanDate = new Date(convertDefaultZoneToUtc(date));

  return isAfter(activeTo, cleanDate) || isEqual(activeTo, cleanDate);
}

/**
 * Convert daylight saving time
 */
const ONE_HOUR = 1;
const HALF_MONTH_DAYS = 15;
const THIRD_OF_MONTH_DAYS = 8;
const MILLISECONDS_IN_MINUTE = 60000;
const EASTERN_TIME = 'America/New_York';

export function getTimezoneOffseMinutes(timeZone: string): number {
  return getTimezoneOffset(timeZone) / MILLISECONDS_IN_MINUTE;
}

function getServerNowTime(): Date {
  return new Date(
    new Date().getTime()
      + MILLISECONDS_IN_MINUTE
        * (new Date().getTimezoneOffset()
          + getTimezoneOffseMinutes(EASTERN_TIME)),
  );
}

export function convertDefaultTimeToDaylightSavingsTime(date: Date | string): Date | string {
  const now = getServerNowTime();
  const dayLightSavingStart = new Date(now.getFullYear(), 2, 1);
  const dayLightSavingEnd = new Date(now.getFullYear(), 10, 1);
  const convertDate = new Date(date);

  dayLightSavingStart.setDate(HALF_MONTH_DAYS - dayLightSavingStart.getDay());
  dayLightSavingEnd.setDate(THIRD_OF_MONTH_DAYS - dayLightSavingEnd.getDay());

  if (
    convertDate.getTime() >= dayLightSavingStart.getTime()
    && convertDate.getTime() <= dayLightSavingEnd.getTime()
  ) {
    return new Date(
      convertDate.setHours(
        convertDate.getHours() - ONE_HOUR,
      ),
    );
  }

  return new Date(
    convertDate.setHours(
      convertDate.getHours(),
    ),
  );
}

export function convertDaylightSavingsTimeToDefaultTime(date: Date | string): Date | string {
  const now = getServerNowTime();
  const dayLightSavingStart = new Date(now.getFullYear(), 2, 1);
  const dayLightSavingEnd = new Date(now.getFullYear(), 10, 1);
  const convertDate = new Date(date);

  dayLightSavingStart.setDate(HALF_MONTH_DAYS - dayLightSavingStart.getDay());
  dayLightSavingEnd.setDate(THIRD_OF_MONTH_DAYS - dayLightSavingEnd.getDay());

  if (
    convertDate.getTime() >= dayLightSavingStart.getTime()
    && convertDate.getTime() <= dayLightSavingEnd.getTime()
  ) {
    return new Date(
      convertDate.setHours(
        convertDate.getHours() + ONE_HOUR,
      ),
    );
  }

  return new Date(
    convertDate.setHours(
      convertDate.getHours(),
    ),
  );
}
