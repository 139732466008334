import { HomePageWidgetTypes } from 'containers/HomePageContainer/types/component.type';
import { Locale } from 'common/enums/locale';
import { PageType } from 'common/enums/page-type.enum';

export enum DesktopSort {
  Right = 'Right',
  Left = 'Left',
}

export enum MobileSort {
  Bottom = 'Bottom',
  Top = 'Top',
}

export interface VideoTextItem {
  src: string;
  frSrc?: string;
  altText?: string;
  frAltText?: string;
  title?: string;
  frTitle?: string;
  description?: string;
  frDescription?: string;
  buttonText?: string;
  buttonLink?: string;
  frButtonText?: string;
  frButtonLink?: string;
  textPosition?: string;
  sequence: number;
  hasImageToggle?: boolean;
}

export interface VideoTextDeviceContent {
  available: boolean;
  items: VideoTextItem[];
}

export type VideoTextFormDeviceContent = Omit<VideoTextDeviceContent, 'items'> & Omit<VideoTextItem, 'sequence'>;

export interface VideoText {
  id: string;
  widgetId: string;
  culture: Locale;
  eventName: string;
  name: string;
  type: HomePageWidgetTypes;
  activeFrom: string;
  activeTo: string;
  sources: string[];
  position?: number;
  pageType?: PageType;
  content: {
    desktop: VideoTextDeviceContent;
    mobile: VideoTextDeviceContent;
  };
}

export type VideoTextFormData = Omit<VideoText, 'id' | 'widgetId' | 'eventName' | 'type' | 'culture' | 'content'> & {
  content: {
    desktop: VideoTextFormDeviceContent;
    mobile: VideoTextFormDeviceContent;
  };
};

export type CreateVideoTextData = Omit<VideoText, 'id' | 'widgetId'>;
export type UpdateVideoTextData = Omit<VideoText, 'id' | 'widgetId' | 'culture'>;
