import { HomePageWidgetType } from 'containers/HomePageContainer/enum/component.enum';
import { ModalContentType } from 'common/enums/modal-content-type';
import BVSocial from 'assets/widgets/thumbnails/BVSocial.png';
import Banner from 'assets/widgets/thumbnails/Banner_Widget.png';
import BannerProductsWidget from 'assets/widgets/thumbnails/Banner_Products_widget.png';
import BubbleText from 'assets/widgets/thumbnails/Bubble_Text_Widget.png';
import GeneralTextWidget from 'assets/widgets/thumbnails/General_Text_Widget.png';
import HeroBanner from 'assets/widgets/thumbnails/Hero_Banner_Widget.png';
import NotificationBanner from 'assets/widgets/thumbnails/Notification_Banner_Widget.png';
import ProductCarousel from 'assets/widgets/thumbnails/Product_Carousel_Widget.png';
import SecondaryBanner from 'assets/widgets/thumbnails/Secondary_Banner_widget.png';
import ShopByCategory from 'assets/widgets/thumbnails/Shop_by_Category_Widget.png';
import ShopByOccasion from 'assets/widgets/thumbnails/Shop_by_Occasion_Widget.png';
import VideoTextWidget from 'assets/widgets/thumbnails/Video_Text_Widget.png';

export const mappedWidgetsProperties = {
  [HomePageWidgetType.CarouselDetailed]: {
    title: 'Product Carousel',
    modalContentType: ModalContentType.CreateProductCarouselWidget,
    widgetName: HomePageWidgetType.CarouselDetailed,
    img: ProductCarousel,
  },
  [HomePageWidgetType.GridWidget]: {
    title: 'Shop By Category',
    modalContentType: ModalContentType.CreateShopByCategoryWidget,
    widgetName: HomePageWidgetType.GridWidget,
    img: ShopByCategory,
  },
  [HomePageWidgetType.Banner]: {
    title: 'Banner',
    modalContentType: ModalContentType.CreateBannerWidget,
    widgetName: HomePageWidgetType.Banner,
    img: Banner,
  },
  [HomePageWidgetType.SecondaryBanner]: {
    title: 'Secondary Banner',
    modalContentType: ModalContentType.CreateSecondaryBannerWidget,
    widgetName: HomePageWidgetType.SecondaryBanner,
    img: SecondaryBanner,
  },
  [HomePageWidgetType.CarouselSimplified]: {
    title: 'Shop By Occasion',
    modalContentType: ModalContentType.CreateShopByOccasionWidget,
    widgetName: HomePageWidgetType.CarouselSimplified,
    img: ShopByOccasion,
  },
  [HomePageWidgetType.CarouselBanners]: {
    title: 'Hero Banner',
    modalContentType: ModalContentType.CreateHeroBannerWidget,
    widgetName: HomePageWidgetType.CarouselBanners,
    img: HeroBanner,
  },
  [HomePageWidgetType.ChipWidget]: {
    title: 'Bubble Text',
    modalContentType: ModalContentType.CreateChipWidget,
    widgetName: HomePageWidgetType.ChipWidget,
    img: BubbleText,
  },
  [HomePageWidgetType.GeneralTextWidget]: {
    title: 'General Text',
    modalContentType: ModalContentType.CreateGeneralTextWidget,
    widgetName: HomePageWidgetType.GeneralTextWidget,
    img: GeneralTextWidget,
  },
  [HomePageWidgetType.NotificationBannerWidget]: {
    title: 'Notification Banner',
    modalContentType: ModalContentType.CreateNotificationBannerWidget,
    widgetName: HomePageWidgetType.NotificationBannerWidget,
    img: NotificationBanner,
  },
  [HomePageWidgetType.BVSocial]: {
    title: 'BV Social',
    modalContentType: ModalContentType.CreateBVSocialWidget,
    widgetName: HomePageWidgetType.BVSocial,
    img: BVSocial,
  },
  [HomePageWidgetType.BannerWithProductList]: {
    title: 'Banner With Product List',
    modalContentType: ModalContentType.CreateBannerWithProductListWidget,
    widgetName: HomePageWidgetType.BannerWithProductList,
    img: BannerProductsWidget,
  },
  [HomePageWidgetType.VideoWithText]: {
    title: 'Video With Text',
    modalContentType: ModalContentType.CreateVideoWithTextWidget,
    widgetName: HomePageWidgetType.VideoWithText,
    img: VideoTextWidget,
  },
};

export type MappedWidgetsPropertiesType
  = keyof typeof mappedWidgetsProperties;
