import { AlertColor } from '@mui/material';
import { GenericStore } from 'common/types/generic-store-state.types';
import { actionKey, storeKey } from 'utils/redux-devtools/constants';
import { connectToDevtools } from 'utils/redux-devtools/connect-to-devtools';
import { devOnly } from 'utils/redux-devtools/dev-only';
import { immer } from 'zustand/middleware/immer';
import create from 'zustand';

export interface GlobalAlertState {
  text?: string;
  isOpen?: boolean;
  severity?: AlertColor;
}

export interface GlobalAlertStore extends GenericStore, GlobalAlertState {
  openAlert: (props: OpenAlertPayload) => void;
  closeAlert: () => void;
}

export type OpenAlertPayload = Omit<GlobalAlertState, 'isOpen'>;

const storeName = 'app/alert';

const initialState: GlobalAlertState = {
  text: '',
  isOpen: false,
  severity: 'info',
};

export const getGlobalAlertState = (store: GlobalAlertStore) => ({
  isOpen: store.isOpen,
  severity: store.severity,
  text: store.text,
});

export const useGlobalAlertStore = create<GlobalAlertStore>()(immer((set) => ({
  [storeKey]: devOnly(storeName),
  ...initialState,
  openAlert: (payload: OpenAlertPayload) => set((store) => {
    store.isOpen = true;
    store.text = payload.text;
    store.severity = payload.severity;
    store[actionKey] = devOnly('openAlert');
  }),
  closeAlert: () => set((store) => {
    store.isOpen = initialState.isOpen;
    store.text = initialState.text;
    store.severity = initialState.severity;
    store[actionKey] = devOnly('closeAlert');
  }),
})));

connectToDevtools(useGlobalAlertStore);
