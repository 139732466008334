import * as styles from 'common/components/GenericModal/styles';
import {
  ActionButtons,
  Container,
  Content,
  Image,
} from 'common/components/GenericModal/styles';
import {
  Box, Button, Divider,
} from '@mui/material';
import {
  CreateSkinnyBannerData, SkinnyBanner, SkinnyBannerFormData, UpdateSkinnyBannerData,
} from 'containers/SkinnyBannerContainer/types/skinny-banner';
import { DateTimePickerField } from 'common/components/DateTimePickerField';
import { Form, Formik } from 'formik';
import { Loader } from 'common/components/Loader';
import { LoadingButton } from '@mui/lab';
import { SkinnyBannerConfiguration } from 'containers/SkinnyBannerContainer/components/PageConfigurator/SkinnyBannerModalContent/SkinnyBannerConfiguration';
import { TextField } from 'common/components/TextField';
import { mapSkinnyBannerFormDataToRequest } from 'containers/SkinnyBannerContainer/components/PageConfigurator/SkinnyBannerModalContent/mappers/map-skinny-banner-form-data-to-request';
import { mapWidgetDataToFormInitialValues } from 'containers/SkinnyBannerContainer/components/PageConfigurator/SkinnyBannerModalContent/mappers/map-widget-data-to-form-initial-values';
import { skinnyBannerWidgetSchema } from 'containers/SkinnyBannerContainer/components/PageConfigurator/SkinnyBannerModalContent/skinny-banner-widget.schema';
import { useCreateWidgetMutation } from 'containers/SkinnyBannerContainer/store/server/use-create-widget-mutation';
import {
  useDefaultInitialValues,
} from 'containers/SkinnyBannerContainer/components/PageConfigurator/SkinnyBannerModalContent/hooks/use-default-initial-values';
import { useSearch } from 'containers/SkinnyBannerContainer/hooks/use-search';
import { useUpdateWidgetMutation } from 'containers/SkinnyBannerContainer/store/server/use-update-widget-mutation';
import { useWidget } from 'containers/SkinnyBannerContainer/store/server/use-widget';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { FC, useCallback } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import SkinnyBannerImage from 'assets/widgets/thumbnails/Skinny_Banner.png';

export interface SkinnyBannerModalContentProps {
  closeModal: () => void;
  id?: string;
}

export const SkinnyBannerModalContent: FC<SkinnyBannerModalContentProps> = ({
  closeModal,
  id,
}) => {
  const defaultInitialValues = useDefaultInitialValues();
  const { culture } = useSearch();
  const { data, isLoading } = useWidget<SkinnyBanner>(id);
  const createMutation = useCreateWidgetMutation(closeModal);
  const updateMutation = useUpdateWidgetMutation(closeModal);

  const handleSubmit = useCallback((values: SkinnyBannerFormData) => {
    if (id) {
      updateMutation.mutate({
        id,
        data: mapSkinnyBannerFormDataToRequest(values) as UpdateSkinnyBannerData,
      });
    } else {
      createMutation.mutate(
        mapSkinnyBannerFormDataToRequest(values, culture) as CreateSkinnyBannerData,
      );
    }
  }, [id, culture, createMutation, updateMutation]);

  if (id && isLoading) {
    return <Container><Loader isLoading /></Container>;
  }

  return (
    <Formik
      initialValues={data ? mapWidgetDataToFormInitialValues(data) : defaultInitialValues}
      validationSchema={skinnyBannerWidgetSchema}
      onSubmit={handleSubmit}
    >
      {({ dirty }) => (
        <Form>
          <Container>
            <Content>
              <Box display="flex">
                <Image src={SkinnyBannerImage} alt="skinny banner widget" />
                <Box pl={3.75}>
                  <TextField name="name" label="Widget Name" fullWidth />
                  <Box display="flex">
                    <DateTimePickerField name="activeFrom" label="Valid from" sx={styles.dateTimePicker} />
                    <DateTimePickerField name="activeTo" label="Valid to" sx={styles.dateTimePicker} />
                  </Box>
                </Box>
              </Box>
              <Divider sx={styles.divider} />
              <Box display="flex">
                <span style={{ margin: 'auto 20px auto 0' }}>Override Tag</span>
                <TextField name="overrideTag" label="Override Tag" />
              </Box>
              <h4>Configure Banner</h4>
              <SkinnyBannerConfiguration />
            </Content>
            <ActionButtons>
              <Button
                onClick={closeModal}
                variant="outlined"
                size="large"
                startIcon={<CancelIcon />}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={createMutation.isLoading || updateMutation.isLoading}
                disabled={data && !dirty}
                variant="contained"
                type="submit"
                size="large"
                startIcon={<SaveIcon />}
              >
                Save
              </LoadingButton>
            </ActionButtons>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

SkinnyBannerModalContent.defaultProps = {
  id: '',
};
