import { VideoText, VideoTextFormData } from 'containers/HomePageContainer/types/video-text';
import { convertDaylightSavingsTimeToDefaultTime, convertUtcToDefaultZone } from 'utils/date';
import { deviceContentDefaultInitialValues } from 'containers/HomePageContainer/components/PageConfigurator/BannerModalContent/default-initial-values';

export const mapWidgetDataToFormInitialValues = (
  data: VideoText,
): VideoTextFormData => ({
  name: data.name,
  activeFrom: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeFrom)),
  activeTo: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeTo)),
  sources: data.sources,
  position: data.position,
  pageType: data.pageType,
  content: {
    desktop: {
      ...((data.content.desktop.items || [])[0] || deviceContentDefaultInitialValues),
      available: data.content.desktop.available,
    },
    mobile: {
      ...((data.content.mobile.items || [])[0] || deviceContentDefaultInitialValues),
      available: data.content.mobile.available,
    },
  },
});
