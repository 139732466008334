import {
  CreateShopByOccasionData,
  ShopByOccasionFormData,
  UpdateShopByOccasionData,
} from 'containers/HomePageContainer/types/shop-by-occasion';
import { DraggableOccasionCard } from 'containers/DndStackContainer/types';
import { HomePageWidgetType } from 'containers/HomePageContainer/enum/component.enum';
import { Locale } from 'common/enums/locale';
import { PageType } from 'common/enums/page-type.enum';
import { convertDefaultTimeToDaylightSavingsTime, convertDefaultZoneToUtc } from 'utils/date';

const deviceItemsMapper = (occasion: DraggableOccasionCard, index: number) => ({
  id: occasion.id!,
  sequence: index,
  name: occasion.name!,
  frName: occasion.frName!,
  src: occasion.image!,
  frSrc: occasion.frImage!,
  fallbackSrc: occasion.image!,
  url: occasion.link!,
});

export const mapShopByOccasionFormDataToRequest = (
  data: ShopByOccasionFormData,
  pageTypeValue: PageType,
  desktopOccasions: DraggableOccasionCard[],
  mobileOccasions: DraggableOccasionCard[],
  culture?: Locale,
): CreateShopByOccasionData | UpdateShopByOccasionData => ({
  activeFrom: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeFrom)),
  activeTo: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeTo)),
  sources: data.sources,
  position: data.position,
  pageType: pageTypeValue,
  name: data.name,
  eventName: data.name,
  type: HomePageWidgetType.CarouselSimplified,
  ...(culture ? { culture } : null),
  content: {
    desktop: {
      available: data.isActiveDesktop,
      title: data.occasionTitleDesktop,
      frTitle: data.occasionFrTitleDesktop,
      bgColor: data.occasionBgColorDesktop,
      items: desktopOccasions.map(deviceItemsMapper),
    },
    mobile: {
      available: data.isActiveMobile,
      title: data.occasionTitleMobile,
      frTitle: data.occasionFrTitleMobile,
      bgColor: data.occasionBgColorMobile,
      items: mobileOccasions.map(deviceItemsMapper),
    },
  },
});
