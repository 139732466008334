import * as styles from 'common/components/GenericModal/styles';
import {
  Box, InputLabel, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
import { DndBannerCardFieldType } from 'containers/HomePageContainer/components/PageConfigurator/HeroBannerModalContent/DndBannerCard/field-type.enum';
import {
  DndItem,
  DraggableBannerCard,
} from 'containers/DndStackContainer/types';
import { DndItemWrapper } from 'containers/DndStackContainer/components/DndItemWrapper';
import { Form, Formik } from 'formik';
import { FormikErrorsListener } from 'common/components/FormikErrorsListener';
import { FormikItemSubmitFormInitializer } from 'containers/DndStackContainer/utils/FormikSubmitFormInitializer';
import { LocalizedTextField } from 'containers/HomePageContainer/utils/LocalizedTextField';
import { TextField } from 'common/components/TextField';
import { WidgetLayout } from 'common/enums/widget-layout.enum';
import { dndBannerCardSchema } from 'containers/HomePageContainer/components/PageConfigurator/HeroBannerModalContent/DndBannerCard/dnd-banner-card.schema';
import { isEmptyOrUndefined } from 'utils/string/is-empty-or-undefined';
import React, {
  FC, FocusEvent, useCallback, useEffect, useState,
} from 'react';

export interface DndBannerCardProps extends DraggableBannerCard {
  isDragging: boolean;
  deleteItem: (id: string) => void;
  updateItemState: (id: string, state: DndItem) => void;
}

export interface BannerCardFormData {
  name: string;
  image: string;
  frImage: string;
  link: string;
  altText: string;
  frAltText: string;
  headerText: string;
  frHeaderText: string;
  contentText: string;
  frContentText: string;
  smallText: string;
  frSmallText: string;
  buttonText: string;
  frButtonText: string;
  buttonTextColor: string;
  textColor: string;
  textAlignment: string;
}

const defaultInitialValues: BannerCardFormData = {
  name: '',
  image: '',
  frImage: '',
  link: '',
  altText: '',
  frAltText: '',
  headerText: '',
  frHeaderText: '',
  contentText: '',
  frContentText: '',
  smallText: '',
  frSmallText: '',
  buttonText: 'red',
  frButtonText: '',
  buttonTextColor: 'red',
  textAlignment: 'left',
  textColor: '',
};

export interface HandleUpdateItemProps {
  itemId: string;
  type: DndBannerCardFieldType;
  value: string | boolean | (() => Promise<void>) | string[];
}

export const DndBannerCard: FC<DndBannerCardProps> = ({
  id,
  name,
  image,
  frImage,
  link,
  altText,
  frAltText,
  headerText,
  frHeaderText,
  contentText,
  frContentText,
  smallText,
  frSmallText,
  buttonText,
  frButtonText,
  buttonTextColor,
  textAlignment,
  textColor,
  updateItemState: updateItem,
  isDragging,
  deleteItem,
  layout,
  isValid: isInitiallyValid,
  onSubmitCallback,
  sources,
}) => {
  const [isValid, setIsValid] = useState(Boolean(isInitiallyValid));
  const [altTextLanguage, setAltTextLanguage] = useState('en');
  const [headerTextLanguage, setHeaderTextLanguage] = useState('en');
  const [contentTextLanguage, setContentTextLanguage] = useState('en');
  const [smallTextLanguage, setSmallTextLanguage] = useState('en');
  const [buttonTextLanguage, setButtonTextLanguage] = useState('en');
  const [imageTextLanguage, setImageTextLanguage] = useState('en');
  const areInitialFieldValues = isEmptyOrUndefined(name)
    || isEmptyOrUndefined(image)
    || isEmptyOrUndefined(link)
    || isEmptyOrUndefined(altText);

  const handleUpdateItem = ({
    itemId,
    type,
    value,
  }: HandleUpdateItemProps): void => {
    if (!updateItem) {
      return;
    }
    const newState = {
      name,
      image,
      frImage,
      link,
      layout,
      isValid,
      onSubmitCallback,
      altText,
      frAltText,
      headerText,
      frHeaderText,
      contentText,
      frContentText,
      smallText,
      frSmallText,
      buttonText,
      frButtonText,
      buttonTextColor,
      textAlignment,
      textColor,
      sources,
    };
    if (type === DndBannerCardFieldType.Name) {
      updateItem(itemId, { ...newState, name: String(value) });
      return;
    }
    if (type === DndBannerCardFieldType.Image) {
      updateItem(itemId, { ...newState, image: String(value) });
      return;
    }
    if (type === DndBannerCardFieldType.FrImage) {
      updateItem(itemId, { ...newState, frImage: String(value) });
    }
    if (type === DndBannerCardFieldType.IsValid) {
      updateItem(itemId, { ...newState, isValid: Boolean(value) });
      return;
    }
    if (type === DndBannerCardFieldType.SubmitFormCallback) {
      updateItem(itemId, {
        ...newState,
        onSubmitCallback: value as () => Promise<void>,
      });
    }
    if (type === DndBannerCardFieldType.Link) {
      updateItem(itemId, { ...newState, link: String(value) });
    }
    if (type === DndBannerCardFieldType.AltText) {
      updateItem(itemId, { ...newState, altText: String(value) });
    }
    if (type === DndBannerCardFieldType.FrAltText) {
      updateItem(itemId, { ...newState, frAltText: String(value) });
    }
    if (type === DndBannerCardFieldType.HeaderText) {
      updateItem(itemId, { ...newState, headerText: String(value) });
    }
    if (type === DndBannerCardFieldType.FrHeaderText) {
      updateItem(itemId, { ...newState, frHeaderText: String(value) });
    }
    if (type === DndBannerCardFieldType.ContentText) {
      updateItem(itemId, { ...newState, contentText: String(value) });
    }
    if (type === DndBannerCardFieldType.FrContentText) {
      updateItem(itemId, { ...newState, frContentText: String(value) });
    }
    if (type === DndBannerCardFieldType.SmallText) {
      updateItem(itemId, { ...newState, smallText: String(value) });
    }
    if (type === DndBannerCardFieldType.FrSmallText) {
      updateItem(itemId, { ...newState, frSmallText: String(value) });
    }
    if (type === DndBannerCardFieldType.ButtonText) {
      updateItem(itemId, { ...newState, buttonText: String(value) });
    }
    if (type === DndBannerCardFieldType.FrButtonText) {
      updateItem(itemId, { ...newState, frButtonText: String(value) });
    }
    if (type === DndBannerCardFieldType.TextColor) {
      updateItem(itemId, { ...newState, textColor: String(value) });
    }
    if (type === DndBannerCardFieldType.ButtonTextColor) {
      updateItem(itemId, { ...newState, buttonTextColor: String(value) });
    }
    if (type === DndBannerCardFieldType.TextAlignment) {
      updateItem(itemId, { ...newState, textAlignment: String(value) });
    }
    if (type === DndBannerCardFieldType.Sources) {
      updateItem(itemId, { ...newState, sources: value as string[] });
    }
  };

  useEffect(() => {
    if (areInitialFieldValues) {
      handleUpdateItem({
        itemId: id!,
        type: DndBannerCardFieldType.IsValid,
        value: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!areInitialFieldValues) {
      handleUpdateItem({
        itemId: id!,
        type: DndBannerCardFieldType.IsValid,
        value: isValid,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  // eslint-disable-next-line max-len
  const onBlur = (fieldType: DndBannerCardFieldType) => ({ target: { value } }: FocusEvent<HTMLInputElement>) => {
    handleUpdateItem({ itemId: id!, type: fieldType, value: String(value) });
  };

  const onChangeButtonTextColor = ({
    target: { value },
  }: SelectChangeEvent<unknown>) => {
    handleUpdateItem({
      itemId: id!,
      type: DndBannerCardFieldType.ButtonTextColor,
      value: String(value),
    });
  };

  const onChangeTextAlignment = ({
    target: { value },
  }: SelectChangeEvent<unknown>) => {
    handleUpdateItem({
      itemId: id!,
      type: DndBannerCardFieldType.TextAlignment,
      value: String(value),
    });
  };

  const onChangeTextColor = ({
    target: { value },
  }: SelectChangeEvent<unknown>) => {
    handleUpdateItem({
      itemId: id!,
      type: DndBannerCardFieldType.TextColor,
      value: String(value),
    });
  };

  const setOnSubmitFormForItemCallback = useCallback(
    (callback: () => Promise<void>) => {
      setTimeout(() => {
        handleUpdateItem({
          itemId: id!,
          type: DndBannerCardFieldType.SubmitFormCallback,
          value: callback,
        });
      }, 1000);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * @param cardFieldType
   * @param language
   * @returns cardFieldType
   * @description This function returns the cardFieldType based on the language.
   * Searches the cardFieldType in the DndBannerCardFieldType enum and
   * returns the key of the cardFieldType in the enum.
   * Available for 'fr' language only. But can be expanded to other languages.
   */
  function getcardFieldType(cardFieldType: DndBannerCardFieldType, language: string) {
    if (language === 'fr') {
      const frenchFieldType = 'Fr'.concat(cardFieldType);
      return Object.keys(DndBannerCardFieldType)
        .find((key) => DndBannerCardFieldType[key as keyof typeof DndBannerCardFieldType]
        === frenchFieldType) as DndBannerCardFieldType;
    }
    return cardFieldType;
  }

  return (
    <DndItemWrapper
      layout={layout ?? WidgetLayout.Common}
      isDragging={isDragging}
      isActive
      deleteItem={deleteItem}
      id={id!}
    >
      <Formik
        initialValues={
          id
            ? {
              id,
              name,
              image,
              frImage,
              link,
              altText,
              frAltText,
              headerText,
              frHeaderText,
              contentText,
              frContentText,
              smallText,
              frSmallText,
              buttonText,
              frButtonText,
              buttonTextColor,
              textColor,
              textAlignment,
            }
            : defaultInitialValues
        }
        validationSchema={dndBannerCardSchema}
        onSubmit={() => {}}
      >
        {({ submitForm, errors, setFieldValue }) => (
          <Form>
            <Box p={2}>
              <FormikItemSubmitFormInitializer
                submitForm={submitForm}
                setOnSubmitFormForItem={setOnSubmitFormForItemCallback}
              />
              <FormikErrorsListener
                onErrors={() => setIsValid(false)}
                onNoErrors={() => setIsValid(true)}
              />
              <TextField
                label="Banner Name"
                name="name"
                variant="standard"
                sx={styles.textField}
                fullWidth
                onBlur={onBlur(DndBannerCardFieldType.Name)}
              />
              {/* The LocalizedTextField component is a custom component that
              handles the localization of the text fields. Use this to other text fields in future
              to support multi lingual text boxes. Moved variant, sx and fullwidth inside
              Component LocalizedTextField, englishText is the name, frenchText is the frName,
              textLanguage is language of the text, setTextLanguage is the function to set language
              */}
              <LocalizedTextField
                label="Banner Image"
                englishText={image || ''}
                frenchText={frImage || image || ''}
                textLanguage={imageTextLanguage}
                setTextLanguage={setImageTextLanguage}
                onBlur={(event) => {
                  if (imageTextLanguage === 'en') {
                    setFieldValue('image', event.target.value);
                    handleUpdateItem({
                      itemId: id!,
                      type: DndBannerCardFieldType.Image,
                      value: event.target.value,
                    });
                  } else {
                    handleUpdateItem({
                      itemId: id!,
                      type: DndBannerCardFieldType.FrImage,
                      value: event.target.value,
                    });
                  }
                }}
                errors={imageTextLanguage === 'en' ? errors?.image : ''}
              />
              <TextField
                label="Banner Link"
                name="link"
                variant="standard"
                sx={styles.textField}
                fullWidth
                onBlur={onBlur(DndBannerCardFieldType.Link)}
              />

              <LocalizedTextField
                label="Alt Text"
                englishText={altText || ''}
                frenchText={frAltText || ''}
                textLanguage={altTextLanguage}
                setTextLanguage={setAltTextLanguage}
                onBlur={(event) => {
                  if (altTextLanguage === 'en') {
                    setFieldValue('altText', event.target.value);
                    handleUpdateItem({
                      itemId: id!,
                      type: DndBannerCardFieldType.AltText,
                      value: event.target.value,
                    });
                  } else {
                    handleUpdateItem({
                      itemId: id!,
                      type: DndBannerCardFieldType.FrAltText,
                      value: event.target.value,
                    });
                  }
                }}
                errors={altTextLanguage === 'en' ? errors?.altText : ''}
              />

              <LocalizedTextField
                label="Header Text"
                englishText={headerText || ''}
                frenchText={frHeaderText || ''}
                textLanguage={headerTextLanguage}
                setTextLanguage={setHeaderTextLanguage}
                onBlur={onBlur(getcardFieldType(
                  DndBannerCardFieldType.HeaderText,
                  headerTextLanguage,
                ))}
                errors={errors?.headerText}
              />

              <LocalizedTextField
                label="Content Text"
                englishText={contentText || ''}
                frenchText={frContentText || ''}
                textLanguage={contentTextLanguage}
                setTextLanguage={setContentTextLanguage}
                onBlur={onBlur(getcardFieldType(
                  DndBannerCardFieldType.ContentText,
                  contentTextLanguage,
                ))}
                errors={errors?.contentText}
              />

              <LocalizedTextField
                label="Small Text"
                englishText={smallText || ''}
                frenchText={frSmallText || ''}
                textLanguage={smallTextLanguage}
                setTextLanguage={setSmallTextLanguage}
                onBlur={onBlur(getcardFieldType(
                  DndBannerCardFieldType.SmallText,
                  smallTextLanguage,
                ))}
                errors={errors?.smallText}
              />

              <InputLabel>Text Color</InputLabel>
              <Select
                onChange={onChangeTextColor}
                name="textColor"
                label="Text Color"
                sx={{ width: '100%' }}
                value={textColor}
              >
                <MenuItem value="red">Red</MenuItem>
                <MenuItem value="white">White</MenuItem>
              </Select>

              <LocalizedTextField
                label="Button Text"
                englishText={buttonText || ''}
                frenchText={frButtonText || ''}
                textLanguage={buttonTextLanguage}
                setTextLanguage={setButtonTextLanguage}
                onBlur={onBlur(getcardFieldType(
                  DndBannerCardFieldType.ButtonText,
                  buttonTextLanguage,
                ))}
                errors={errors?.buttonText}
              />
              <InputLabel>Button Text Color</InputLabel>
              <Select
                onChange={onChangeButtonTextColor}
                name="buttonTextColor"
                label="Button Text Color"
                sx={{ width: '100%' }}
                value={buttonTextColor}
              >
                <MenuItem value="red">Red</MenuItem>
                <MenuItem value="white">White</MenuItem>
              </Select>
              <Box mt={4} />
              <InputLabel>Text Alignment</InputLabel>
              <Select
                onChange={onChangeTextAlignment}
                name="textAlignment"
                label="Text Alignment"
                sx={{ width: '100%' }}
                value={textAlignment}
              >
                <MenuItem value="left">Left</MenuItem>
                <MenuItem value="top">Top</MenuItem>
              </Select>
            </Box>
          </Form>
        )}
      </Formik>
    </DndItemWrapper>
  );
};
