import { GeneralTextWidgetFormData } from 'containers/HomePageContainer/types/general-text-widgets';
import { deviceContentDefaultInitialValues } from 'containers/HomePageContainer/components/PageConfigurator/generalTextModalContent/default-initial-value';
import { useInitCommonValues } from 'utils/hooks/use-init-common-values';

export const useDefaultInitialValues = (): GeneralTextWidgetFormData => ({
  ...useInitCommonValues(),
  content: {
    desktop: deviceContentDefaultInitialValues,
    mobile: deviceContentDefaultInitialValues,
  },
});
