/**
 * Transforms CamelCase to Title Case
 * Example input: titleCaseAString
 * output:
 * @param str - string to title case
 * @returns a string formatted with tile case
 */
export function camelCaseToTitleCase(inputStr: string): string {
  let str = inputStr;
  // Split camelCase by white spaces
  // https://stackoverflow.com/a/7225450
  str = str.replace(/([A-Z])/g, ' $1');
  str = str.charAt(0).toUpperCase() + str.slice(1);
  str = str.replace(/([^\W_]+[^\s-]*) */g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

  // https://stackoverflow.com/a/6475125
  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  const lowers = ['A', 'An', 'The', 'And', 'But', 'Or', 'For', 'Nor', 'As', 'At',
    'By', 'For', 'From', 'In', 'Into', 'Near', 'Of', 'On', 'Onto', 'To', 'With'];
  for (let i = 0, j = lowers.length; i < j; i += 1) {
    str = str.replace(
      new RegExp(`\\s${lowers[i]}\\s`, 'g'),
      (txt) => txt.toLowerCase(),
    );
  }

  // Certain words such as initialisms or acronyms should be left uppercase
  const uppers = ['Id', 'Tv'];
  for (let i = 0, j = uppers.length; i < j; i += 1) {
    str = str.replace(
      new RegExp(`\\b${uppers[i]}\\b`, 'g'),
      uppers[i].toUpperCase(),
    );
  }

  return str;
}
