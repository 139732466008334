import {
  Box, Button, Table, TableContainer, Typography,
} from '@mui/material';

import { DraggablePageConfigCard } from 'containers/DndSkinnyContainer/types';
import { Loader } from 'common/components/Loader';
import { SkinnyBannerWidgetType } from 'containers/SkinnyBannerContainer/enum/component.enum';
import { SkinnyBannerWidgetTypes } from 'containers/SkinnyBannerContainer/types/component.type';
import {
  TableWidgetHeader,
} from 'containers/SkinnyBannerContainer/components/WidgetList/component/TableWidgetHeader';
import { TableWidgets } from 'containers/SkinnyBannerContainer/components/WidgetList/component/TableWidget';
import { Widget } from 'containers/SkinnyBannerContainer/types';
import { WidgetType } from 'containers/SkinnyBannerContainer/components/WidgetList/component/WidgetType';
import { convertDefaultZoneToUtc } from 'utils/date';
import { getWidgetLayout } from 'containers/SkinnyBannerContainer/utils/get-widget-layout';
import { parseSearch } from 'containers/SkinnyBannerContainer/utils/parse-search';
import { useLocation } from 'react-router-dom';
import { useSkinnyBannerWidgetsStore } from 'containers/SkinnyBannerContainer/store/client/use-skinny-banner-widgets.store';
import { useWidgets } from 'containers/SkinnyBannerContainer/components/WidgetList/store/server/use-widgets';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { FC, useCallback, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import shallow from 'zustand/shallow';

interface Props {
  handleClose: () => void;
}

export const WidgetList: FC<Props> = ({ handleClose }) => {
  const { search } = useLocation();
  const { culture, date } = parseSearch(search);
  const [types, setTypes] = useState<SkinnyBannerWidgetTypes[]>([]);
  const [selectedDate, setSelectedDate] = useState<string | undefined>(
    convertDefaultZoneToUtc(date),
  );
  let skinnyWidgets = Object.values(SkinnyBannerWidgetType);
  skinnyWidgets = skinnyWidgets.filter(
    (item) => (item !== SkinnyBannerWidgetType.SkinnyBannerPage),
  );
  if (types.length > 0) {
    skinnyWidgets = types;
  }
  const { data: widgetsList, isLoading } = useWidgets(
    { date: selectedDate, culture, types: skinnyWidgets },
  );
  const [selectedWidget, setSelectedWidget] = useState<Widget | null>(null);
  const widgets = useSkinnyBannerWidgetsStore((store) => store.widgets, shallow);
  const setWidgets = useSkinnyBannerWidgetsStore((store) => store.setWidgets);

  const handleAddSelected = useCallback(() => {
    if (selectedWidget) {
      const newWidget: DraggablePageConfigCard = {
        id: selectedWidget.id,
        widgetId: selectedWidget.widgetId,
        name: selectedWidget.name,
        type: selectedWidget.type,
        activeFrom: selectedWidget.activeFrom,
        activeTo: selectedWidget.activeTo,
        layout: getWidgetLayout(selectedWidget),
        sources: selectedWidget.sources,
      };
      const newWidgets: DraggablePageConfigCard[] = [...widgets, newWidget];
      setWidgets(newWidgets);
      handleClose();
    }
  }, [widgets, selectedWidget, handleClose, setWidgets]);

  const handleSetSelect = (widget: Widget) => {
    if (widget.id === selectedWidget?.id) {
      setSelectedWidget(null);
      return;
    }
    setSelectedWidget(widget);
  };

  const handleGetAll = () => {
    setSelectedDate(undefined);
  };

  return (
    <>
      <Box
        data-test="widget-list-modal"
        pt={5}
        pl={2}
        sx={{ color: (theme) => theme.palette.warning.main }}
      >
        <Typography component="span">Only the widgets active for the selected date are shown, click</Typography>
        {' '}
        <Typography
          data-test="show-all-link"
          onClick={handleGetAll}
          component="span"
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
            color: 'blue',
          }}
        >
          here
        </Typography>
        {' '}
        <Typography component="span">to show all widgets.</Typography>
      </Box>
      <Box
        display="flex"
        textAlign="center"
        sx={{ overflowY: 'scroll', height: '60vh' }}
      >
        <Box width="20vw" textAlign="left" pt={2}>
          <Button
            sx={{ marginLeft: 3.5 }}
            disabled={types.length === 0}
            onClick={() => setTypes([])}
            variant="contained"
            data-test="unselect-all"
          >
            unselect all
          </Button>
          <WidgetType types={types} setTypes={setTypes} />
        </Box>
        <Loader isLoading={isLoading} />
        <TableContainer>
          <Table aria-label="simple widgets table">
            <TableWidgetHeader />
            <TableWidgets
              items={widgetsList ?? []}
              handleSelect={handleSetSelect}
              selectedWidget={selectedWidget}
            />
          </Table>
        </TableContainer>
      </Box>
      <Box display="flex" justifyContent="center" pt={2}>
        <Button
          data-test="cancel"
          variant="outlined"
          onClick={handleClose}
          sx={{ mr: 2 }}
          startIcon={<CancelIcon />}
        >
          Cancel
        </Button>
        <Button
          data-test="select-widget"
          variant="contained"
          onClick={handleAddSelected}
          startIcon={<SaveIcon />}
        >
          Select
        </Button>
      </Box>
    </>
  );
};
