import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as SelectMui,
  SxProps,
} from '@mui/material';
import { useField } from 'formik';
import React, { FC, ReactNode, useId } from 'react';

interface SelectProps {
  name: string;
  label?: string;
  disabled?: boolean;
  sx?: SxProps;
  children: ReactNode;
}

export const Select: FC<SelectProps> = ({
  label,
  sx,
  disabled,
  children,
  ...props
}) => {
  const [field, meta] = useField<string>(props);
  const id = useId();

  return (
    <FormControl sx={sx} disabled={disabled}>
      {label ? <InputLabel id={id}>{label}</InputLabel> : null}
      <SelectMui labelId={id} {...field} label={label}>
        {children}
      </SelectMui>
      {meta.touched && meta.error ? (
        <FormHelperText error>{meta.error}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

Select.defaultProps = {
  label: '',
  disabled: false,
  sx: {},
};
