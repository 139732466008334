import {
  BannerWithProductListFormData,
  CreateBannerWithProductListData,
} from 'containers/HomePageContainer/types/banner-with-product-list';
import { HomePageWidgetType } from 'containers/HomePageContainer/enum/component.enum';
import { Locale } from 'common/enums/locale';
import { convertDefaultTimeToDaylightSavingsTime, convertDefaultZoneToUtc } from 'utils/date';

export const mapProductCarouselCreationFormDataToRequest = (
  data: BannerWithProductListFormData,
  culture: Locale = Locale.EnUs,
): CreateBannerWithProductListData => ({
  ...data,
  activeFrom: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeFrom)),
  activeTo: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeTo)),
  eventName: data.name,
  type: HomePageWidgetType.BannerWithProductList,
  culture,
});
