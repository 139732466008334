import { SxProps, Theme } from '@mui/material';
import { theme } from 'utils/theme';
import red from '@mui/material/colors/red';

export const box: SxProps = {
  height: 1,
  width: 1,
  display: 'flex',
  alignItems: 'center',
};

export const image: SxProps = {
  borderRadius: 1,
  width: 48,
  height: 48,
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.common.white,
  '&&&': {
    m: theme.spacing(1.5),
  },
};

export const caption = (isActive: boolean): SxProps<Theme> => ({
  color: isActive ? theme.palette.text.secondary : red[500],
});

export const name = (isActive: boolean): SxProps<Theme> => ({
  color: isActive ? theme.palette.text.primary : theme.palette.text.secondary,
});
