import 'common/components/RichEditer/styles.css';
import 'suneditor/dist/css/suneditor.min.css';
import * as styles from 'common/components/GenericModal/styles';
import {
  Box,
} from '@mui/material';
import { maximumCharacterValidationMessage, minimumCharacterValidationMessage } from 'containers/SkinnyBannerContainer/components/PageConfigurator/SkinnyBannerModalContent/Editor/validation-constants';
import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import SunEditor from 'suneditor-react';
import SunEditorCore from 'suneditor/src/lib/core';

interface RichEditorProps {
  textEditorValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void;
  formKey: string;
  contentData: string;
  contentFieldName: string;
}

export const RichEditor: FC<RichEditorProps> = ({
  textEditorValue, formKey, contentData, contentFieldName,
}) => {
  const [displayCharacterRequiredMessage, setDisplayCharacterRequiredMessage] = useState(false);
  const [displayCharacterLimitMessage, setDisplayCharacterLimitMessage] = useState(false);
  const [editorTextContent, setEditorTextContent] = useState('');
  const editor = useRef<SunEditorCore>();

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };

  useEffect(() => {
    if (editorTextContent !== '') {
      if (contentFieldName === 'body') {
        textEditorValue(`content.${formKey}.body`, editorTextContent || '', true);
      }
      if (contentFieldName === 'frBody') {
        textEditorValue(`content.${formKey}.frBody`, editorTextContent || '', true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorTextContent]);

  const handleOnChange = (editorContent: string) => {
    const contentTestContainer = document.createElement('div');
    contentTestContainer.innerHTML = editorContent;
    const cont = editor.current?.getText();
    setDisplayCharacterRequiredMessage(!cont);
    setDisplayCharacterLimitMessage(String(cont).length > 50000);
    setEditorTextContent(editorContent);
  };

  const editorClass = (displayCharacterRequiredMessage)
   || displayCharacterLimitMessage
    ? styles.validatedEditorContainer : styles.editorContainer;

  const displayValidationMessage = (displayCharacterRequiredMessage)
     || displayCharacterLimitMessage
    ? (
      <Box sx={styles.editorValidationMessage}>
        {displayCharacterRequiredMessage
          ? <span>{minimumCharacterValidationMessage}</span> : null}
        {displayCharacterLimitMessage ? <span>{maximumCharacterValidationMessage}</span> : null}
      </Box>
    ) : null;

  return (
    <Box sx={styles.editorWrapperClass}>
      <Box sx={editorClass}>
        <Box className="editor">
          <SunEditor
            placeholder="Description"
            setContents={contentData}
            getSunEditorInstance={getSunEditorInstance}
            onChange={handleOnChange}
            setOptions={{
              buttonList: [
                ['bold', 'underline', 'italic'],
                ['fontColor', 'formatBlock', 'fontSize'],
                ['link'],
              ],
              showPathLabel: false,
              height: 'auto',
            }}
          />
        </Box>
      </Box>
      {displayValidationMessage}
    </Box>
  );
};
