import * as Yup from 'yup';

const stringShouldNotBeEmpty = 'This field is required, must be a non empty string';
const valueMustBeString = 'The value must be a string';
const altTextMinimumCharacterLengthError = 'Alt text should be at least 10 characters long';
const headerTextMaximumCharacterLengthError = 'Header text should be at most 48 characters long';
const contentTextMaximumCharacterLengthError = 'Content text should be at most 80 characters long';
const smallTextMaximumCharacterLengthError = 'Small text should be at most 96 characters long';
const buttonTextMaximumCharacterLengthError = 'Button text should be at most 24 characters long';

export const dndBannerCardSchema = Yup.object().shape({
  name: Yup.string().min(1).required()
    .typeError(valueMustBeString)
    .required(stringShouldNotBeEmpty),
  link: Yup.string().min(1).required()
    .typeError(valueMustBeString)
    .required(stringShouldNotBeEmpty),
  image: Yup.string().min(1).required()
    .typeError(valueMustBeString)
    .required(stringShouldNotBeEmpty),
  altText: Yup.string().min(10, altTextMinimumCharacterLengthError)
    .typeError(valueMustBeString)
    .required(stringShouldNotBeEmpty),
  headerText: Yup.string().max(48, headerTextMaximumCharacterLengthError)
    .typeError(valueMustBeString),
  contentText: Yup.string().max(80, contentTextMaximumCharacterLengthError)
    .typeError(valueMustBeString),
  smallText: Yup.string().max(96, smallTextMaximumCharacterLengthError)
    .typeError(valueMustBeString),
  buttonText: Yup.string().max(24, buttonTextMaximumCharacterLengthError)
    .typeError(valueMustBeString),
});
