/* eslint-disable react/no-array-index-key */
import { WidgetLayout } from 'common/enums/widget-layout.enum';
import Chip from '@mui/material/Chip';
import React, { FC } from 'react';
import Stack from '@mui/material/Stack';

export interface Props {
  layout: WidgetLayout;
  overrideTag: string;
  sources: string[] | undefined;
}

export const Chips: FC<Props> = ({
  layout, overrideTag, sources = [],
}) => (
  <Stack direction="row" spacing={1}>
    {!!sources && !!sources?.length && sources.map((source, index) => <Chip key={`${source}-${index}`} label={source} size="small" />)}
    {layout === WidgetLayout.Desktop && (
      <>
        {overrideTag && (<Chip label="Custom" size="small" style={{ color: '#ffffff', background: '#C8102E' }} />)}
        {' '}
        <Chip label="Desktop" size="small" />
      </>
    )}
    {layout === WidgetLayout.Mobile && (
      <>
        {overrideTag && (<Chip label="Custom" size="small" style={{ color: '#ffffff', background: '#C8102E' }} />)}
        {' '}
        <Chip label="Mobile" size="small" />
      </>
    )}
    {layout === WidgetLayout.Common && (
      <>
        {overrideTag && (<Chip label="Custom" size="small" style={{ color: '#ffffff', background: '#C8102E' }} />)}
        {' '}
        <Chip label="Mobile" size="small" />
        {' '}
        <Chip label="Desktop" size="small" />
      </>
    )}
  </Stack>
);
