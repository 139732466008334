import { Alert, Snackbar } from '@mui/material';
import { getGlobalAlertState, useGlobalAlertStore } from 'common/store/client/global-alert/use-global-alert.store';
import React, { FC } from 'react';
import shallow from 'zustand/shallow';

const AUTO_HIDE_DURATION = 5000;

export const GeneralAlert: FC = () => {
  const {
    isOpen,
    severity,
    text,
  } = useGlobalAlertStore(getGlobalAlertState, shallow);
  const closeAlert = useGlobalAlertStore((store) => store.closeAlert);

  return (
    <Snackbar
      open={isOpen}
      onClose={closeAlert}
      autoHideDuration={AUTO_HIDE_DURATION}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert variant="filled" severity={severity} onClose={closeAlert}>
        {text}
      </Alert>
    </Snackbar>
  );
};
