import { SkinnyBannerFormData } from 'containers/SkinnyBannerContainer/types/skinny-banner';
import { deviceContentDefaultInitialValues, overrideTagInitValue } from 'containers/SkinnyBannerContainer/components/PageConfigurator/SkinnyBannerModalContent/default-initial-values';
import { useInitCommonValues } from 'utils/hooks/use-init-common-values';

export const useDefaultInitialValues = (): SkinnyBannerFormData => ({
  ...useInitCommonValues(),
  ...overrideTagInitValue(),
  content: {
    desktop: deviceContentDefaultInitialValues,
    mobile: deviceContentDefaultInitialValues,
  },
});
