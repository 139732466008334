import { BVSocialFormData, UpdateBVSocialData } from 'containers/HomePageContainer/types/bvsocial';
import { HomePageWidgetType } from 'containers/HomePageContainer/enum/component.enum';
import { convertDefaultTimeToDaylightSavingsTime, convertDefaultZoneToUtc } from 'utils/date';

export const mapBVSocialUpdateFormDataToRequest = (
  data: BVSocialFormData,
): UpdateBVSocialData => ({
  ...data,
  activeFrom: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeFrom)),
  activeTo: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeTo)),
  eventName: data.name,
  type: HomePageWidgetType.BVSocial,
});
