import { BannerFormData } from 'containers/HomePageContainer/types/banner';
import { deviceContentDefaultInitialValues } from 'containers/HomePageContainer/components/PageConfigurator/BannerModalContent/default-initial-values';
import { useInitCommonValues } from 'utils/hooks/use-init-common-values';

export const useDefaultInitialValues = (): BannerFormData => ({
  ...useInitCommonValues(),
  content: {
    desktop: deviceContentDefaultInitialValues,
    mobile: deviceContentDefaultInitialValues,
  },
});
