import { HomePageWidgetTypes } from 'containers/HomePageContainer/types/component.type';
import { Locale } from 'common/enums/locale';

/*
* For some reason SP uses highToLow as lowToHigh and vice versa,
* so we need to swap the sort values
*/
export enum Sort {
  Sequence = 'Sequence',
  PriceLowToHigh = 'PriceHighToLow',
  PriceHighToLow = 'PriceLowToHigh',
}

export interface BannerWithProductListDeviceContent {
  available: boolean;
  src?: string;
  frSrc?: string;
  ctaLink?: string;
  frCtaLink?: string;
  altText?: string;
  frAltText?: string;
  url?: string;
  filterParams?: {
    sort: Sort;
    limit: number;
  };
}

export interface BannerWithProductList {
  id: string;
  widgetId: string;
  culture: Locale;
  eventName: string;
  name: string;
  type: HomePageWidgetTypes;
  activeFrom: string;
  activeTo: string;
  sources: string[];
  content: {
    desktop: BannerWithProductListDeviceContent;
    mobile: BannerWithProductListDeviceContent;
  };
}

export type BannerWithProductListFormData = Omit<BannerWithProductList, 'id' | 'widgetId' | 'eventName' | 'type' | 'culture'>;
export type CreateBannerWithProductListData = Omit<BannerWithProductList, 'id' | 'widgetId'>;
export type UpdateBannerWithProductListData = Omit<BannerWithProductList, 'id' | 'widgetId' | 'culture'>;
