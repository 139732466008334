import { AppInsightsProvider } from 'utils/telemetry/app-insights-provider';
import { Widget } from 'containers/SkinnyBannerContainer/types';
import { api } from 'api/apibase.service';
import { queryClient } from 'api/rq-client/query-client.service';
import { queryKeys } from 'containers/SkinnyBannerContainer/store/server/query-keys';
import { useEffect, useMemo } from 'react';
import { useGlobalAlertStore } from 'common/store/client/global-alert/use-global-alert.store';
import { useQuery } from '@tanstack/react-query';

export const useWidget = <T extends Widget>(id?: string) => {
  const openAlert = useGlobalAlertStore((store) => store.openAlert);
  const key = useMemo(() => queryKeys.widget(id), [id]);
  const query = useQuery<T>(
    key,
    async (): Promise<T> => {
      const res = await api.base.get<T>(`/widgets/${id || ''}`);

      return {
        ...res.data,
        sources: ['organic'],
      };
    },
    {
      enabled: Boolean(id),
      onError: (error) => {
        openAlert({
          text: 'Something went wrong, please try again later',
          severity: 'error',
        });
        AppInsightsProvider.trackError(error as Error);
      },
    },
  );

  useEffect(() => () => {
    queryClient.removeQueries(key);
  }, [key]);

  return query;
};
