import * as styles from 'containers/DndStackContainer/components/DndPageConfigCard/styles';
import { Typography } from '@mui/material';
import { convertDateToISOString, convertDaylightSavingsTimeToDefaultTime, convertUtcToDefaultZone } from 'utils/date';
import React, { FC } from 'react';

export interface Props {
  activeFrom: string;
  activeTo: string;
  name: string;
  isActive: boolean;
}

export const NameCaption: FC<Props> = ({
  name, activeFrom, activeTo, isActive,
}) => (
  <>
    <Typography variant="subtitle2" noWrap sx={styles.name(isActive)}>{name}</Typography>
    <Typography variant="caption" sx={styles.caption(isActive)}>
      {isActive ? 'Valid ' : 'Expired '}
      {
        isActive
          ? convertDateToISOString(
            new Date(convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(activeFrom))),
          )
          : convertDateToISOString(
            new Date(convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(activeTo))),
          )
      }
    </Typography>
  </>

);
