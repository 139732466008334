import {
  ShopByOccasionFormData,
} from 'containers/HomePageContainer/types/shop-by-occasion';
import { useInitCommonValues } from 'utils/hooks/use-init-common-values';

export const useDefaultInitialValues = (): ShopByOccasionFormData => ({
  ...useInitCommonValues(),
  occasionTitleDesktop: '',
  occasionFrTitleDesktop: '',
  occasionTitleMobile: '',
  occasionFrTitleMobile: '',
  occasionBgColorDesktop: '',
  occasionBgColorMobile: '',
});
