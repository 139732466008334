import { colors } from 'utils/theme/colors';
import { createTheme } from '@mui/material';

const initial = createTheme({
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            '& > td': {
              color: colors.white,
            },
            '&:hover': {
              color: colors.white,
              backgroundColor: colors.hoverRed,
            },
            backgroundColor: colors.hoverRed,
            color: colors.white,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& > .MuiDialog-container > .MuiPaper-root': {
            backgroundColor: '#f2f3f5',
          },
        },
      },
    },
  },
  typography: {
    subtitle2: {
      fontWeight: 500,
      fontSize: 14,
    },
  },
  palette: {
    primary: {
      main: '#C8102F',
    },
    text: {
      primary: '#00000087',
      secondary: '#00000060',
    },
  },
});

export const theme = createTheme({
  ...initial,
  components: {
    ...initial.components,
    MuiButton: {
      styleOverrides: {
        outlined: {
          borderColor: initial.palette.divider,
        },
      },
    },
  },
});
