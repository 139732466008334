import { BubbleTextFormData } from 'containers/HomePageContainer/types/bubble-text';
import { useInitCommonValues } from 'utils/hooks/use-init-common-values';

export const useDefaultInitialValues = (): BubbleTextFormData => ({
  ...useInitCommonValues(),
  bubbleTextTitleDesktop: '',
  bubbleTextTitleMobile: '',
  bubbleTextFrTitleDesktop: '',
  bubbleTextFrTitleMobile: '',
});
