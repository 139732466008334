import { GenericStore } from 'common/types/generic-store-state.types';
import { ModalContentType } from 'common/enums/modal-content-type';
import { actionKey, storeKey } from 'utils/redux-devtools/constants';
import { connectToDevtools as connectDevtools } from 'utils/redux-devtools/connect-to-devtools';
import { devOnly } from 'utils/redux-devtools/dev-only';
import { immer } from 'zustand/middleware/immer';
import create from 'zustand';

export interface ModalState {
  open: boolean;
  title: string;
  type: ModalContentType;
  id?: string;
  pageType: string;
}

type OpenModalPayload = Omit<Partial<ModalState>, 'open'>;

export interface ModalStore extends GenericStore, ModalState {
  openModal: (payload: OpenModalPayload) => void;
  closeModal: () => void;
}

const initialState = {
  open: false,
  title: '',
  type: ModalContentType.Empty,
  id: undefined,
  pageType: '',
};

const storeName = 'modal';

export const getModalState = (store: ModalStore) => ({
  open: store.open,
  title: store.title,
  type: store.type,
  id: store.id,
  pageType: store.pageType,
});

export const useModal = create<ModalStore>()(immer((set) => ({
  [storeKey]: devOnly(storeName),
  ...initialState,
  openModal: (payload) => set((store) => {
    store.open = true;
    store.title = payload.title || initialState.title;
    store.type = payload.type || initialState.type;
    store.id = payload.id;
    store.pageType = payload.pageType || initialState.pageType;

    store[actionKey] = devOnly('openModal');
  }),
  closeModal: () => set((store) => {
    store.open = false;
    store.title = initialState.title;
    store.type = initialState.type;
    store.id = initialState.id;
    store.pageType = initialState.pageType;

    store[actionKey] = devOnly('closeModal');
  }),
})));

connectDevtools(useModal);
