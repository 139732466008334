import {
  BVSocialFormData,
  CreateBVSocialData,
} from 'containers/HomePageContainer/types/bvsocial';
import { HomePageWidgetType } from 'containers/HomePageContainer/enum/component.enum';
import { Locale } from 'common/enums/locale';
import { convertDefaultTimeToDaylightSavingsTime, convertDefaultZoneToUtc } from 'utils/date';

export const mapBVSocialCreationFormDataToRequest = (
  data: BVSocialFormData,
  culture: Locale = Locale.EnUs,
): CreateBVSocialData => ({
  ...data,
  activeFrom: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeFrom)),
  activeTo: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeTo)),
  eventName: data.name,
  type: HomePageWidgetType.BVSocial,
  culture,
});
