import 'common/components/RichEditer/styles.css';
import 'suneditor/dist/css/suneditor.min.css';
import * as styles from 'common/components/GenericModal/styles';
import {
  Box,
} from '@mui/material';
import { DndItem, DraggableNotificationBannerCard } from 'containers/DndStackContainer/types';
import { maximumCharacterValidationMessage } from 'containers/SkinnyBannerContainer/components/PageConfigurator/SkinnyBannerModalContent/Editor/validation-constants';
import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import SunEditor from 'suneditor-react';
import SunEditorCore from 'suneditor/src/lib/core';

interface RichEditorProps {
  textEditorValue: (id: string, state: DndItem) => void;
  id: string;
  newState: DraggableNotificationBannerCard;
  editorBackgroundColor: string;
  contentFieldName: string;
  editorPlaceholder: string;
  editorValue: string;
}
export const RichEditor: FC<RichEditorProps> = ({
  textEditorValue,
  id,
  newState,
  editorBackgroundColor,
  contentFieldName,
  editorPlaceholder,
  editorValue,
}) => {
  const [displayCharacterLimitMessage, setDisplayCharacterLimitMessage] = useState(false);
  const [checkEditorBackground, setCheckEditorBackground] = useState(false);
  const [displayEditorBackground, setDisplayEditorBackground] = useState(false);
  const [editorBackground, setEditorBackground] = useState('');
  const [editorTextContent, setEditorTextContent] = useState('');
  const editor = useRef<SunEditorCore>();

  useEffect(() => {
    if (editorTextContent !== '') {
      if (contentFieldName === 'bannerText') {
        textEditorValue(id, { ...newState, bannerText: String(editorTextContent) });
      }
      if (contentFieldName === 'frBannerText') {
        textEditorValue(id, { ...newState, frBannerText: String(editorTextContent) });
      }
      if (contentFieldName === 'buttonText') {
        textEditorValue(id, { ...newState, buttonText: String(editorTextContent) });
      }
      if (contentFieldName === 'frButtonText') {
        textEditorValue(id, { ...newState, frButtonText: String(editorTextContent) });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorTextContent]);

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
    editor.current?.setOptions({
      defaultStyle: `background: ${editorBackgroundColor}`,
    });
    if (contentFieldName === 'bannerText') {
      textEditorValue(id, { ...newState, bannerText: String('<p><br></p>') });
    }
    if (contentFieldName === 'frBannerText') {
      textEditorValue(id, { ...newState, frBannerText: String('<p><br></p>') });
    }
    if (contentFieldName === 'buttonText') {
      textEditorValue(id, { ...newState, buttonText: String('<p><br></p>') });
    }
    if (contentFieldName === 'frButtonText') {
      textEditorValue(id, { ...newState, frButtonText: String('<p><br></p>') });
    }
  };

  const handleOnChange = (editorContent: string) => {
    const contentTestContainer = document.createElement('div');
    contentTestContainer.innerHTML = editorContent;
    const cont = editor.current?.getText();
    setDisplayCharacterLimitMessage(String(cont).length > 150);
    setEditorTextContent(editorContent || '<p><br></p>');
  };

  const handleEditorBackground = () => {
    if (editorBackgroundColor !== editorBackground && displayEditorBackground) {
      setDisplayEditorBackground(false);
      setCheckEditorBackground(false);
    }

    if (!checkEditorBackground) {
      setEditorBackground(`${editorBackgroundColor}`);
      setCheckEditorBackground(true);
    }

    if (checkEditorBackground && !displayEditorBackground) {
      editor.current?.setOptions({
        defaultStyle: `background: ${editorBackgroundColor}`,
      });
      setDisplayEditorBackground(true);
    }
  };

  if (editorBackgroundColor && editorBackgroundColor.includes('#')) {
    handleEditorBackground();
  }

  const editorClass = displayCharacterLimitMessage
    ? styles.validatedEditorContainer : styles.editorContainer;

  const displayValidationMessage = displayCharacterLimitMessage
    ? (
      <Box sx={styles.editorValidationMessage}>
        {displayCharacterLimitMessage ? <span>{maximumCharacterValidationMessage}</span> : null}
      </Box>
    ) : null;

  return (
    <Box sx={styles.editorWrapperClass}>
      <Box sx={editorClass}>
        <Box className="editor" style={{ backgroundColor: 'white' }}>
          <SunEditor
            placeholder={editorPlaceholder}
            getSunEditorInstance={getSunEditorInstance}
            onChange={handleOnChange}
            setContents={editorValue}
            setOptions={{
              buttonList: [
                ['bold', 'underline', 'italic'],
                ['fontColor', 'formatBlock'],
              ],
              showPathLabel: false,
              height: 'auto',
            }}
          />
        </Box>
      </Box>
      {displayValidationMessage}
    </Box>
  );
};
