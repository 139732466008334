import * as Yup from 'yup';

const stringShouldNotBeEmpty = 'This field is required, must be a non empty string';
const valueMustBeString = 'The value must be a string';
const valueMustBeNumber = 'This field must be a number 0 or greater';
const dateShouldBeSet = 'Date should be set';
const valueMustBeDate = 'The value must be a date';
const activeToMustBeAfterActiveFrom = 'Valid to must be after Valid from';
const sourceArrayShouldNotBeEmpty = 'Source must contain at least one item';
const sourceIsRequired = 'Source is required';

const conditionOptions = {
  is: true,
  then: Yup.number().required(valueMustBeNumber),
};

export const heroBannerWidgetSchema = Yup.object().shape({
  name: Yup.string().min(1).required()
    .typeError(valueMustBeString)
    .required(stringShouldNotBeEmpty),
  autoSlideIntervalDesktop: Yup.number().min(0)
    .typeError(valueMustBeNumber)
    .when('isActiveDesktop', conditionOptions),
  autoSlideIntervalMobile: Yup.number().min(0)
    .typeError(valueMustBeNumber)
    .when('isActiveMobile', conditionOptions),
  sources: Yup.array().of(Yup.string())
    .min(1, sourceArrayShouldNotBeEmpty)
    .required(sourceIsRequired),
  activeFrom: Yup.date()
    .typeError(valueMustBeDate)
    .required(dateShouldBeSet),
  activeTo: Yup.date()
    .typeError(valueMustBeDate)
    .when('activeFrom', (activeFrom) => Yup.date().min(
      activeFrom,
      activeToMustBeAfterActiveFrom,
    ))
    .required(dateShouldBeSet),
});
