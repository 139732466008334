import { Box, Divider, IconButton } from '@mui/material';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import React, { FC } from 'react';

interface WidgetToolsProps {
  onDelete: () => void;
  onEdit?: () => void;
}

export const WidgetTools: FC<WidgetToolsProps> = ({ onDelete, onEdit }) => (
  <Box display="flex" px={0.5}>
    {onEdit ? (
      <>
        <IconButton size="small" onClick={onEdit} aria-label="edit widget">
          <Edit />
        </IconButton>
        <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
      </>
    ) : null}
    <IconButton size="small" onClick={onDelete} aria-label="remove widget">
      <DeleteOutline />
    </IconButton>
  </Box>
);

WidgetTools.defaultProps = {
  onEdit: undefined,
};
