import { appendAuthHeader } from 'api/interceptors/append-auth-header.interceptor';
import { rejectNotAuthorizedResponse } from 'api/interceptors/handle-auth.interceptor';
import axios, { AxiosInstance } from 'axios';

export class ApiBaseService {
  base: AxiosInstance;

  constructor() {
    this.base = axios.create({
      baseURL: '/api',
    });

    if (!window.DISABLE_AUTH_GUARD) {
      this.base.interceptors.request.use(appendAuthHeader);
      this.base.interceptors.response.use(
        (next) => Promise.resolve(next),
        rejectNotAuthorizedResponse,
      );
    }
  }
}

export const api = new ApiBaseService();
