import { DraggablePageConfigCard } from 'containers/DndSkinnyContainer/types';
import { GenericStore } from 'common/types/generic-store-state.types';
import { actionKey, storeKey } from 'utils/redux-devtools/constants';
import { connectToDevtools as connectDevtools } from 'utils/redux-devtools/connect-to-devtools';
import { devOnly } from 'utils/redux-devtools/dev-only';
import { immer } from 'zustand/middleware/immer';
import create from 'zustand';

export interface SkinnyBannerWidgetsStore extends GenericStore {
  widgets: DraggablePageConfigCard[];
  initialWidgets: DraggablePageConfigCard[];
  setWidgets: (w: DraggablePageConfigCard[]) => void;
  updateWidget: (id: string, w: DraggablePageConfigCard) => void;
  initWidgets: (w: DraggablePageConfigCard[]) => void;
  resetWidgets: () => void;
  unInitWidgets: () => void;
}

const storeName = 'skinny/widgets';

export const useSkinnyBannerWidgetsStore = create<SkinnyBannerWidgetsStore>()(immer((set) => ({
  [storeKey]: devOnly(storeName),
  widgets: [],
  initialWidgets: [],
  setWidgets: (widgets) => set((store) => {
    store.widgets = widgets;
    store[actionKey] = devOnly('setWidgets');
  }),
  updateWidget: (id, widget) => set((store) => {
    const widgetId = store.widgets.findIndex((item) => item.id === id);
    store.widgets[widgetId] = {
      ...store.widgets[widgetId],
      ...widget,
    };
    store[actionKey] = devOnly('updateWidget');
  }),
  initWidgets: (
    payload: DraggablePageConfigCard[],
  ) => set((store) => {
    store.initialWidgets = payload;
    store.widgets = payload;
    store[actionKey] = devOnly('initWidgets');
  }),
  resetWidgets: () => set((store) => {
    store.widgets = store.initialWidgets;
    store[actionKey] = devOnly('resetWidgets');
  }),
  unInitWidgets: () => set((store) => {
    store[actionKey] = devOnly('unInitWidgets');
  }),
})));

connectDevtools(useSkinnyBannerWidgetsStore);
