/* eslint-disable react/no-array-index-key */
import * as styles from 'common/components/GenericModal/styles';
import { AddCircleOutline, Cancel } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import { DEVICE_ACTIVATION_SWITCH_WARNING } from 'containers/HomePageContainer/components/PageConfigurator/constants';
import {
  FieldArray, useFormikContext,
} from 'formik';
import { LocalizedTextField } from 'containers/HomePageContainer/utils/LocalizedTextField';
import { SecondaryBannerFormData } from 'containers/HomePageContainer/types/secondary-banner';
import { Switch } from 'common/components/Switch';
import { TextField } from 'common/components/TextField';
import { resolutionSuggestionLabel } from 'containers/HomePageContainer/components/PageConfigurator/SecondaryBannerModalContent/styles';
import { useGlobalAlertStore } from 'common/store/client/global-alert/use-global-alert.store';
import React, {
  ChangeEvent, FC, Fragment, useEffect, useState,
} from 'react';

interface DeviceData {
  key: 'desktop' | 'mobile';
}

const devicesData: DeviceData[] = [
  {
    key: 'desktop',
  },
  {
    key: 'mobile',
  }];

interface FieldToggle {
  desktop: string;
  mobile: string;
}

const srcToggle: FieldToggle = {
  desktop: 'en',
  mobile: 'en',
};

const altTexToggle: FieldToggle = {
  desktop: 'en',
  mobile: 'en',
};

export const SecondaryBannersConfiguration:
FC = () => {
  const {
    values, setFieldValue, handleBlur, setValues, initialValues,
  } = useFormikContext<SecondaryBannerFormData>();
  const openAlert = useGlobalAlertStore((store) => store.openAlert);
  const [src, setSrc] = useState<FieldToggle>(srcToggle);
  const [altText, setAltText] = useState<FieldToggle>(altTexToggle);

  const handleSrcTextLanguageChange = (key: string, newLang: string) => {
    setSrc((prevState) => ({
      ...prevState,
      [key]: newLang,
    }));
  };

  const handleAltTextLanguageChange = (key: string, newLang: string) => {
    setAltText((prevState) => ({
      ...prevState,
      [key]: newLang,
    }));
  };

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>, key: string) => {
    const isChecked = event.target.checked;
    if (isChecked || (values.content.desktop.available || values.content.mobile.available)) {
      setFieldValue(`content.${key}.available`, isChecked);
    } else {
      openAlert(DEVICE_ACTIVATION_SWITCH_WARNING);
    }
  };

  useEffect(() => {
    if (!initialValues.name) {
      setValues({
        content: {
          desktop: {
            available: true,
            items: [{
              bannerName: '', altText: '', src: '', frSrc: '', frAltText: '', url: '', sequence: 0,
            }],
          },
          mobile: {
            available: true,
            items: [{
              bannerName: '', altText: '', src: '', frSrc: '', frAltText: '', url: '', sequence: 0,
            }],
          },
        },
        name: values.name,
        activeTo: values.activeTo,
        activeFrom: values.activeFrom,
        sources: values.sources,
        position: values.position,
        pageType: values.pageType,
      }, true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const widgetAlignment = {
    display: 'flex', justifyContent: 'flex-start', height: '100%', flexDirection: 'column',
  };

  return (
    <Grid container spacing={10}>
      {devicesData.map(({ key }) => {
        const disabled = !values.content[key].available;
        const deviceBanners = values.content[key].items;

        return (
          <Grid key={key} item xs={6}>
            <Box sx={widgetAlignment}>
              <Switch
                name={`content.${key}.available`}
                label={key === 'desktop' ? 'Desktop' : 'Mobile'}
                labelPlacement="start"
                onChange={(event) => handleSwitchChange(event, key)}
                sx={styles.availabilitySwitch}
              />
              <FieldArray name={`content.${key}.items`}>
                {({
                  remove, push,
                }) => (
                  <Box sx={widgetAlignment}>
                    {deviceBanners.length > 0
                    && deviceBanners.map((_, index) => (
                      <Fragment key={`${key}-${index}`}>
                        <Box sx={styles.widgetOutline}>
                          {index !== 0 && (
                            <Button
                              onClick={() => remove(index)}
                              sx={styles.closeWidgetButton}
                            >
                              <Cancel fontSize="large" />
                            </Button>
                          )}
                          <TextField
                            name={`content.${key}.items[${index}].bannerName`}
                            disabled={disabled}
                            label="Banner Name"
                            sx={styles.textField}
                            onBlur={(e) => handleBlur(e)}
                          />
                          <LocalizedTextField
                            label="Image URL"
                            englishText={values.content[key].items[index].src || ''}
                            frenchText={values.content[key].items[index].frSrc || ''}
                            textLanguage={src[key]}
                            setTextLanguage={(newLang) => handleSrcTextLanguageChange(key, newLang)}
                            onBlur={(event) => {
                              if (src[key] === 'fr') {
                                setFieldValue(`content.${key}.items[${index}].frSrc`, event.target.value);
                              } else {
                                setFieldValue(`content.${key}.items[${index}].src`, event.target.value);
                              }
                            }}
                            disabled={disabled}
                          />
                          <TextField
                            name={`content.${key}.items[${index}].url`}
                            disabled={disabled}
                            label="Page Link URL"
                            sx={styles.textField}
                            onBlur={(e) => handleBlur(e)}
                          />
                          <LocalizedTextField
                            label="Alt Text"
                            englishText={values.content[key].items[index].altText || ''}
                            frenchText={values.content[key].items[index].frAltText || ''}
                            textLanguage={altText[key]}
                            setTextLanguage={(newLang) => handleAltTextLanguageChange(key, newLang)}
                            onBlur={(event) => {
                              if (altText[key] === 'fr') {
                                setFieldValue(`content.${key}.items[${index}].frAltText`, event.target.value);
                              } else {
                                setFieldValue(`content.${key}.items[${index}].altText`, event.target.value);
                              }
                            }}
                            disabled={disabled}
                          />
                        </Box>
                        <Box sx={resolutionSuggestionLabel}>
                          {values.content.desktop.items.length > 1 && key === 'desktop' && <Typography>Recommended Image Resolution - 1028px X 640px</Typography>}
                          {values.content.desktop.items.length === 1 && key === 'desktop' && <Typography>Recommended Image Resolution - 2120px X 640px</Typography>}
                          {key === 'mobile' && <Typography>Recommended Image Resolution - 1456px X 528px</Typography>}
                        </Box>
                      </Fragment>
                    ))}
                    {deviceBanners.length < 2 && (
                      <Button
                        sx={{ fontSize: '18px' }}
                        onClick={() => push({
                          bannerName: '', altText: '', src: '', url: '', sequence: 1,
                        })}
                      >
                        <AddCircleOutline fontSize="large" />
                        Add 2nd Banner
                      </Button>
                    )}
                  </Box>
                )}
              </FieldArray>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
