import { SkinnyBannerDeviceContent } from 'containers/SkinnyBannerContainer/types/skinny-banner';
import { v4 as uuidv4 } from 'uuid';

export const deviceContentDefaultInitialValues: SkinnyBannerDeviceContent = {
  available: true,
  bannerBackgroundColor: '',
  autoSlideInterval: 8000,
  items: [
    {
      id: uuidv4(),
      editorContent: '',
      frEditorContent: '',
      url: '',
      sequence: 0,
    },
  ],
};

interface CommonInitialValues {
  overrideTag: string;
}

export const overrideTagInitValue = (): CommonInitialValues => ({ overrideTag: '' });
