import * as styles from 'containers/HomePageContainer/components/PageConfigurator/NotificationBannerModalContent/DndNotificationBannerCard/styles';
import {
  Box,
  Button,
} from '@mui/material';
import { DateTimePickerField } from 'containers/HomePageContainer/components/PageConfigurator/NotificationBannerModalContent/DndNotificationBannerCard/DateTimePickerField';
import { DndItem, DraggableNotificationBannerCard } from 'containers/DndStackContainer/types';
import { TextField } from 'containers/HomePageContainer/components/PageConfigurator/NotificationBannerModalContent/components/TextField';
import React, { FC, useState } from 'react';

interface NotificationBannerConfigurationProps {
  setFieldValue: (id: string, state: DndItem) => void;
  id: string;
  newState: DraggableNotificationBannerCard;
}

export const NotificationBannerConfiguration: FC<NotificationBannerConfigurationProps> = (
  { newState, setFieldValue, id },
) => {
  const [displayTimeButton, setDisplayTimeButton] = useState<string>(
    newState?.isDeliveryCutoffTime ? 'btn1' : 'btn2',
  );
  const [promoEndTimeValue, setPromoEndTimeValue] = useState<boolean>(
    newState?.isPromoMessage || false,
  );
  const [deliveryTimeValue, setDeliveryTimeValue] = useState<boolean>(
    newState?.isDeliveryCutoffTime || true,
  );

  return (
    <Box display="flex" flexDirection="column">
      <Box style={{ textAlign: 'center' }} mb={4}>
        <Button
          id="DeliveryCutoffTime"
          name="isDeliveryCutoffTime"
          sx={displayTimeButton === 'btn1' ? styles.selectedButton : styles.notSelectedButton}
          onClick={() => { setDisplayTimeButton('btn1'); setDeliveryTimeValue(true); setPromoEndTimeValue(false); setFieldValue(id, { ...newState, promoEndTime: String('') }); }}
        >
          Delivery - Cutoff
        </Button>
        <Button
          id="PromoMessage"
          name="isPromoMessage"
          sx={displayTimeButton === 'btn2' ? styles.selectedButton : styles.notSelectedButton}
          onClick={() => { setDisplayTimeButton('btn2'); setPromoEndTimeValue(true); setDeliveryTimeValue(false); setFieldValue(id, { ...newState, cutoffTime: Number(4) }); }}
        >
          Promo - Message
        </Button>
      </Box>
      {deliveryTimeValue && (
        <TextField
          label="Cutoff Hours"
          name="cutoffTime"
          variant="standard"
          type="number"
          sx={displayTimeButton === 'btn1' ? styles.textField : styles.selectFieldNone}
          fullWidth
          onBlur={(e) => {
            setFieldValue(id, { ...newState, cutoffTime: Number(e.target.value) });
          }}
        />
      )}
      {promoEndTimeValue && (
        <DateTimePickerField
          name="promoEndTime"
          label="Promo End Time"
          sx={displayTimeButton === 'btn2' ? styles.textField : styles.selectFieldNone}
          setFieldValue={setFieldValue}
          id={id}
          newState={newState}
        />
      )}
    </Box>
  );
};
