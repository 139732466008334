import { GenericStore } from 'common/types/generic-store-state.types';
import { actionKey, storeKey } from 'utils/redux-devtools/constants';
import { connectToDevtools } from 'utils/redux-devtools/connect-to-devtools';
import { devOnly } from 'utils/redux-devtools/dev-only';
import { immer } from 'zustand/middleware/immer';
import create from 'zustand';

export interface UnauthorizedRequestStore extends GenericStore {
  isUnauthorized: boolean;
  setUnauthorized: (b: boolean) => void;
}

const storeName = 'app/unauthReq';

export const useUnauthorizedRequestStore = create<UnauthorizedRequestStore>()(immer((set) => ({
  [storeKey]: devOnly(storeName),
  isUnauthorized: false,
  setUnauthorized: (payload: boolean) => set((store) => {
    store.isUnauthorized = payload;
    store[actionKey] = devOnly('setUnauthorized');
  }),
})));

connectToDevtools(useUnauthorizedRequestStore);
