import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import 'common/components/RichEditer/styles.css';
import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import * as styles from 'common/components/GenericModal/styles';
import {
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import { DEVICE_ACTIVATION_SWITCH_WARNING } from 'containers/SkinnyBannerContainer/components/PageConfigurator/constants';
import { DndProvider } from 'react-dnd';
import { DndStackContainer } from 'containers/DndSkinnyContainer/DndStackContainer';
import { DraggableItemType } from 'containers/DndSkinnyContainer/enums/draggable-item-type.enum';
import { DraggableSkinnyCard } from 'containers/DndSkinnyContainer/types';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { SkinnyBannerFormData } from 'containers/SkinnyBannerContainer/types/skinny-banner';
import { Switch } from 'common/components/Switch';
import { TextField } from 'common/components/TextField';
import { TwitterPicker } from 'react-color';
import { useFormikContext } from 'formik';
import { useGlobalAlertStore } from 'common/store/client/global-alert/use-global-alert.store';
import { v4 as uuidv4 } from 'uuid';
import AddIcon from '@mui/icons-material/Add';
import React, {
  FC, useCallback, useEffect, useState,
} from 'react';

interface DeviceData {
  title: string;
  key: 'desktop' | 'mobile';
}

const devicesData: DeviceData[] = [{
  title: 'Desktop',
  key: 'desktop',
}, {
  title: 'Mobile',
  key: 'mobile',
}];

export const SkinnyBannerConfiguration: FC = () => {
  const [desktopColorPicker, setDesktopColorPicker] = useState<boolean>(false);
  const [mobileColorPicker, setMobileColorPicker] = useState<boolean>(false);
  const [desktopBackground, setDesktopBackground] = useState<string>('');
  const [mobileBackground, setMobileBackground] = useState<string>('');
  const { values, setFieldValue } = useFormikContext<SkinnyBannerFormData>();
  const [desktopSkinnyBanners, setDesktopSkinnyBanners] = useState<DraggableSkinnyCard[]>([]);
  const [mobileSkinnyBanners, setMobileSkinnyBanners] = useState<DraggableSkinnyCard[]>([]);
  const openAlert = useGlobalAlertStore((store) => store.openAlert);

  const handleMobileChange = ({ hex }: { hex: string }) => {
    setMobileBackground(hex.toUpperCase());
    setFieldValue('content.mobile.bannerBackgroundColor', hex);
    setMobileColorPicker(false);
  };
  const handleDesktopChange = ({ hex }: { hex: string }) => {
    setDesktopBackground(hex.toUpperCase());
    setFieldValue('content.desktop.bannerBackgroundColor', hex);
    setDesktopColorPicker(false);
  };

  useEffect(() => {
    setFieldValue('content.desktop.items', desktopSkinnyBanners);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktopSkinnyBanners]);

  useEffect(() => {
    setFieldValue('content.mobile.items', mobileSkinnyBanners);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileSkinnyBanners]);

  useEffect(() => {
    if (values.content.desktop.bannerBackgroundColor) {
      setDesktopBackground(values.content.desktop.bannerBackgroundColor.toUpperCase());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.content.desktop.bannerBackgroundColor]);

  useEffect(() => {
    if (values.content.mobile.bannerBackgroundColor) {
      setMobileBackground(values.content.mobile.bannerBackgroundColor.toUpperCase());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.content.mobile.bannerBackgroundColor]);

  if (values) {
    if (desktopSkinnyBanners.length === 0) {
      setDesktopSkinnyBanners((values.content?.desktop?.items || []));
    }
    if (mobileSkinnyBanners.length === 0) {
      setMobileSkinnyBanners((values.content?.mobile?.items || []));
    }
  }

  const setSkinnyBannersCallback = useCallback((prev: DraggableSkinnyCard[]) => [...prev, {
    id: uuidv4(),
    editorContent: '',
    frEditorContent: '',
    url: '',
    bgColor: '',
    isValid: false,
    onSubmitCallback: undefined,
  }], []);

  const isSubmitWithEmptyBanners = useCallback((
    banners: DraggableSkinnyCard[],
  ) => banners.length === 0, []);

  const desktopAutoSlideIntervalStatus = !(desktopSkinnyBanners.length > 1);
  const mobileAutoSlideIntervalStatus = !(mobileSkinnyBanners.length > 1);

  return (
    <Grid container spacing={10}>
      {devicesData.map(({ title, key }) => {
        const disabled = !values.content[key].available;

        return (
          <Grid key={key} item xs={6}>
            <Box display="flex" flexDirection="column">
              <Switch
                name={`content.${key}.available`}
                label={title}
                labelPlacement="start"
                onChange={(event) => {
                  if (
                    event.target.checked
                    || (values.content.desktop.available && values.content.mobile.available)
                  ) {
                    setFieldValue(`content.${key}.available`, event.target.checked);
                  } else {
                    openAlert(DEVICE_ACTIVATION_SWITCH_WARNING);
                  }
                }}
                sx={styles.availabilitySwitch}
              />
              {!disabled ? (
                <>
                  <TextField
                    name={`content.${key}.autoSlideInterval`}
                    label="Auto Slide Interval (milliseconds)"
                    fullWidth
                    disabled={key === 'desktop' ? desktopAutoSlideIntervalStatus : mobileAutoSlideIntervalStatus}
                  />
                  <Box
                    display="flex"
                    onClick={key === 'mobile' ? () => { setMobileColorPicker(!mobileColorPicker); } : () => { setDesktopColorPicker(!desktopColorPicker); }}
                  >
                    <TextField
                      autoComplete="off"
                      name={`content.${key}.bannerBackgroundColor`}
                      disabled={disabled}
                      label="Banner Background Color"
                      sx={styles.textField}
                      value={key === 'desktop' ? desktopBackground : mobileBackground}
                      type="text"
                    />
                    <Box sx={styles.colorBox} style={{ backgroundColor: key === 'mobile' ? mobileBackground : desktopBackground }} />
                  </Box>
                  <Box sx={styles.colorPicker}>
                    {(key === 'mobile' && mobileColorPicker)
                    && (
                      <TwitterPicker
                        color={mobileBackground}
                        onChangeComplete={handleMobileChange}
                      />
                    )}
                    {(key === 'desktop' && desktopColorPicker)
                    && (
                      <TwitterPicker
                        color={desktopBackground}
                        onChangeComplete={handleDesktopChange}
                      />
                    )}
                  </Box>
                </>
              ) : null}
              {key === 'desktop' && !disabled && (
                <Box display="flex" flexDirection="column">
                  <Typography variant="subtitle1" my={3}>
                    {`${desktopSkinnyBanners.length} Skinny Banners in ${key}:`}
                  </Typography>
                  <DndProvider backend={HTML5Backend}>
                    <DndStackContainer
                      itemType={DraggableItemType.SkinnyBannerWidget}
                      items={desktopSkinnyBanners}
                      setItems={setDesktopSkinnyBanners}
                      columnHeaders={['', '']}
                      bgColor={desktopBackground}
                    />
                  </DndProvider>
                  {desktopSkinnyBanners.length < 3 && (
                    <Button
                      onClick={() => setDesktopSkinnyBanners(setSkinnyBannersCallback)}
                      sx={styles.getAddButton(
                        isSubmitWithEmptyBanners(desktopSkinnyBanners),
                      )}
                      variant="outlined"
                      startIcon={<AddIcon />}
                      fullWidth
                    >
                      Add Skinny Banner
                    </Button>
                  )}
                </Box>
              )}
              {key === 'mobile' && !disabled && (
                <Box display="flex" flexDirection="column">
                  <Typography variant="subtitle1" my={3}>
                    {`${mobileSkinnyBanners.length} Skinny Banners in ${key}:`}
                  </Typography>
                  <DndProvider backend={HTML5Backend}>
                    <DndStackContainer
                      itemType={DraggableItemType.SkinnyBannerWidget}
                      items={mobileSkinnyBanners}
                      setItems={setMobileSkinnyBanners}
                      columnHeaders={['', '']}
                      bgColor={mobileBackground}
                    />
                  </DndProvider>
                  {mobileSkinnyBanners.length < 3 && (
                    <Button
                      onClick={() => setMobileSkinnyBanners(setSkinnyBannersCallback)}
                      sx={styles.getAddButton(
                        isSubmitWithEmptyBanners(mobileSkinnyBanners),
                      )}
                      variant="outlined"
                      startIcon={<AddIcon />}
                      fullWidth
                    >
                      Add Skinny Banner
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
