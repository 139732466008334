import { BannerWithProductListDeviceContent, BannerWithProductListFormData, Sort } from 'containers/HomePageContainer/types/banner-with-product-list';
import { useInitCommonValues } from 'utils/hooks/use-init-common-values';

const deviceContentDefaultInitialValues: BannerWithProductListDeviceContent = {
  available: true,
  src: '',
  frSrc: '',
  ctaLink: '',
  frCtaLink: '',
  altText: '',
  frAltText: '',
  url: '',
  filterParams: {
    sort: Sort.Sequence,
    limit: 10,
  },
};

export const useDefaultInitialValues = (): BannerWithProductListFormData => ({
  ...useInitCommonValues(),
  content: {
    desktop: deviceContentDefaultInitialValues,
    mobile: deviceContentDefaultInitialValues,
  },
});
