import { TableCell, TableHead, TableRow } from '@mui/material';
import React, {
  FC,
} from 'react';

export const TableWidgetHeader: FC = () => (
  <TableHead>
    <TableRow>
      <TableCell>Widget Name</TableCell>
      <TableCell>Widget Type</TableCell>
      <TableCell>Created For</TableCell>
      <TableCell>Active From</TableCell>
      <TableCell>Active To</TableCell>
    </TableRow>
  </TableHead>
);
