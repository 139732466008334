import * as styles from 'common/components/GenericModal/styles';
import {
  ActionButtons,
  Container,
  Content,
  Image,
} from 'common/components/GenericModal/styles';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import {
  CreateHeroBannerData, HeroBanner,
  HeroBannerFormData,
  UpdateHeroBannerData,
} from 'containers/HomePageContainer/types/hero-banner';
import { DEVICE_ACTIVATION_SWITCH_WARNING } from 'containers/HomePageContainer/components/PageConfigurator/constants';
import { DateTimePickerField } from 'common/components/DateTimePickerField';
import { DndProvider } from 'react-dnd';
import { DndStackContainer } from 'containers/DndStackContainer/DndStackContainer';
import { DraggableBannerCard } from 'containers/DndStackContainer/types';
import { DraggableItemType } from 'containers/DndStackContainer/enums/draggable-item-type.enum';
import {
  Field, FieldProps, Form, Formik,
} from 'formik';
import { FormikErrorsListener } from 'common/components/FormikErrorsListener';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Loader } from 'common/components/Loader';
import { LoadingButton } from '@mui/lab';
import { TextField } from 'common/components/TextField/index';
import { areWidgetItemsEqualToInitialItems } from 'containers/HomePageContainer/utils/are-widget-items-equal-to-initial-items';

import { PageType } from 'common/enums/page-type.enum';
import { SourceSelect } from 'common/components/SourceSelect';
import { heroBannerWidgetSchema } from 'containers/HomePageContainer/components/PageConfigurator/HeroBannerModalContent/hero-banner-widget.schema';
import { mapHeroBannerFormDataToRequest } from 'containers/HomePageContainer/components/PageConfigurator/HeroBannerModalContent/mappers/map-hero-banner-form-data-to-request';
import {
  mapItemsToInitialValues,
  mapWidgetDataToFormInitialValues,
} from 'containers/HomePageContainer/components/PageConfigurator/HeroBannerModalContent/mappers/map-widget-data-to-form-initial-values';
import { useCreateWidgetMutation } from 'containers/HomePageContainer/store/server/use-create-widget-mutation';
import { useDefaultInitialValues } from 'containers/HomePageContainer/components/PageConfigurator/HeroBannerModalContent/hooks/use-default-initial-values';
import { useGlobalAlertStore } from 'common/store/client/global-alert/use-global-alert.store';
import { useSearch } from 'containers/HomePageContainer/hooks/use-search';
import { useUpdateWidgetMutation } from 'containers/HomePageContainer/store/server/use-update-widget-mutation';
import { useWidget } from 'containers/HomePageContainer/store/server/use-widget';
import { v4 as uuidv4 } from 'uuid';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import HeroBannerImage from 'assets/widgets/thumbnails/Hero_Banner_Widget.png';
import React, {
  Dispatch,
  FC, SetStateAction,
  useCallback,
  useEffect, useMemo,
  useState,
} from 'react';
import SaveIcon from '@mui/icons-material/Save';

export interface HeroBannerModalContentProps {
  id?: string;
  closeModal: () => void;
  pageType: PageType;
}

export const HeroBannerModalContent: FC<HeroBannerModalContentProps> = ({
  closeModal,
  id,
  pageType,
}) => {
  const defaultInitialValues = useDefaultInitialValues();
  const { culture } = useSearch();
  const { data, isLoading } = useWidget<HeroBanner>(id);
  const createMutation = useCreateWidgetMutation(closeModal);
  const updateMutation = useUpdateWidgetMutation(closeModal);
  const [desktopBanners, setDesktopBanners] = useState<DraggableBannerCard[]>([]);
  const [mobileBanners, setMobileBanners] = useState<DraggableBannerCard[]>([]);
  const [isValid, setIsValid] = useState(true);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const openAlert = useGlobalAlertStore((store) => store.openAlert);
  const [isActiveDesktopBanners, setIsActiveDesktopBanners] = useState(true);
  const [isActiveMobileBanners, setIsActiveMobileBanners] = useState(true);
  const widgetsDirty = useMemo(
    () => !areWidgetItemsEqualToInitialItems(
      desktopBanners,
      mapItemsToInitialValues,
      data?.content?.desktop?.items,
    )
      || !areWidgetItemsEqualToInitialItems(
        mobileBanners,
        mapItemsToInitialValues,
        data?.content?.mobile?.items,
      ),
    [data, desktopBanners, mobileBanners],
  );

  useEffect(() => {
    if (data) {
      const { sources } = data;
      setIsActiveDesktopBanners(Boolean(data.content?.desktop?.available));
      setIsActiveMobileBanners(Boolean(data.content?.mobile?.available));
      setDesktopBanners(mapItemsToInitialValues(data.content?.desktop?.items, sources));
      setMobileBanners(mapItemsToInitialValues(data.content?.mobile?.items, sources));
    }
  }, [data]);

  const setBannersCallback = useCallback((prev: DraggableBannerCard[]) => [...prev, {
    id: uuidv4(),
    name: '',
    image: '',
    frImage: '',
    link: '',
    altText: '',
    frAltText: '',
    headerText: '',
    frHeaderText: '',
    contentText: '',
    frContentText: '',
    smallText: '',
    frSmallText: '',
    buttonText: '',
    frButtonText: '',
    buttonTextColor: 'red',
    textAlignment: 'left',
    textColor: 'red',
    isValid: false,
    onSubmitCallback: undefined,
    sources: ['organic'],
  }], []);

  const openFormInvalidAlert = useCallback(() => {
    openAlert({
      text: 'Highlighted fields are invalid, they should be filled with proper values',
      severity: 'error',
    });
  }, [openAlert]);

  const openBannersInvalidAlert = useCallback(() => {
    openAlert({
      text: 'Highlighted fields in banners are invalid, they should be filled with proper values',
      severity: 'error',
    });
  }, [openAlert]);

  const openBannersEmptyAlert = useCallback(() => {
    openAlert({
      text: 'Highlighted fields in banners are empty, please add at least 1 banner in active device categories',
      severity: 'error',
    });
  }, [openAlert]);

  const dndItemsHandleOnSubmit = useCallback(() => {
    desktopBanners.forEach((banner) => { banner.onSubmitCallback?.(); });
    mobileBanners.forEach((banner) => { banner.onSubmitCallback?.(); });
  }, [desktopBanners, mobileBanners]);

  const areSomeBannersInvalid = useCallback(
    () => {
      if (desktopBanners.some((banner) => !banner.isValid)
            || mobileBanners.some((banner) => !banner.isValid)) {
        return true;
      }
      return false;
    },
    [desktopBanners, mobileBanners],
  );

  const areSomeBannersEmpty = useCallback(
    () => {
      if (isActiveDesktopBanners && desktopBanners.length === 0) {
        return true;
      }
      if (isActiveMobileBanners && mobileBanners.length === 0) {
        return true;
      }
      return false;
    },
    [desktopBanners, mobileBanners, isActiveDesktopBanners, isActiveMobileBanners],
  );

  const isSubmitWithEmptyBanners = useCallback((
    banners: DraggableBannerCard[],
  ) => submitAttempted && banners.length === 0, [submitAttempted]);

  const handleActivityChange = useCallback((
    value: boolean,
    setState: Dispatch<SetStateAction<boolean>>,
  ) => {
    if (value || (isActiveDesktopBanners && isActiveMobileBanners)) {
      setState(value);
    } else {
      openAlert(DEVICE_ACTIVATION_SWITCH_WARNING);
    }
  }, [isActiveDesktopBanners, isActiveMobileBanners, openAlert]);

  const handleSubmit = (values: HeroBannerFormData) => {
    if (!isValid) {
      openFormInvalidAlert();
    }
    if (areSomeBannersInvalid()) {
      openBannersInvalidAlert();
      return;
    }
    if (areSomeBannersEmpty()) {
      openBannersEmptyAlert();
      return;
    }

    if (id) {
      updateMutation.mutate({
        id,
        data: mapHeroBannerFormDataToRequest(
          values,
          desktopBanners,
          mobileBanners,
        ) as UpdateHeroBannerData,
      });
    } else {
      createMutation.mutate(mapHeroBannerFormDataToRequest(
        values,
        desktopBanners,
        mobileBanners,
        culture,
      ) as CreateHeroBannerData);
    }
  };

  if (id && isLoading) {
    return <Container><Loader isLoading /></Container>;
  }

  return (
    <Container>
      <Content>
        <Formik
          initialValues={data ? mapWidgetDataToFormInitialValues(data) : defaultInitialValues}
          validationSchema={heroBannerWidgetSchema}
          onSubmit={handleSubmit}
        >
          {({ dirty }) => (
            <Form>
              <FormikErrorsListener
                onErrors={() => setIsValid(false)}
                onNoErrors={() => setIsValid(true)}
              />
              <Box display="flex">
                <Image src={HeroBannerImage} alt="hero banner widget" />
                <Box pl={3.75}>
                  <TextField name="name" label="Widget Name" fullWidth />
                  <Box display="flex">
                    <DateTimePickerField name="activeFrom" label="Valid from" sx={styles.dateTimePicker} />
                    <DateTimePickerField name="activeTo" label="Valid to" sx={styles.dateTimePicker} />
                    {pageType !== PageType.Plp && (
                      <SourceSelect name="sources" />
                    )}
                  </Box>
                </Box>
              </Box>
              <Divider sx={styles.divider} />
              <Grid container spacing={10}>
                <Grid item xs={6}>
                  <Field name="isActiveDesktop">
                    {({ field }: FieldProps<boolean>) => (
                      <FormControlLabel
                        control={(
                          <Switch
                            checked={isActiveDesktopBanners}
                            onClick={() => handleActivityChange(
                              !isActiveDesktopBanners,
                              setIsActiveDesktopBanners,
                            )}
                            {...field}
                          />
                        )}
                        label="Desktop"
                        labelPlacement="start"
                        sx={styles.availabilitySwitch}
                        {...field}
                      />
                    )}
                  </Field>
                  <TextField
                    label="Auto Slide Interval (milliseconds)"
                    name="autoSlideIntervalDesktop"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field name="isActiveMobile">
                    {({ field }: FieldProps<boolean>) => (
                      <FormControlLabel
                        control={(
                          <Switch
                            checked={isActiveMobileBanners}
                            onClick={() => handleActivityChange(
                              !isActiveMobileBanners,
                              setIsActiveMobileBanners,
                            )}
                            {...field}
                          />
                        )}
                        label="Mobile"
                        labelPlacement="start"
                        sx={styles.availabilitySwitch}
                        {...field}
                      />
                    )}
                  </Field>
                  <TextField
                    name="autoSlideIntervalMobile"
                    label="Auto Slide Interval (milliseconds)"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <ActionButtons>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={closeModal}
                  startIcon={<CancelIcon />}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={createMutation.isLoading || updateMutation.isLoading}
                  disabled={data && !(dirty || widgetsDirty)}
                  variant="contained"
                  type="submit"
                  size="large"
                  startIcon={<SaveIcon />}
                  onClick={() => {
                    setSubmitAttempted(true);
                    dndItemsHandleOnSubmit();
                    if (!isValid) {
                      openFormInvalidAlert();
                    }
                  }}
                >
                  Save
                </LoadingButton>
              </ActionButtons>
            </Form>
          )}
        </Formik>
        <Grid container spacing={10}>
          <Grid item xs={6} pt={2}>
            <Typography variant="subtitle1" my={3}>
              {`${desktopBanners.length} Banners in desktop:`}
            </Typography>
            <DndProvider backend={HTML5Backend}>
              <DndStackContainer
                itemType={DraggableItemType.HeroBannerWidget}
                items={desktopBanners}
                setItems={setDesktopBanners}
                columnHeaders={['', '']}
                pageType={pageType}
              />
            </DndProvider>
            <Button
              onClick={() => setDesktopBanners(setBannersCallback)}
              sx={styles.getAddButton(
                isActiveDesktopBanners && isSubmitWithEmptyBanners(desktopBanners),
              )}
              variant="outlined"
              startIcon={<AddIcon />}
              fullWidth
            >
              Add Banner
            </Button>
          </Grid>
          <Grid item xs={6} pt={2}>
            <Typography variant="subtitle1" my={3}>
              {`${mobileBanners.length} Banners in mobile:`}
            </Typography>
            <DndProvider backend={HTML5Backend}>
              <DndStackContainer
                itemType={DraggableItemType.HeroBannerWidget}
                items={mobileBanners}
                setItems={setMobileBanners}
                columnHeaders={['', '']}
                pageType={pageType}
              />
            </DndProvider>
            <Button
              onClick={() => setMobileBanners(setBannersCallback)}
              sx={styles.getAddButton(
                isActiveMobileBanners && isSubmitWithEmptyBanners(mobileBanners),
              )}
              variant="outlined"
              startIcon={<AddIcon />}
              fullWidth
            >
              Add Banner
            </Button>
          </Grid>
        </Grid>
      </Content>
    </Container>
  );
};

HeroBannerModalContent.defaultProps = {
  id: '',
};
