import {
  CreateProductCarouselData,
  ProductCarouselFormData,
} from 'containers/HomePageContainer/types/product-carousel';
import { HomePageWidgetType } from 'containers/HomePageContainer/enum/component.enum';
import { Locale } from 'common/enums/locale';
import { convertDefaultTimeToDaylightSavingsTime, convertDefaultZoneToUtc } from 'utils/date';

export const mapProductCarouselCreationFormDataToRequest = (
  data: ProductCarouselFormData,
  culture: Locale = Locale.EnUs,
): CreateProductCarouselData => ({
  ...data,
  activeFrom: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeFrom)),
  activeTo: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeTo)),
  eventName: data.name,
  type: HomePageWidgetType.CarouselDetailed,
  culture,
});
