import { ModalContentType } from 'common/enums/modal-content-type';
import { PlpPageWidgetType } from 'containers/PlpPageContainer/enum/component.enum';
import Banner from 'assets/widgets/thumbnails/Banner_Widget.png';
import BubbleText from 'assets/widgets/thumbnails/Bubble_Text_Widget.png';
import SecondaryBanner from 'assets/widgets/thumbnails/Secondary_Banner_widget.png';
import ShopByOccasion from 'assets/widgets/thumbnails/Shop_by_Occasion_Widget.png';
import VideoTextWidget from 'assets/widgets/thumbnails/Video_Text_Widget.png';

export const mappedWidgetsProperties = {
  [PlpPageWidgetType.CarouselSimplified]: {
    title: 'Shop By Occasion',
    modalContentType: ModalContentType.CreateShopByOccasionWidget,
    widgetName: PlpPageWidgetType.CarouselSimplified,
    img: ShopByOccasion,
  },
  [PlpPageWidgetType.Banner]: {
    title: 'Banner',
    modalContentType: ModalContentType.CreateBannerWidget,
    widgetName: PlpPageWidgetType.Banner,
    img: Banner,
  },
  [PlpPageWidgetType.SecondaryBanner]: {
    title: 'Secondary Banner',
    modalContentType: ModalContentType.CreateSecondaryBannerWidget,
    widgetName: PlpPageWidgetType.SecondaryBanner,
    img: SecondaryBanner,
  },
  [PlpPageWidgetType.ChipWidget]: {
    title: 'Bubble Text',
    modalContentType: ModalContentType.CreateChipWidget,
    widgetName: PlpPageWidgetType.ChipWidget,
    img: BubbleText,
  },
  [PlpPageWidgetType.VideoWithText]: {
    title: 'Video With Text',
    modalContentType: ModalContentType.CreateVideoWithTextWidget,
    widgetName: PlpPageWidgetType.VideoWithText,
    img: VideoTextWidget,
  },
};

export type MappedWidgetsPropertiesType
  = keyof typeof mappedWidgetsProperties;
