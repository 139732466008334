import {
  InteractionRequiredAuthError,
  PopupRequest,
  PublicClientApplication,
  SilentRequest,
} from '@azure/msal-browser';
import { configuration } from 'api/auth/configuration';
import { differenceInMinutes } from 'date-fns';

class AuthService {
  private readonly client: PublicClientApplication;

  private readonly scopes: string[];

  private readonly redirectRequest: PopupRequest;

  private static checkTokenExpiration(expiresOn: Date | null): boolean {
    return differenceInMinutes(expiresOn || 0, new Date()) > 1;
  }

  constructor() {
    this.client = new PublicClientApplication(configuration);
    this.scopes = [`api://${window.AD_CLIENT_ID!}/${window.AD_SCOPE!}`];
    this.redirectRequest = {
      scopes: this.scopes,
      prompt: 'select_account',
    };
  }

  getInstance(): PublicClientApplication {
    return this.client;
  }

  login(): Promise<void> {
    return this.client.loginRedirect(this.redirectRequest);
  }

  logout(): Promise<void> {
    return this.client.logoutRedirect();
  }

  async getToken(): Promise<string | null> {
    try {
      const tokenSilentRequest: SilentRequest = {
        scopes: this.scopes,
        account: this.client.getActiveAccount() || undefined,
      };
      const { accessToken, expiresOn } = await this.client.acquireTokenSilent(tokenSilentRequest);

      if (AuthService.checkTokenExpiration(expiresOn)) {
        return accessToken;
      }

      const { accessToken: refreshedAccessToken } = await this.client.acquireTokenSilent({
        ...tokenSilentRequest,
        forceRefresh: true,
      });

      return refreshedAccessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        this.getTokenRedirect();
      }
      // eslint-disable-next-line no-console
      console.error(error);
      return null;
    }
  }

  getTokenRedirect(): void {
    this.client.acquireTokenRedirect({
      ...this.redirectRequest,
      account: this.client.getActiveAccount() || undefined,
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
  }
}

export const authService = new AuthService();
