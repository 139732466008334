import * as styles from 'common/components/GenericModal/styles';
import {
  ActionButtons,
  Container,
  Content,
  Image,
} from 'common/components/GenericModal/styles';
import {
  Banner, BannerFormData, CreateBannerData, UpdateBannerData,
} from 'containers/HomePageContainer/types/banner';
import { BannersConfiguration } from 'containers/HomePageContainer/components/PageConfigurator/BannerModalContent/BannersConfiguration';
import {
  Box, Button, Divider,
} from '@mui/material';
import { DateTimePickerField } from 'common/components/DateTimePickerField';
import { Form, Formik } from 'formik';
import { Loader } from 'common/components/Loader';
import { LoadingButton } from '@mui/lab';
import { PageType } from 'common/enums/page-type.enum';
import { PositionSelect } from 'common/components/StaticWidgetSelect';
import { SourceSelect } from 'common/components/SourceSelect';
import { TextField } from 'common/components/TextField';
import { bannerWidgetSchema } from 'containers/HomePageContainer/components/PageConfigurator/BannerModalContent/banner-widget.schema';
import { mapBannerFormDataToRequest } from 'containers/HomePageContainer/components/PageConfigurator/BannerModalContent/mappers/map-banner-form-data-to-request';
import { mapWidgetDataToFormInitialValues } from 'containers/HomePageContainer/components/PageConfigurator/BannerModalContent/mappers/map-widget-data-to-form-initial-values';
import { useCreateWidgetMutation } from 'containers/HomePageContainer/store/server/use-create-widget-mutation';
import {
  useDefaultInitialValues,
} from 'containers/HomePageContainer/components/PageConfigurator/BannerModalContent/hooks/use-default-initial-values';
import { useSearch } from 'containers/HomePageContainer/hooks/use-search';
import { useUpdateWidgetMutation } from 'containers/HomePageContainer/store/server/use-update-widget-mutation';
import { useWidget } from 'containers/HomePageContainer/store/server/use-widget';
import BannerImage from 'assets/widgets/thumbnails/Banner_Widget.png';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { FC, useCallback } from 'react';
import SaveIcon from '@mui/icons-material/Save';

export interface BannerModalContentProps {
  closeModal: () => void;
  id?: string;
  pageType: PageType;
}

export const BannerModalContent: FC<BannerModalContentProps> = ({
  closeModal,
  id,
  pageType,
}) => {
  const defaultInitialValues = useDefaultInitialValues();
  const { culture } = useSearch();
  const { data, isLoading } = useWidget<Banner>(id);
  const createMutation = useCreateWidgetMutation(closeModal);
  const updateMutation = useUpdateWidgetMutation(closeModal);

  const handleSubmit = useCallback((values: BannerFormData) => {
    if (id) {
      updateMutation.mutate({
        id,
        data: mapBannerFormDataToRequest(values, pageType) as UpdateBannerData,
      });
    } else {
      createMutation.mutate(
        mapBannerFormDataToRequest(values, pageType, culture) as CreateBannerData,
      );
    }
  }, [id, culture, createMutation, updateMutation, pageType]);

  if (pageType === PageType.Plp) {
    defaultInitialValues.position = 1;
  }
  if (id && isLoading) {
    return <Container><Loader isLoading /></Container>;
  }

  return (
    <Formik
      initialValues={data ? mapWidgetDataToFormInitialValues(data) : defaultInitialValues}
      validationSchema={bannerWidgetSchema}
      onSubmit={handleSubmit}
    >
      {({ dirty }) => (
        <Form>
          <Container>
            <Content>
              <Box display="flex">
                <Image src={BannerImage} alt="banner widget" />
                <Box pl={3.75}>
                  <TextField name="name" label="Widget Name" fullWidth />
                  <Box display="flex">
                    <DateTimePickerField name="activeFrom" label="Valid from" sx={styles.dateTimePicker} />
                    <DateTimePickerField name="activeTo" label="Valid to" sx={styles.dateTimePicker} />
                    {pageType !== PageType.Plp && (
                      <SourceSelect name="sources" />
                    )}
                    {pageType === PageType.Plp && (
                      <PositionSelect name="position" />
                    )}
                  </Box>
                </Box>
              </Box>
              <Divider sx={styles.divider} />
              <BannersConfiguration />
            </Content>
            <ActionButtons>
              <Button
                onClick={closeModal}
                variant="outlined"
                size="large"
                startIcon={<CancelIcon />}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={createMutation.isLoading || updateMutation.isLoading}
                disabled={data && !dirty}
                variant="contained"
                type="submit"
                size="large"
                startIcon={<SaveIcon />}
              >
                Save
              </LoadingButton>
            </ActionButtons>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

BannerModalContent.defaultProps = {
  id: '',
};
