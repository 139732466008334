import { Item } from 'containers/PlpPageContainer/components/WidgetList/component/Item';
import { TableBody } from '@mui/material';
import { Widget } from 'containers/PlpPageContainer/types';
import React, {
  FC,
} from 'react';

interface WidgetItems {
  items: Widget[];
  selectedWidget: Widget | null;
  handleSelect: (widget: Widget) => void;
}

export const TableWidgets: FC<WidgetItems> = ({ items, selectedWidget, handleSelect }) => (
  <TableBody>
    {
      items.map((item) => (
        <Item
          widget={item}
          key={item.id}
          selected={selectedWidget?.id === item.id}
          handleClick={handleSelect}
        />
      ))
    }
  </TableBody>
);
