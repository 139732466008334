import { WidgetLayout } from 'common/enums/widget-layout.enum';

export interface FilterProperties {
  layout?: WidgetLayout;
  onSubmitCallback?: () => Promise<void>;
}

export const filterWidgetItemsProperties = <T extends FilterProperties>({
  layout,
  onSubmitCallback,
  ...data
}: T) => data;
