import { CreateSecondaryBannerData, SecondaryBannerFormData, UpdateSecondaryBannerData } from 'containers/HomePageContainer/types/secondary-banner';
import { HomePageWidgetType } from 'containers/HomePageContainer/enum/component.enum';
import { Locale } from 'common/enums/locale';
import { PageType } from 'common/enums/page-type.enum';
import { convertDefaultTimeToDaylightSavingsTime, convertDefaultZoneToUtc } from 'utils/date';

export const mapSecondaryBannerFormDataToRequest = (
  data: SecondaryBannerFormData,
  pageTypeValue: PageType,
  culture?: Locale,
): CreateSecondaryBannerData | UpdateSecondaryBannerData => {
  const desktopItemsWithSequence = data.content.desktop.items
    .filter((item) => item.bannerName.length > 0)
    .map((item, index) => ({
      ...item,
      sequence: index,
    }));

  const mobileItemsWithSequence = data.content.mobile.items
    .filter((item) => item.bannerName.length > 0)
    .map((item, index) => ({
      ...item,
      sequence: index,
    }));

  return ({
    ...data,
    activeFrom: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeFrom)),
    activeTo: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeTo)),
    eventName: data.name,
    sources: data.sources,
    position: data.position,
    pageType: pageTypeValue,
    type: HomePageWidgetType.SecondaryBanner,
    content: {
      desktop: {
        available: data.content.desktop.available,
        items: data.content.desktop.available ? desktopItemsWithSequence : [],
      },
      mobile: {
        available: data.content.mobile.available,
        items: data.content.mobile.available ? mobileItemsWithSequence : [],
      },
    },
    ...(culture ? { culture } : {}),
  });
};
