import * as styles from 'containers/DndStackContainer/components/DndItemWrapper/styles';
import {
  Box, Card, Grid, Tooltip,
} from '@mui/material';
import { WidgetLayout } from 'common/enums/widget-layout.enum';
import { WidgetTools } from 'containers/DndStackContainer/components/WidgetTools';
import React, { FC } from 'react';

export interface DndItemWrapperProps {
  id: string;
  isDragging: boolean;
  layout: WidgetLayout;
  deleteItem: (id: string) => void;
  onEdit?: () => void;
  children: React.ReactNode;
  isActive: boolean;
}

export const DndItemWrapper: FC<DndItemWrapperProps> = ({
  children,
  layout,
  isDragging,
  isActive,
  id,
  deleteItem,
  onEdit,
}) => (
  <Grid
    container
    sx={styles.getGridContainer(layout)}
  >
    <Grid
      item
      xs={layout === WidgetLayout.Common ? 12 : 6}
      sx={styles.gridItem}
    >
      <Tooltip
        title={isDragging
          ? ''
          : <WidgetTools onDelete={() => deleteItem(id)} onEdit={onEdit} />}
        placement="top"
        componentsProps={{
          tooltip: { sx: styles.tooltip },
        }}
      >
        <Box sx={styles.draggedItemPlaceholder}>
          <Card sx={styles.getDraggedItemCard(isDragging, isActive)} id="test">
            {children}
          </Card>
        </Box>
      </Tooltip>
    </Grid>
  </Grid>
);

DndItemWrapper.defaultProps = {
  onEdit: undefined,
};
