import { PageType } from 'common/enums/page-type.enum';
import { getSelectedDateState, useSelectedDateStore } from 'common/store/client/selected-date/use-selected-date.store';
import shallow from 'zustand/shallow';

interface CommonInitialValues {
  name: string;
  isActiveDesktop: boolean;
  isActiveMobile: boolean;
  activeFrom: string;
  activeTo: string;
  sources: string[];
  position?: number;
  pageType?: PageType;
}

export const useInitCommonValues = (): CommonInitialValues => {
  const {
    date,
  } = useSelectedDateStore(getSelectedDateState, shallow);
  return {
    name: '',
    isActiveDesktop: true,
    isActiveMobile: true,
    activeFrom: date,
    activeTo: new Date(2050, 11, 31).toISOString(),
    sources: ['organic'],
  };
};
