export enum PlpPageWidgetType {
  PlpPage = 'plppage',
  Banner = 'banner',
  SecondaryBanner = 'secondaryBanner',
  ChipWidget = 'chipWidget',
  VideoWithText = 'videoWithText',
  CarouselSimplified = 'carouselSimplified',
}

export enum PlpDefaultCategory {
  id = 0,
  name = 'Default',
}
