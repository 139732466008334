import { Box, Button, Link } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import React, { FC } from 'react';
import logo from 'assets/edible-cms-logo.svg';

export const LeftPanel: FC = () => {
  const { instance } = useMsal();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      height="100vh"
      position="fixed"
      borderRight="2px solid lightgray"
    >
      <Link href="/">
        <img src={logo} width={60} height={60} alt="logo" />
      </Link>
      <Button
        sx={{ mb: 4, mr: 1 }}
        variant="outlined"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={() => instance.logout()}
      >
        Log out
      </Button>
    </Box>
  );
};
