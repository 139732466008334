import * as styles from 'common/components/GenericModal/styles';
import { Box } from '@mui/material';
import { DndBubbleTextFieldType } from 'containers/HomePageContainer/components/PageConfigurator/BubbleTextWidgetModalContent/DndBubbleTextCard/field-type.enum';
import { DndItem, DraggableBubbleTextCard } from 'containers/DndStackContainer/types';
import { DndItemWrapper } from 'containers/DndStackContainer/components/DndItemWrapper';
import { Form, Formik } from 'formik';
import { FormikErrorsListener } from 'common/components/FormikErrorsListener';
import { FormikItemSubmitFormInitializer } from 'containers/DndStackContainer/utils/FormikSubmitFormInitializer';
import { LocalizedTextField } from 'containers/HomePageContainer/utils/LocalizedTextField';
import { TextField } from 'common/components/TextField';
import { WidgetLayout } from 'common/enums/widget-layout.enum';
import { dndBubbleTextCardSchema } from 'containers/HomePageContainer/components/PageConfigurator/BubbleTextWidgetModalContent/DndBubbleTextCard/dnd-bubble-text-card.schema';
import { isEmptyOrUndefined } from 'utils/string/is-empty-or-undefined';
import React, {
  FC, FocusEvent, useCallback, useEffect, useState,
} from 'react';

export interface DndBubbleTextCardProps extends DraggableBubbleTextCard {
  isDragging: boolean;
  deleteItem: (id: string) => void;
  updateItemState: (
    id: string,
    state: DndItem
  ) => void;
}

export interface BubbleTextCardFormData {
  title: string;
  frTitle: string;
  image: string;
  link: string;
}

const defaultInitialValues: BubbleTextCardFormData = {
  title: '',
  frTitle: '',
  image: '',
  link: '',
};

export interface HandleUpdateItemProps {
  itemId: string;
  type: DndBubbleTextFieldType;
  value: string | boolean | (() => Promise<void>) | string[];
}

export const DndBubbleTextCard: FC<DndBubbleTextCardProps> = ({
  id, title, frTitle, link, updateItemState: updateItem,
  isDragging, deleteItem, layout, isValid: isInitiallyValid,
  onSubmitCallback, sources,
}) => {
  const [textLanguage, setTextLanguage] = useState('en');
  const [isValid, setIsValid] = useState(Boolean(isInitiallyValid));
  const areInitialFieldValues = isEmptyOrUndefined(title)
    || isEmptyOrUndefined(link)
    || isEmptyOrUndefined(frTitle);

  const handleUpdateItem = ({
    itemId, type, value,
  }: HandleUpdateItemProps): void => {
    if (!updateItem) {
      return;
    }
    const newState = {
      title, frTitle, link, layout, isValid, onSubmitCallback, sources,
    };
    if (type === DndBubbleTextFieldType.Title) {
      updateItem(itemId, { ...newState, title: String(value) });
      return;
    }
    if (type === DndBubbleTextFieldType.FrTitle) {
      updateItem(itemId, { ...newState, frTitle: String(value) });
      return;
    }
    if (type === DndBubbleTextFieldType.IsValid) {
      updateItem(itemId, { ...newState, isValid: Boolean(value) });
      return;
    }
    if (type === DndBubbleTextFieldType.SubmitFormCallback) {
      updateItem(itemId, { ...newState, onSubmitCallback: value as (() => Promise<void>) });
    }
    if (type === DndBubbleTextFieldType.Link) {
      updateItem(itemId, { ...newState, link: String(value) });
    }
    if (type === DndBubbleTextFieldType.Sources) {
      updateItem(itemId, { ...newState, sources: value as string[] });
    }
  };

  useEffect(() => {
    if (areInitialFieldValues) {
      handleUpdateItem({ itemId: id!, type: DndBubbleTextFieldType.IsValid, value: false });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!areInitialFieldValues) {
      handleUpdateItem({ itemId: id!, type: DndBubbleTextFieldType.IsValid, value: isValid });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  const onBlur = (fieldType: DndBubbleTextFieldType) => (
    { target: { value } }: FocusEvent<HTMLInputElement>,
  ) => {
    handleUpdateItem({ itemId: id!, type: fieldType, value: String(value) });
  };

  const setOnSubmitFormForItemCallback = useCallback((callback: () => Promise<void>) => {
    setTimeout(() => {
      handleUpdateItem({
        itemId: id!, type: DndBubbleTextFieldType.SubmitFormCallback, value: callback,
      });
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DndItemWrapper
      layout={layout ?? WidgetLayout.Common}
      isDragging={isDragging}
      isActive
      deleteItem={deleteItem}
      id={id!}
    >
      <Formik
        initialValues={id
          ? {
            id, title, frTitle, link, isValid,
          }
          : defaultInitialValues}
        validationSchema={dndBubbleTextCardSchema}
        onSubmit={() => {}}
      >
        {({ submitForm, errors, setFieldValue }) => (
          <Form>
            <Box p={2}>
              <FormikItemSubmitFormInitializer
                submitForm={submitForm}
                setOnSubmitFormForItem={setOnSubmitFormForItemCallback}
              />
              <FormikErrorsListener
                onErrors={() => setIsValid(false)}
                onNoErrors={() => setIsValid(true)}
              />
              <LocalizedTextField
                label="Text"
                englishText={title || ''}
                frenchText={frTitle || ''}
                textLanguage={textLanguage}
                setTextLanguage={setTextLanguage}
                onBlur={(event) => {
                  if (textLanguage === 'en') {
                    setFieldValue('title', event.target.value);
                    handleUpdateItem({
                      itemId: id!,
                      type: DndBubbleTextFieldType.Title,
                      value: event.target.value,
                    });
                  } else {
                    handleUpdateItem({
                      itemId: id!,
                      type: DndBubbleTextFieldType.FrTitle,
                      value: event.target.value,
                    });
                  }
                }}
                errors={errors?.title}
              />
              <TextField
                label="Text Link"
                name="link"
                variant="standard"
                sx={styles.textField}
                fullWidth
                onBlur={onBlur(DndBubbleTextFieldType.Link)}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </DndItemWrapper>
  );
};
