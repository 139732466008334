import { CreateGeneralTextWidgetData, GeneralTextWidgetFormData, UpdateGeneralTextWidgetData } from 'containers/HomePageContainer/types/general-text-widgets';
import { HomePageWidgetType } from 'containers/HomePageContainer/enum/component.enum';
import { Locale } from 'common/enums/locale';
import { convertDefaultTimeToDaylightSavingsTime, convertDefaultZoneToUtc } from 'utils/date';

export const mapGeneralTextFormDataToRequest = (
  data: GeneralTextWidgetFormData,
  culture?: Locale,
): CreateGeneralTextWidgetData | UpdateGeneralTextWidgetData => {
  const { available: desktopAvailable, ...desktopItem } = data.content.desktop;
  const { available: mobileAvailable, ...mobileItem } = data.content.mobile;

  return ({
    ...data,
    activeFrom: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeFrom)),
    activeTo: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeTo)),
    sources: data.sources,
    eventName: data.name,
    type: HomePageWidgetType.GeneralTextWidget,
    content: {
      desktop: {
        available: desktopAvailable,
        items: [{ ...desktopItem, sequence: 0 }],
      },
      mobile: {
        available: mobileAvailable,
        items: [{ ...mobileItem, sequence: 0 }],
      },
    },
    ...(culture ? { culture } : null),
  });
};
