import { SecondaryBannerFormData } from 'containers/HomePageContainer/types/secondary-banner';
import { deviceContentDefaultInitialValues } from 'containers/HomePageContainer/components/PageConfigurator/SecondaryBannerModalContent/default-initial-values';
import { useInitCommonValues } from 'utils/hooks/use-init-common-values';

export const useDefaultInitialValues = (): SecondaryBannerFormData => ({
  ...useInitCommonValues(),
  content: {
    desktop: deviceContentDefaultInitialValues,
    mobile: deviceContentDefaultInitialValues,
  },
});
