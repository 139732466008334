import {
  Box,
} from '@mui/material';
import { DndItem } from 'containers/DndSkinnyContainer/types';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';
import { DraggableItemType } from 'containers/DndSkinnyContainer/enums/draggable-item-type.enum';
import { createElementByType } from 'containers/DndSkinnyContainer/components/DraggableItem/create-element-by-type';
import React, {
  FC, useRef,
} from 'react';
import type { Identifier, XYCoord } from 'dnd-core';

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export interface DraggableItemProps {
  item: DndItem;
  'data-test': string;
  itemType: DraggableItemType;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  updateItemState: (
    id: string,
    state: DndItem
  ) => void;
  deleteItem: (id: string) => void;
  bgColor: string;
}

export const DraggableItem: FC<DraggableItemProps> = ({
  item, itemType, index, moveItem, updateItemState, deleteItem, 'data-test': dataTest, bgColor,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: DraggableItem.name,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(dragItem: { index: number }, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = dragItem.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      dragItem.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: DraggableItem.name,
    item: () => ({ id: item.id, index }),
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <Box
      ref={ref}
      data-handler-id={handlerId}
      data-test={dataTest}
      height={1}
    >
      {createElementByType({
        itemType, item, isDragging, updateItemState, deleteItem, bgColor,
      })}
    </Box>
  );
};
