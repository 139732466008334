import { BannerFormDeviceContent } from 'containers/HomePageContainer/types/banner';

export const deviceContentDefaultInitialValues: BannerFormDeviceContent = {
  available: true,
  hasGiftFinder: false,
  bannerBackgroundColor: '',
  body: '',
  frBody: '',
  altText: '',
  frAltText: '',
  src: '',
  frSrc: '',
  url: '',
};
