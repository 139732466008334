import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AppContainer } from 'containers/AppContainer';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MsalProvider } from '@azure/msal-react';
import {
  QueryClientProvider,
} from '@tanstack/react-query';
import { authService } from 'api/auth/auth.api.service';
import { queryClient } from 'api/rq-client/query-client.service';
import React from 'react';
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <MsalProvider instance={authService.getInstance()}>
    <QueryClientProvider client={queryClient}>
      <React.StrictMode>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AppContainer />
        </LocalizationProvider>
      </React.StrictMode>
    </QueryClientProvider>
  </MsalProvider>,
);
