import 'common/components/RichEditer/styles.css';
import 'suneditor/dist/css/suneditor.min.css';
import * as styles from 'common/components/GenericModal/styles';
import {
  Box,
} from '@mui/material';
import { maximumCharacterValidationMessage } from 'containers/SkinnyBannerContainer/components/PageConfigurator/SkinnyBannerModalContent/Editor/validation-constants';
import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import SunEditor from 'suneditor-react';
import SunEditorCore from 'suneditor/src/lib/core';

interface RichEditorProps {
  textEditorValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void;
  formKey: string;
  contentData: string;
  editorBackgroundColor: string;
  contentFieldName: string;
  disabled: boolean;
}
export const RichEditor: FC<RichEditorProps> = ({
  textEditorValue, formKey, contentData, editorBackgroundColor, contentFieldName, disabled,
}) => {
  const [displayCharacterLimitMessage, setDisplayCharacterLimitMessage] = useState(false);
  const [checkEditorBackground, setCheckEditorBackground] = useState(false);
  const [displayEditorBackground, setDisplayEditorBackground] = useState(false);
  const [editorBackground, setEditorBackground] = useState('');
  const [editorTextContent, setEditorTextContent] = useState('');
  const editor = useRef<SunEditorCore>();

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
    const cont = editor.current?.getText();
    if (!cont) {
      textEditorValue(`content.${formKey}.${contentFieldName}`, '<p><br></p>', true);
    }
    textEditorValue(`content.${formKey}.${contentFieldName}`, '<p><br></p>', true);
  };

  useEffect(() => {
    if (editorTextContent !== '') {
      textEditorValue(`content.${formKey}.${contentFieldName}`, editorTextContent || '<p><br></p>', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorTextContent]);

  const handleOnChange = (editorContent: string) => {
    const contentTestContainer = document.createElement('div');
    contentTestContainer.innerHTML = editorContent;
    const cont = editor.current?.getText();
    setDisplayCharacterLimitMessage(String(cont).length > 150);
    setEditorTextContent(editorContent);
  };

  const handleEditorBackground = () => {
    if (editorBackgroundColor !== editorBackground && displayEditorBackground) {
      setDisplayEditorBackground(false);
      setCheckEditorBackground(false);
    }

    if (!checkEditorBackground) {
      setEditorBackground(`${editorBackgroundColor}`);
      setCheckEditorBackground(true);
    }

    if (checkEditorBackground && !displayEditorBackground) {
      editor.current?.setOptions({
        defaultStyle: `background: ${editorBackgroundColor}`,
      });
      setDisplayEditorBackground(true);
    }
  };

  if (editorBackgroundColor && editorBackgroundColor.includes('#')) {
    handleEditorBackground();
  }

  const editorClass = displayCharacterLimitMessage
    ? styles.validatedEditorContainer : styles.editorContainer;

  const displayValidationMessage = displayCharacterLimitMessage
    ? (
      <Box sx={styles.editorValidationMessage}>
        {displayCharacterLimitMessage ? <span>{maximumCharacterValidationMessage}</span> : null}
      </Box>
    ) : null;

  return (
    <Box sx={styles.editorWrapperClass}>
      <Box sx={editorClass}>
        <Box className="editor" style={{ backgroundColor: 'white' }}>
          <SunEditor
            placeholder="Banner Text"
            setContents={contentData}
            disable={disabled}
            getSunEditorInstance={getSunEditorInstance}
            onChange={handleOnChange}
            setOptions={{
              buttonList: [
                ['bold', 'underline', 'italic'],
                ['fontColor', 'formatBlock'],
              ],
              showPathLabel: false,
              height: 'auto',
            }}
          />
        </Box>
      </Box>
      {displayValidationMessage}
    </Box>
  );
};
