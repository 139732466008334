import { AppInsightsProvider } from 'utils/telemetry/app-insights-provider';
import {
  AuthenticatedTemplate, UnauthenticatedTemplate,
} from '@azure/msal-react';
import { BrowserRouter } from 'react-router-dom';
import { DefaultSuspense } from 'common/components/DefaultSuspense';
import { GeneralAlert } from 'common/components/GeneralAlert';
import { GenericModal } from 'common/components/GenericModal';
import { MainContainer } from 'containers/MainContainer';
import { ThemeProvider } from '@mui/material';
import { isDevEnv } from 'utils/is-dev-env';
import { theme } from 'utils/theme';
import { useHandleAuthRedirect } from 'containers/AppContainer/hooks/use-handle-auth-redirect';
import React, {
  FC, lazy, useEffect, useState,
} from 'react';

const ReactQueryDevtools = lazy(
  () => import('@tanstack/react-query-devtools/build/lib/index.prod.js')
    .then((d) => ({
      default: d.ReactQueryDevtools,
    })),
);
const Login = lazy(() => import('containers/LoginContainer'));

export const AppContainer: FC = () => {
  useHandleAuthRedirect();

  const [showDevtools, setShowDevtools] = useState(false);

  useEffect(() => {
    window.toggleRQDevTools = () => setShowDevtools((old) => !old);
  }, []);

  if (window.APP_INSIGHTS_INSTRUMENTATION_KEY) {
    AppInsightsProvider.init(window.APP_INSIGHTS_INSTRUMENTATION_KEY);
  }

  return (
    <>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          {window.DISABLE_AUTH_GUARD
            ? <MainContainer />
            : (
              <>
                <AuthenticatedTemplate>
                  <MainContainer />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <DefaultSuspense>
                    <Login />
                  </DefaultSuspense>
                </UnauthenticatedTemplate>
              </>
            )}
          <GeneralAlert />
          <GenericModal />
        </ThemeProvider>
      </BrowserRouter>
      {showDevtools && isDevEnv && <ReactQueryDevtools />}
    </>
  );
};
