import {
  Backdrop, CircularProgress,
} from '@mui/material';

import React, { FC } from 'react';

interface Props {
  isLoading: boolean;
}

export const Loader: FC<Props> = ({ isLoading }) => (
  <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={isLoading}
  >
    <CircularProgress color="inherit" />
  </Backdrop>

);
