import * as styles from 'common/components/GenericModal/styles';
import { BVSocialFormData } from 'containers/HomePageContainer/types/bvsocial';
import {
  Box,
  Grid,
} from '@mui/material';
import { DEVICE_ACTIVATION_SWITCH_WARNING } from 'containers/HomePageContainer/components/PageConfigurator/constants';
import { Switch } from 'common/components/Switch';
import { useFormikContext } from 'formik';
import { useGlobalAlertStore } from 'common/store/client/global-alert/use-global-alert.store';
import React, { FC } from 'react';

interface DeviceData {
  title: string;
  key: 'desktop' | 'mobile';
}

const devicesData: DeviceData[] = [{
  title: 'Desktop',
  key: 'desktop',
}, {
  title: 'Mobile',
  key: 'mobile',
}];

export const BVSocialConfiguration: FC = () => {
  const { values, setFieldValue } = useFormikContext<BVSocialFormData>();
  const openAlert = useGlobalAlertStore((store) => store.openAlert);

  return (
    <Grid container spacing={10}>
      {devicesData.map(({ title, key }) => (
        <Grid key={key} item xs={6}>
          <Box display="flex" flexDirection="column">
            <Switch
              name={`content.${key}.available`}
              label={title}
              labelPlacement="start"
              onChange={(event) => {
                if (
                  event.target.checked
                    || (values.content.desktop.available && values.content.mobile.available)
                ) {
                  setFieldValue(`content.${key}.available`, event.target.checked);
                } else {
                  openAlert(DEVICE_ACTIVATION_SWITCH_WARNING);
                }
              }}
              sx={styles.availabilitySwitch}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
