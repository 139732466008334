import { BVSocialModalContent } from 'containers/HomePageContainer/components/PageConfigurator/BVSocialModalContent';
import { BannerModalContent } from 'containers/HomePageContainer/components/PageConfigurator/BannerModalContent';
import { BannerProductListModalContent } from 'containers/HomePageContainer/components/PageConfigurator/BannerWithProductListModalContent';
import { Box } from '@mui/material';
import { BubbleTextWidgetModalContent } from 'containers/HomePageContainer/components/PageConfigurator/BubbleTextWidgetModalContent';
import { CreateNewWidgetModalContent } from 'containers/HomePageContainer/components/PageConfigurator/CreateNewWidgetModalContent';
import { GeneralTextModalContent } from 'containers/HomePageContainer/components/PageConfigurator/generalTextModalContent';
import { HeroBannerModalContent } from 'containers/HomePageContainer/components/PageConfigurator/HeroBannerModalContent';
import { ModalContentType } from 'common/enums/modal-content-type';
import { NotificationBannerContent } from 'containers/HomePageContainer/components/PageConfigurator/NotificationBannerModalContent';
import { PageType } from 'common/enums/page-type.enum';
import { ProductCarouselModalContent } from 'containers/HomePageContainer/components/PageConfigurator/ProductCarouselModalContent';
import { SecondaryBannerModalContent } from 'containers/HomePageContainer/components/PageConfigurator/SecondaryBannerModalContent';
import { ShopByCategoryModalContent } from 'containers/HomePageContainer/components/PageConfigurator/ShopByCategoryModalContent';
import { ShopByOccasionModalContent } from 'containers/HomePageContainer/components/PageConfigurator/ShopByOccasionModalContent';
import { VideoTextModalContent } from 'containers/HomePageContainer/components/PageConfigurator/VideoWithTextModalContent';
import { WidgetList } from 'containers/HomePageContainer/components/WidgetList';
import React from 'react';

interface Parameters {
  type: ModalContentType;
  closeModal: () => void;
  id?: string;
  modalPageType: PageType;
}

export function getHomeModalContent({
  type,
  closeModal,
  id,
  modalPageType,
}: Parameters) {
  if (
    type === ModalContentType.CreateHeroBannerWidget
    || type === ModalContentType.ModifyHeroBannerWidget
  ) {
    return (
      <HeroBannerModalContent id={id} closeModal={closeModal} pageType={modalPageType} />
    );
  }
  if (
    type === ModalContentType.CreateProductCarouselWidget
    || type === ModalContentType.ModifyProductCarouselWidget
  ) {
    return (
      <ProductCarouselModalContent closeModal={closeModal} id={id} pageType={modalPageType} />
    );
  }
  if (
    type === ModalContentType.CreateBannerWithProductListWidget
    || type === ModalContentType.ModifyBannerWithProductListWidget
  ) {
    return (
      <BannerProductListModalContent closeModal={closeModal} id={id} pageType={modalPageType} />
    );
  }
  if (
    type === ModalContentType.CreateVideoWithTextWidget
    || type === ModalContentType.ModifyVideoWithTextWidget
  ) {
    return (
      <VideoTextModalContent closeModal={closeModal} id={id} pageType={modalPageType} />
    );
  }
  if (
    type === ModalContentType.CreateBVSocialWidget
    || type === ModalContentType.ModifyBVSocialWidget
  ) {
    return (
      <BVSocialModalContent closeModal={closeModal} id={id} pageType={modalPageType} />
    );
  }
  if (
    type === ModalContentType.CreateBannerWidget
    || type === ModalContentType.ModifyBannerWidget
  ) {
    return (
      <BannerModalContent closeModal={closeModal} id={id} pageType={modalPageType} />
    );
  }
  if (
    type === ModalContentType.CreateSecondaryBannerWidget
    || type === ModalContentType.ModifySecondaryBannerWidget
  ) {
    return (
      <SecondaryBannerModalContent closeModal={closeModal} id={id} pageType={modalPageType} />
    );
  }
  if (
    type === ModalContentType.CreateShopByOccasionWidget
    || type === ModalContentType.ModifyShopByOccasionWidget
  ) {
    return (
      <ShopByOccasionModalContent closeModal={closeModal} id={id} pageType={modalPageType} />
    );
  }
  if (
    type === ModalContentType.CreateShopByCategoryWidget
    || type === ModalContentType.ModifyShopByCategoryWidget
  ) {
    return (
      <ShopByCategoryModalContent closeModal={closeModal} id={id} pageType={modalPageType} />
    );
  }
  if (
    type === ModalContentType.CreateChipWidget
    || type === ModalContentType.ModifyChipWidget
  ) {
    return (
      <BubbleTextWidgetModalContent closeModal={closeModal} id={id} pageType={modalPageType} />
    );
  }
  if (type === ModalContentType.CreateGeneralTextWidget
    || type === ModalContentType.ModifyGeneralTextWidget) {
    return (
      <GeneralTextModalContent id={id} closeModal={closeModal} pageType={modalPageType} />
    );
  }
  if (
    type === ModalContentType.CreateNotificationBannerWidget
    || type === ModalContentType.ModifyNotificationBannerWidget
  ) {
    return (
      <NotificationBannerContent closeModal={closeModal} id={id} pageType={modalPageType} />
    );
  }
  if (type === ModalContentType.CreateWidget) {
    return (
      <CreateNewWidgetModalContent pageType={modalPageType} />
    );
  }
  if (type === ModalContentType.SelectWidget) {
    return (
      <WidgetList handleClose={closeModal} />
    );
  }
  return <Box>{type}</Box>;
}
