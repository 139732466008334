import * as styles from 'common/components/GenericModal/styles';
import { BannerFormData } from 'containers/HomePageContainer/types/banner';
import {
  Box,
  Grid,
} from '@mui/material';
import { DEVICE_ACTIVATION_SWITCH_WARNING } from 'containers/HomePageContainer/components/PageConfigurator/constants';
import { LocalizedButtonText } from 'common/components/RichEditer/Localized-Button-Label';
import { LocalizedTextField } from 'containers/HomePageContainer/utils/LocalizedTextField';
import { RichEditor } from 'containers/HomePageContainer/components/PageConfigurator/BannerModalContent/Editor/index';
import { Switch } from 'common/components/Switch';
import { TextField } from 'common/components/TextField';
import { TwitterPicker } from 'react-color';
import { useFormikContext } from 'formik';
import { useGlobalAlertStore } from 'common/store/client/global-alert/use-global-alert.store';
import React, {
  FC, useEffect, useRef, useState,
} from 'react';

interface DeviceData {
  title: string;
  key: 'desktop' | 'mobile';
}

interface FieldToggle {
  desktop: string;
  mobile: string;
}

const devicesData: DeviceData[] = [{
  title: 'Desktop',
  key: 'desktop',
}, {
  title: 'Mobile',
  key: 'mobile',
}];

const srcToggle: FieldToggle = {
  desktop: 'en',
  mobile: 'en',
};

const altTextToggle: FieldToggle = {
  desktop: 'en',
  mobile: 'en',
};

export const BannersConfiguration: FC = () => {
  const { values, setFieldValue, errors } = useFormikContext<BannerFormData>();
  const openAlert = useGlobalAlertStore((store) => store.openAlert);
  const [desktopBackground, setDesktopBackground] = useState<string>('');
  const [mobileBackground, setMobileBackground] = useState<string>('');
  const [desktopColorPicker, setDesktopColorPicker] = useState<boolean>(false);
  const [mobileColorPicker, setMobileColorPicker] = useState<boolean>(false);
  const [desktopImageDisable, setDesktopImageDisable] = useState<boolean>(false);
  const [mobileImageDisable, setMobileImageDisable] = useState<boolean>(false);
  const mobileRef = useRef<HTMLDivElement | null>(null);
  const desktopRef = useRef<HTMLDivElement | null>(null);
  const [src, setSrc] = useState<FieldToggle>(srcToggle);
  const [altText, setAltText] = useState<FieldToggle>(altTextToggle);

  const isCA = window.COUNTRY === 'CA';

  const handleMobileChange = ({ hex }: { hex: string }) => {
    setMobileBackground(hex.toUpperCase());
    setFieldValue('content.mobile.bannerBackgroundColor', hex);
    setMobileColorPicker(false);
    setMobileImageDisable(true);
  };
  const handleDesktopChange = ({ hex }: { hex: string }) => {
    setDesktopBackground(hex.toUpperCase());
    setFieldValue('content.desktop.bannerBackgroundColor', hex);
    setDesktopColorPicker(false);
    setDesktopImageDisable(true);
  };
  const mobileHandleClickOutside = (event: Event) => {
    if (mobileRef.current && !mobileRef.current.contains(event.target as Node)) {
      setMobileColorPicker(false);
    }
  };
  const desktopHandleClickOutside = (event: Event) => {
    if (desktopRef.current && !desktopRef.current.contains(event.target as Node)) {
      setDesktopColorPicker(false);
    }
  };
  const handleSrcLanguageChange = (key: string, newLang: string) => {
    setSrc((prevState) => ({
      ...prevState,
      [key]: newLang,
    }));
  };

  const handleAltTextLanguageChange = (key: string, newLang: string) => {
    setAltText((prevState) => ({
      ...prevState,
      [key]: newLang,
    }));
  };

  useEffect(() => {
    document.addEventListener('mousedown', mobileHandleClickOutside);
    document.addEventListener('mousedown', desktopHandleClickOutside);
    return () => {
      document.removeEventListener('mousedown', mobileHandleClickOutside);
      document.removeEventListener('mousedown', desktopHandleClickOutside);
    };
  }, []);

  const disableBackgroundColor = (val: string, key: string) => {
    const element = document.getElementById(`${key}BackgroundColorContainer`);
    if (element) {
      if (val && val !== '') {
        element.style.opacity = '1';
        element.style.pointerEvents = 'none';
      } else {
        element.style.opacity = '';
        element.style.pointerEvents = '';
      }
    }
  };
  useEffect(() => {
    setDesktopBackground(values.content.desktop.bannerBackgroundColor
      && values.content.desktop.bannerBackgroundColor.toUpperCase());
    setMobileBackground(values.content.mobile.bannerBackgroundColor
      && values.content.mobile.bannerBackgroundColor.toUpperCase());
  }, [values.content.desktop.bannerBackgroundColor, values.content.mobile.bannerBackgroundColor]);
  const setSelected = (checked: boolean, keySelected: string) => {
    setFieldValue(`content.${keySelected}.hasGiftFinder`, checked);
  };

  return (
    <Grid container spacing={10}>
      {devicesData.map(({ title, key }) => {
        const disabled = !values.content[key].available;
        const pageLinkUrlDisabled = values.content[key].hasGiftFinder;
        const disableImageBoxFlag = key === 'desktop' ? desktopImageDisable : mobileImageDisable;
        const disableImageBox = values.content[key].bannerBackgroundColor
          ? true : disableImageBoxFlag;
        const disableAltTextBox = disableImageBox || !values.content[key].src;

        if (values.content[key].src) {
          disableBackgroundColor(values.content[key].src, key);
        }
        const bgColorCon = document.getElementById(`${key}BackgroundColorContainer`);
        if (bgColorCon) {
          if (pageLinkUrlDisabled) {
            bgColorCon.style.opacity = '1';
            bgColorCon.style.pointerEvents = 'none';
          } else {
            bgColorCon.style.opacity = '';
            bgColorCon.style.pointerEvents = '';
          }
        }

        return (
          <Grid key={key} item xs={6}>
            <Box display="flex" flexDirection="column">
              <Switch
                name={`content.${key}.available`}
                label={title}
                labelPlacement="start"
                onChange={(event) => {
                  if (
                    event.target.checked
                    || (values.content.desktop.available && values.content.mobile.available)
                  ) {
                    setFieldValue(`content.${key}.available`, event.target.checked);
                  } else {
                    openAlert(DEVICE_ACTIVATION_SWITCH_WARNING);
                  }
                }}
                sx={styles.availabilitySwitch}
              />
              <Switch name={`content.${key}.hasGiftFinder`} label="Gift Finder" labelPlacement="start" sx={styles.hasGiftFinder} onChange={(event) => setSelected(event.target.checked, key)} disabled={disabled} />
              <Box
                display="flex"
                id={`${key}BackgroundColorContainer`}
                onClick={key === 'mobile' ? () => { setMobileColorPicker(!mobileColorPicker); setMobileImageDisable(false); } : () => { setDesktopColorPicker(!desktopColorPicker); setDesktopImageDisable(false); }}
              >
                <TextField
                  autoComplete="off"
                  name={`content.${key}.bannerBackgroundColor`}
                  disabled={disabled || pageLinkUrlDisabled}
                  label="Banner Background Color"
                  sx={styles.textField}
                  value={key === 'desktop' ? desktopBackground : mobileBackground}
                  type="text"
                />
                <Box sx={styles.colorBox} style={{ backgroundColor: key === 'mobile' ? mobileBackground : desktopBackground }} />
              </Box>
              <Box sx={styles.colorPicker}>
                {(key === 'mobile' && mobileColorPicker)
              && (
                <TwitterPicker
                  color={mobileBackground}
                  onChangeComplete={handleMobileChange}
                />
              )}
                {(key === 'desktop' && desktopColorPicker)
              && (
                <TwitterPicker
                  color={desktopBackground}
                  onChangeComplete={handleDesktopChange}
                />
              )}
              </Box>
              {key === 'mobile'
                && (
                  <>
                    {isCA
                && (
                  <LocalizedButtonText labelText="En" />
                )}
                    <RichEditor
                      formKey={key}
                      textEditorValue={setFieldValue}
                      editorBackgroundColor={mobileBackground}
                      contentData={values.content.mobile.body || ''}
                      contentFieldName="body"
                      disabled={pageLinkUrlDisabled}
                    />
                    {isCA
                && (
                  <>
                    <LocalizedButtonText labelText="Fr" />
                    <RichEditor
                      formKey={key}
                      textEditorValue={setFieldValue}
                      editorBackgroundColor={mobileBackground}
                      contentData={values.content.mobile.frBody || ''}
                      contentFieldName="frBody"
                      disabled={pageLinkUrlDisabled}
                    />
                  </>
                )}
                  </>
                )}
              {key === 'desktop'
                && (
                  <>
                    {isCA
                && (
                  <LocalizedButtonText labelText="En" />
                )}

                    <RichEditor
                      formKey={key}
                      textEditorValue={setFieldValue}
                      editorBackgroundColor={desktopBackground}
                      contentData={values.content.desktop.body || ''}
                      contentFieldName="body"
                      disabled={pageLinkUrlDisabled}
                    />
                    {isCA
                && (
                  <>
                    <LocalizedButtonText labelText="Fr" />
                    <RichEditor
                      formKey={key}
                      textEditorValue={setFieldValue}
                      editorBackgroundColor={desktopBackground}
                      contentData={values.content.desktop.frBody || ''}
                      contentFieldName="frBody"
                      disabled={pageLinkUrlDisabled}
                    />
                  </>
                )}
                  </>
                )}
              <LocalizedTextField
                label="src"
                englishText={values.content[key].src || ''}
                frenchText={values.content[key].frSrc || ''}
                textLanguage={src[key]}
                setTextLanguage={(newLang) => handleSrcLanguageChange(key, newLang)}
                onBlur={(event) => {
                  if (src[key] === 'fr') {
                    setFieldValue(`content.${key}.frSrc`, event.target.value);
                  } else {
                    setFieldValue(`content.${key}.src`, event.target.value);
                  }
                  disableBackgroundColor(event.target.value, key);
                }}
                variant="outlined"
                disabled={disableImageBox || pageLinkUrlDisabled}
                errors={key === 'desktop' ? errors?.content?.desktop?.src : errors?.content?.mobile?.src}
              />
              <LocalizedTextField
                label="altText"
                englishText={values.content[key].altText || ''}
                frenchText={values.content[key].frAltText || ''}
                textLanguage={altText[key]}
                setTextLanguage={(newLang) => handleAltTextLanguageChange(key, newLang)}
                onBlur={(event) => {
                  if (altText[key] === 'fr') {
                    setFieldValue(`content.${key}.frAltText`, event.target.value);
                  } else {
                    setFieldValue(`content.${key}.altText`, event.target.value);
                  }
                }}
                variant="outlined"
                disabled={disableAltTextBox || pageLinkUrlDisabled}
                errors={key === 'desktop' ? errors?.content?.desktop?.altText : errors?.content?.mobile?.altText}
              />
              <TextField name={`content.${key}.url`} disabled={disabled || pageLinkUrlDisabled} label="Page Link URL" sx={styles.textField} />
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
