import { DraggableBannerCard } from 'containers/DndStackContainer/types';
import {
  ShopByOccasionDeviceDataItem,
  ShopByOccasionFormData,
  ShopByOccasionWidget,
} from 'containers/HomePageContainer/types/shop-by-occasion';
import { convertDaylightSavingsTimeToDefaultTime, convertUtcToDefaultZone } from 'utils/date';

export const mapItemsToInitialValues = (
  items: ShopByOccasionDeviceDataItem[] = [],
  sources: string[] = ['organic'],
): DraggableBannerCard[] => (items || []).map((item) => ({
  id: item.id,
  name: item.name,
  frName: item.frName,
  image: item.src,
  frImage: item.frSrc,
  link: item.url,
  isValid: true,
  sources,
}));

export const mapWidgetDataToFormInitialValues = (
  data: ShopByOccasionWidget,
): ShopByOccasionFormData => {
  const sourcesArray = Array.isArray(data.sources) ? data.sources : [data.sources];
  const initializeArrayIfUndefined = !sourcesArray || sourcesArray[0] === undefined ? ['organic'] : sourcesArray;
  return {
    name: data.name,
    activeFrom: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeFrom)),
    activeTo: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeTo)),
    isActiveDesktop: Boolean(data.content?.desktop?.available),
    isActiveMobile: Boolean(data.content?.mobile?.available),
    occasionTitleDesktop: data.content?.desktop?.title,
    occasionFrTitleDesktop: data.content?.desktop?.frTitle,
    occasionTitleMobile: data.content?.mobile?.title,
    occasionFrTitleMobile: data.content?.mobile?.frTitle,
    occasionBgColorDesktop: data.content?.desktop?.bgColor,
    occasionBgColorMobile: data.content?.mobile?.bgColor,
    sources: initializeArrayIfUndefined,
    position: data.position,
  };
};
