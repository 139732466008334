import { ProductCarousel, ProductCarouselFormData } from 'containers/HomePageContainer/types/product-carousel';
import { convertDaylightSavingsTimeToDefaultTime, convertUtcToDefaultZone } from 'utils/date';

export const mapWidgetDataToFormInitialValues = (
  data: ProductCarousel,
): ProductCarouselFormData => ({
  name: data.name,
  activeFrom: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeFrom)),
  activeTo: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeTo)),
  sources: data.sources,
  content: data.content,
});
