import { GeneralTextWidget, GeneralTextWidgetFormData } from 'containers/HomePageContainer/types/general-text-widgets';
import { convertDaylightSavingsTimeToDefaultTime, convertUtcToDefaultZone } from 'utils/date';
import { deviceContentDefaultInitialValues } from 'containers/HomePageContainer/components/PageConfigurator/generalTextModalContent/default-initial-value';

export const mapWidgetDataToFormInitialValues = (
  data: GeneralTextWidget,
): GeneralTextWidgetFormData => ({
  name: data.name,
  activeFrom: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeFrom)),
  activeTo: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeTo)),
  sources: data.sources,
  content: {
    desktop: {
      ...((data.content.desktop.items || [])[0] || deviceContentDefaultInitialValues),
      available: data.content.desktop.available,
    },
    mobile: {
      ...((data.content.mobile.items || [])[0] || deviceContentDefaultInitialValues),
      available: data.content.mobile.available,
    },
  },
});
