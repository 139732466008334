import * as Yup from 'yup';
import { PageType } from 'common/enums/page-type.enum';

const stringShouldNotBeEmpty = 'This field is required, must be a non empty string';
const valueMustBeString = 'The value must be a string';
const dateShouldBeSet = 'Date should be set';
const valueMustBeDate = 'The value must be a date';
const activeToMustBeAfterActiveFrom = 'Valid to must be after Valid from';
const sourceArrayShouldNotBeEmpty = 'Source must contain at least one item';
const sourceIsRequired = 'Source is required';
const positionIsRequired = 'Position is required';
const valueMustBeNumber = 'The value must be a number';

const conditionOptions = {
  is: true,
  then: Yup.string().required(stringShouldNotBeEmpty),
};

export const shopByOccasionWidgetSchema = Yup.object().shape({
  name: Yup.string().min(1).required()
    .typeError(valueMustBeString)
    .required(stringShouldNotBeEmpty),
  occasionTitleDesktop: Yup.string().min(1)
    .typeError(valueMustBeString)
    .when('isActiveDesktop', conditionOptions),
  occasionTitleMobile: Yup.string().min(1)
    .typeError(valueMustBeString)
    .when('isActiveMobile', conditionOptions),
  occasionBgColorDesktop: Yup.string().min(1)
    .typeError(valueMustBeString),
  occasionBgColorMobile: Yup.string().min(1)
    .typeError(valueMustBeString),
  activeFrom: Yup.date()
    .typeError(valueMustBeDate)
    .required(dateShouldBeSet),
  sources: Yup.array().of(Yup.string())
    .min(1, sourceArrayShouldNotBeEmpty)
    .required(sourceIsRequired),
  position: Yup.number()
    .typeError(valueMustBeNumber)
    .when('pageType', {
      is: PageType.Plp,
      then: Yup.number().required(positionIsRequired),
    }),
  pageType: Yup.string().min(1)
    .typeError(valueMustBeString),
  activeTo: Yup.date()
    .typeError(valueMustBeDate)
    .when('activeFrom', (activeFrom) => Yup.date().min(
      activeFrom,
      activeToMustBeAfterActiveFrom,
    ))
    .required(dateShouldBeSet),
});
