import { AppInsightsProvider } from 'utils/telemetry/app-insights-provider';
import { AxiosError, AxiosResponse } from 'axios';
import { HttpStatusCode } from 'common/enums/http-status-code.enum';
import { SeverityLevel } from 'utils/telemetry/severity-level.enum';
import { useUnauthorizedRequestStore } from 'containers/AppContainer/store/client/unauthorized-request/use-unauthorized-request.store';

export const rejectNotAuthorizedResponse = (
  error: AxiosError,
): Promise<AxiosResponse> => {
  const { response } = error;
  if (response?.status === HttpStatusCode.NotAuthorized
    || response?.status === HttpStatusCode.Forbidden
  ) {
    const { setUnauthorized } = useUnauthorizedRequestStore.getState();
    AppInsightsProvider.trackError(
      response as unknown as Error,
      SeverityLevel.Critical,
    );
    setUnauthorized(true);
  }
  return Promise.reject(error);
};
