import { Dialog, DialogContent } from '@mui/material';
import { DialogHeader } from 'common/components/GenericModal/Header';
import { PageType } from 'common/enums/page-type.enum';
import { getHomeModalContent } from 'containers/HomePageContainer/utils/get-modal-type';
import { getModalState, useModal } from 'common/components/GenericModal/store/client/use-modal.store';
import { getPlpModalContent } from 'containers/PlpPageContainer/utils/get-modal-type';
import { getSkinnyBannerModalContent } from 'containers/SkinnyBannerContainer/utils/get-modal-type';
import React, { FC } from 'react';
import shallow from 'zustand/shallow';

export const GenericModal: FC = () => {
  const {
    open,
    title,
    type,
    id,
    pageType,
  } = useModal(getModalState, shallow);
  const closeModal = useModal((store) => store.closeModal);
  let modalPageType = PageType.HomePage;

  const renderModalContent = () => {
    switch (pageType) {
      case 'skinnybanner':
        return getSkinnyBannerModalContent({ type, closeModal, id });
      case 'plp':
        modalPageType = PageType.Plp;
        return getPlpModalContent({
          type,
          closeModal,
          id,
          modalPageType,
        });
      default:
        return getHomeModalContent({
          type,
          closeModal,
          id,
          modalPageType,
        });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        sx: { borderRadius: '32px' },
      }}
    >
      <DialogHeader onClose={closeModal} title={title} />
      <DialogContent sx={{ pt: 0 }}>
        {renderModalContent()}
      </DialogContent>
    </Dialog>
  );
};
