import * as styles from 'common/components/GenericModal/styles';
import {
  BannerWithProductListFormData, Sort,
} from 'containers/HomePageContainer/types/banner-with-product-list';
import {
  Box,
  Grid,
  MenuItem,
} from '@mui/material';
import { DEVICE_ACTIVATION_SWITCH_WARNING } from 'containers/HomePageContainer/components/PageConfigurator/constants';
import { LocalizedTextField } from 'containers/HomePageContainer/utils/LocalizedTextField';
import { Select } from 'common/components/Select';
import { Switch } from 'common/components/Switch';
import { TextField } from 'common/components/TextField';
import { useFormikContext } from 'formik';
import { useGlobalAlertStore } from 'common/store/client/global-alert/use-global-alert.store';
import React, { FC, useState } from 'react';

interface DeviceData {
  title: string;
  key: 'desktop' | 'mobile';
}

const devicesData: DeviceData[] = [{
  title: 'Desktop',
  key: 'desktop',
}, {
  title: 'Mobile',
  key: 'mobile',
}];

interface FieldToggle {
  desktop: string;
  mobile: string;
}

const srcToggle: FieldToggle = {
  desktop: 'en',
  mobile: 'en',
};

const ctaLinkToggle: FieldToggle = {
  desktop: 'en',
  mobile: 'en',
};

const altTextToggle: FieldToggle = {
  desktop: 'en',
  mobile: 'en',
};

export const BannerProductListConfiguration: FC = () => {
  const { values, setFieldValue, errors } = useFormikContext<BannerWithProductListFormData>();
  const [src, setSrc] = useState<FieldToggle>(srcToggle);
  const [ctaLink, setCtaLink] = useState<FieldToggle>(ctaLinkToggle);
  const [altText, setAltText] = useState<FieldToggle>(altTextToggle);

  const openAlert = useGlobalAlertStore((store) => store.openAlert);

  const handleSrcLanguageChange = (key: string, newLang: string) => {
    setSrc((prevState) => ({
      ...prevState,
      [key]: newLang,
    }));
  };

  const handleCtaLinkLanguageChange = (key: string, newLang: string) => {
    setCtaLink((prevState) => ({
      ...prevState,
      [key]: newLang,
    }));
  };

  const handleAltTextLanguageChange = (key: string, newLang: string) => {
    setAltText((prevState) => ({
      ...prevState,
      [key]: newLang,
    }));
  };

  return (
    <Grid container spacing={10}>
      {devicesData.map(({ title, key }) => {
        const disabled = !values.content[key].available;

        return (
          <Grid key={key} item xs={6}>
            <Box display="flex" flexDirection="column">
              <Switch
                name={`content.${key}.available`}
                label={title}
                labelPlacement="start"
                onChange={(event) => {
                  if (
                    event.target.checked
                    || (values.content.desktop.available && values.content.mobile.available)
                  ) {
                    setFieldValue(`content.${key}.available`, event.target.checked);
                  } else {
                    openAlert(DEVICE_ACTIVATION_SWITCH_WARNING);
                  }
                }}
                sx={styles.availabilitySwitch}
              />
              <LocalizedTextField
                label="Banner Image"
                englishText={values.content[key].src || ''}
                frenchText={values.content[key].frSrc || ''}
                textLanguage={src[key]}
                setTextLanguage={(newLang) => handleSrcLanguageChange(key, newLang)}
                onBlur={(event) => {
                  if (src[key] === 'fr') {
                    setFieldValue(`content.${key}.frSrc`, event.target.value);
                  } else {
                    setFieldValue(`content.${key}.src`, event.target.value);
                  }
                }}
                variant="outlined"
                errors={key === 'desktop' ? errors?.content?.desktop?.src : errors?.content?.mobile?.src}

              />
              <LocalizedTextField
                label="Banner Image CTA LInk"
                englishText={values.content[key].ctaLink || ''}
                frenchText={values.content[key].frCtaLink || ''}
                textLanguage={ctaLink[key]}
                setTextLanguage={(newLang) => handleCtaLinkLanguageChange(key, newLang)}
                onBlur={(event) => {
                  if (ctaLink[key] === 'fr') {
                    setFieldValue(`content.${key}.frCtaLink`, event.target.value);
                  } else {
                    setFieldValue(`content.${key}.ctaLink`, event.target.value);
                  }
                }}
                variant="outlined"
                errors={key === 'desktop' ? errors?.content?.desktop?.ctaLink : errors?.content?.mobile?.ctaLink}
              />
              <LocalizedTextField
                label="Alt Text"
                englishText={values.content[key].altText || ''}
                frenchText={values.content[key].frAltText || ''}
                textLanguage={altText[key]}
                setTextLanguage={(newLang) => handleAltTextLanguageChange(key, newLang)}
                onBlur={(event) => {
                  if (altText[key] === 'fr') {
                    setFieldValue(`content.${key}.frAltText`, event.target.value);
                  } else {
                    setFieldValue(`content.${key}.altText`, event.target.value);
                  }
                }}
                variant="outlined"
                errors={key === 'desktop' ? errors?.content?.desktop?.altText : errors?.content?.mobile?.altText}
              />
              <TextField name={`content.${key}.url`} disabled={disabled} label="Carousel URL" sx={styles.textField} />
              <Select name={`content.${key}.filterParams.sort`} disabled={disabled} label="Sort" sx={styles.textField}>
                <MenuItem value={Sort.Sequence}>Sequence</MenuItem>
                <MenuItem value={Sort.PriceLowToHigh}>Price Low to High</MenuItem>
                <MenuItem value={Sort.PriceHighToLow}>Price High to Low</MenuItem>
              </Select>
              <TextField
                type="number"
                name={`content.${key}.filterParams.limit`}
                disabled={disabled}
                label="Item Limit"
                sx={styles.textField}
              />
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
