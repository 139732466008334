import { GreateTextWigetFormContent } from 'containers/HomePageContainer/types/general-text-widgets';

export const deviceContentDefaultInitialValues: GreateTextWigetFormContent = {
  available: true,
  body: '',
  frBody: '',
  accordian: false,
  expandAccordian: false,
  title: '',
  frTitle: '',
};
