import { GenericStore } from 'common/types/generic-store-state.types';
import { actionKey, storeKey } from 'utils/redux-devtools/constants';
import { connectToDevtools } from 'utils/redux-devtools/connect-to-devtools';
import { devOnly } from 'utils/redux-devtools/dev-only';
import { immer } from 'zustand/middleware/immer';
import create from 'zustand';

export interface SelectedDateState {
  date: string;
}

interface SelectedDateStore extends GenericStore, SelectedDateState {
  setSelectedDate: (props: SelectedDateState) => void;
}

const storeName = 'app/selected-date';

const initialState: SelectedDateState = {
  date: '',
};

export const getSelectedDateState = (store: SelectedDateStore) => ({
  date: store.date,
});

export const useSelectedDateStore = create<SelectedDateStore>()(immer((set) => ({
  [storeKey]: devOnly(storeName),
  ...initialState,
  setSelectedDate: (payload: SelectedDateState) => set((store) => {
    store.date = payload.date;
    store[actionKey] = devOnly('setSelectedDate');
  }),
})));

connectToDevtools(useSelectedDateStore);
