import * as styles from 'common/components/GenericModal/styles';
import {
  ActionButtons,
  Container,
  Content,
  Image,
} from 'common/components/GenericModal/styles';
import {
  Box,
  Button, Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import {
  CreateShopByOccasionData,
  ShopByOccasionFormData,
  ShopByOccasionWidget, UpdateShopByOccasionData,
} from 'containers/HomePageContainer/types/shop-by-occasion';
import { DEVICE_ACTIVATION_SWITCH_WARNING } from 'containers/HomePageContainer/components/PageConfigurator/constants';
import { DateTimePickerField } from 'common/components/DateTimePickerField';
import { DndProvider } from 'react-dnd';
import { DndStackContainer } from 'containers/DndStackContainer/DndStackContainer';
import { DraggableItemType } from 'containers/DndStackContainer/enums/draggable-item-type.enum';
import { DraggableOccasionCard } from 'containers/DndStackContainer/types';
import {
  Field, FieldProps, Form, Formik,
} from 'formik';
import { FormikErrorsListener } from 'common/components/FormikErrorsListener';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Loader } from 'common/components/Loader';
import { LoadingButton } from '@mui/lab';
import { LocalizedTextField } from 'containers/HomePageContainer/utils/LocalizedTextField';
import { PageType } from 'common/enums/page-type.enum';
import { PositionSelect } from 'common/components/StaticWidgetSelect';
import { SourceSelect } from 'common/components/SourceSelect';
import { TextField } from 'common/components/TextField/index';
import { TwitterPicker } from 'react-color';
import { areWidgetItemsEqualToInitialItems } from 'containers/HomePageContainer/utils/are-widget-items-equal-to-initial-items';
import { mapItemsToInitialValues, mapWidgetDataToFormInitialValues } from 'containers/HomePageContainer/components/PageConfigurator/ShopByOccasionModalContent/mappers/map-widget-data-to-form-initial-values';
import { mapShopByOccasionFormDataToRequest } from 'containers/HomePageContainer/components/PageConfigurator/ShopByOccasionModalContent/mappers/map-shop-by-occasion-form-data-to-request';
import { shopByOccasionWidgetSchema } from 'containers/HomePageContainer/components/PageConfigurator/ShopByOccasionModalContent/shop-by-occasion-widget.schema';
import { useCreateWidgetMutation } from 'containers/HomePageContainer/store/server/use-create-widget-mutation';
import {
  useDefaultInitialValues,
} from 'containers/HomePageContainer/components/PageConfigurator/ShopByOccasionModalContent/hooks/use-default-initial-values';
import { useGlobalAlertStore } from 'common/store/client/global-alert/use-global-alert.store';
import { useSearch } from 'containers/HomePageContainer/hooks/use-search';
import { useUpdateWidgetMutation } from 'containers/HomePageContainer/store/server/use-update-widget-mutation';
import { useWidget } from 'containers/HomePageContainer/store/server/use-widget';
import { v4 as uuidv4 } from 'uuid';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import React, {
  Dispatch,
  FC, SetStateAction, useCallback, useEffect, useMemo, useState,
} from 'react';
import SaveIcon from '@mui/icons-material/Save';
import ShopByOccasionImage from 'assets/widgets/thumbnails/Shop_by_Occasion_Widget.png';

export interface ShopByOccasionModalContentProps {
  id?: string;
  closeModal: () => void;
  pageType: PageType;
}

export const ShopByOccasionModalContent: FC<ShopByOccasionModalContentProps> = ({
  closeModal,
  id,
  pageType,
}) => {
  const defaultInitialValues = useDefaultInitialValues();
  const { culture } = useSearch();
  const { data, isLoading } = useWidget<ShopByOccasionWidget>(id);
  const createMutation = useCreateWidgetMutation(closeModal);
  const updateMutation = useUpdateWidgetMutation(closeModal);
  const [desktopOccasions, setDesktopOccasions] = useState<DraggableOccasionCard[]>([]);
  const [mobileOccasions, setMobileOccasions] = useState<DraggableOccasionCard[]>([]);
  const [isValid, setIsValid] = useState(true);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const openAlert = useGlobalAlertStore((store) => store.openAlert);
  const [isActiveDesktopOccasions, setIsActiveDesktopOccasions] = useState(true);
  const [isActiveMobileOccasions, setIsActiveMobileOccasions] = useState(true);
  const [desktopColorPicker, setDesktopColorPicker] = useState<boolean>(false);
  const [mobileColorPicker, setMobileColorPicker] = useState<boolean>(false);
  const [desktopBackground, setDesktopBackground] = useState<string>('');
  const [mobileBackground, setMobileBackground] = useState<string>('');
  const [desktopTextLanguage, setDesktopTextLanguage] = useState('en');
  const [mobileTextLanguages, setMobileTextLanguages] = useState('en');
  const handleMobileChange = (hex: string) => {
    setMobileBackground(hex);
    setMobileColorPicker(false);
  };
  const handleDesktopChange = (hex: string) => {
    setDesktopBackground(hex);
    setDesktopColorPicker(false);
  };

  const widgetsDirty = useMemo(
    () => !areWidgetItemsEqualToInitialItems(
      desktopOccasions,
      mapItemsToInitialValues,
      data?.content?.desktop?.items,
    )
      || !areWidgetItemsEqualToInitialItems(
        mobileOccasions,
        mapItemsToInitialValues,
        data?.content?.mobile?.items,
      ),
    [data, desktopOccasions, mobileOccasions],
  );

  useEffect(() => {
    if (data) {
      const { sources } = data;
      setIsActiveDesktopOccasions(Boolean(data.content?.desktop?.available));
      setIsActiveMobileOccasions(Boolean(data.content?.mobile?.available));
      setDesktopOccasions(mapItemsToInitialValues(data.content?.desktop?.items, sources));
      setMobileOccasions(mapItemsToInitialValues(data.content?.mobile?.items, sources));
      setMobileBackground(data.content?.mobile?.bgColor);
      setDesktopBackground(data.content?.desktop?.bgColor);
    }
  }, [data]);

  const setOccasionsCallback = useCallback((prev: DraggableOccasionCard[]) => [...prev, {
    id: uuidv4(),
    name: '',
    image: '',
    link: '',
    isValid: false,
    onSubmitCallback: undefined,
    sources: ['organic'],
  }], []);

  const openFormInvalidAlert = useCallback(() => {
    openAlert({
      text: 'Highlighted fields are invalid, they should be filled with proper values',
      severity: 'error',
    });
  }, [openAlert]);

  const openOccasionsInvalidAlert = useCallback(() => {
    openAlert({
      text: 'Highlighted fields in occasions are invalid, they should be filled with proper values',
      severity: 'error',
    });
  }, [openAlert]);

  const openOccasionsEmptyAlert = useCallback(() => {
    openAlert({
      text: 'Highlighted fields in occasions are empty, please add at least 1 banner in active device categories',
      severity: 'error',
    });
  }, [openAlert]);

  const dndItemsHandleOnSubmit = useCallback(() => {
    desktopOccasions.forEach((banner) => { banner.onSubmitCallback?.(); });
    mobileOccasions.forEach((banner) => { banner.onSubmitCallback?.(); });
  }, [desktopOccasions, mobileOccasions]);

  const areSomeOccasionsInvalid = useCallback(
    () => {
      if (desktopOccasions.some((banner) => !banner.isValid)
            || mobileOccasions.some((banner) => !banner.isValid)) {
        return true;
      }
      return false;
    },
    [desktopOccasions, mobileOccasions],
  );

  const areSomeOccasionsEmpty = useCallback(
    () => {
      if (isActiveDesktopOccasions && desktopOccasions.length === 0) {
        return true;
      }
      if (isActiveMobileOccasions && mobileOccasions.length === 0) {
        return true;
      }
      return false;
    },
    [desktopOccasions, mobileOccasions, isActiveDesktopOccasions, isActiveMobileOccasions],
  );

  const isSubmitWithEmptyOccasions = useCallback((
    banners: DraggableOccasionCard[],
  ) => submitAttempted && banners.length === 0, [submitAttempted]);

  const handleActivityChange = useCallback((
    value: boolean,
    setState: Dispatch<SetStateAction<boolean>>,
  ) => {
    if (value || (isActiveDesktopOccasions && isActiveMobileOccasions)) {
      setState(value);
    } else {
      openAlert(DEVICE_ACTIVATION_SWITCH_WARNING);
    }
  }, [isActiveDesktopOccasions, isActiveMobileOccasions, openAlert]);

  const handleSubmit = (values: ShopByOccasionFormData) => {
    if (!isValid) {
      openFormInvalidAlert();
    }
    if (areSomeOccasionsInvalid()) {
      openOccasionsInvalidAlert();
      return;
    }
    if (areSomeOccasionsEmpty()) {
      openOccasionsEmptyAlert();
      return;
    }

    if (id) {
      updateMutation.mutate({
        id,
        data: mapShopByOccasionFormDataToRequest(
          values,
          pageType,
          desktopOccasions,
          mobileOccasions,
        ) as UpdateShopByOccasionData,
      });
    } else {
      createMutation.mutate(mapShopByOccasionFormDataToRequest(
        values,
        pageType,
        desktopOccasions,
        mobileOccasions,
        culture,
      ) as CreateShopByOccasionData);
    }
  };

  if (pageType === PageType.Plp) {
    defaultInitialValues.position = 1;
  }
  if (id && isLoading) {
    return <Container><Loader isLoading /></Container>;
  }

  return (
    <Container>
      <Content>
        <Formik
          initialValues={{
            ...(data ? mapWidgetDataToFormInitialValues(data)
              : defaultInitialValues),
            pageType,
          }}
          validationSchema={shopByOccasionWidgetSchema}
          onSubmit={handleSubmit}
        >
          {({
            dirty, setFieldValue, values, errors,
          }) => (
            <Form>
              <FormikErrorsListener
                onErrors={() => setIsValid(false)}
                onNoErrors={() => setIsValid(true)}
              />
              <Box display="flex">
                <Image src={ShopByOccasionImage} alt="shop by occasion widget" />
                <Box pl={3.75}>
                  <TextField name="name" label="Widget Name" fullWidth />
                  <Box display="flex">
                    <DateTimePickerField name="activeFrom" label="Valid from" sx={styles.dateTimePicker} />
                    <DateTimePickerField name="activeTo" label="Valid to" sx={styles.dateTimePicker} />
                    {pageType !== PageType.Plp && (
                      <SourceSelect name="sources" />
                    )}
                    {pageType === PageType.Plp && (
                      <PositionSelect name="position" />
                    )}
                  </Box>
                </Box>
              </Box>
              <Divider sx={styles.divider} />
              <Grid container spacing={10}>
                <Grid item xs={6}>
                  <Field name="isActiveDesktop">
                    {({ field }: FieldProps<boolean>) => (
                      <FormControlLabel
                        control={(
                          <Switch
                            checked={isActiveDesktopOccasions}
                            onClick={() => handleActivityChange(
                              !isActiveDesktopOccasions,
                              setIsActiveDesktopOccasions,
                            )}
                            {...field}
                          />
                        )}
                        label="Desktop"
                        labelPlacement="start"
                        sx={styles.availabilitySwitch}
                        {...field}
                      />
                    )}
                  </Field>
                  <LocalizedTextField
                    label="Occasion Title"
                    englishText={values.occasionTitleDesktop || ''}
                    frenchText={values.occasionFrTitleDesktop || ''}
                    textLanguage={desktopTextLanguage}
                    setTextLanguage={setDesktopTextLanguage}
                    onBlur={(event) => {
                      if (desktopTextLanguage === 'en') {
                        setFieldValue('occasionTitleDesktop', event.target.value);
                      } else {
                        setFieldValue('occasionFrTitleDesktop', event.target.value);
                      }
                    }}
                    errors={errors?.occasionTitleDesktop}
                  />
                  <Box
                    display="flex"
                    mt={3}
                    onClick={() => { setDesktopColorPicker(!desktopColorPicker); }}
                  >
                    <TextField
                      autoComplete="off"
                      name="occasionBgColorDesktop"
                      label="Occasion Background Color"
                      sx={styles.textField}
                      value={desktopBackground}
                      type="text"
                    />
                    <Box sx={styles.colorBox} style={{ backgroundColor: desktopBackground }} />
                  </Box>
                  <Box sx={styles.colorPicker}>
                    {(desktopColorPicker) && (
                      <TwitterPicker
                        color={desktopBackground}
                        onChangeComplete={({ hex }: { hex: string }) => { handleDesktopChange(hex); setFieldValue('occasionBgColorDesktop', hex); }}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6} pt={2}>
                  <Field name="isActiveMobile">
                    {({ field }: FieldProps<boolean>) => (
                      <FormControlLabel
                        control={(
                          <Switch
                            checked={isActiveMobileOccasions}
                            onClick={() => handleActivityChange(
                              !isActiveMobileOccasions,
                              setIsActiveMobileOccasions,
                            )}
                            {...field}
                          />
                        )}
                        label="Mobile"
                        labelPlacement="start"
                        sx={styles.availabilitySwitch}
                        {...field}
                      />
                    )}
                  </Field>
                  <LocalizedTextField
                    label="Occasion Title"
                    englishText={values.occasionTitleMobile || ''}
                    frenchText={values.occasionFrTitleMobile || ''}
                    textLanguage={mobileTextLanguages}
                    setTextLanguage={setMobileTextLanguages}
                    onBlur={(event) => {
                      if (mobileTextLanguages === 'en') {
                        setFieldValue('occasionTitleMobile', event.target.value);
                      } else {
                        setFieldValue('occasionFrTitleMobile', event.target.value);
                      }
                    }}
                    errors={errors?.occasionTitleMobile}
                  />
                  <Box
                    display="flex"
                    mt={3}
                    onClick={() => { setMobileColorPicker(!mobileColorPicker); }}
                  >
                    <TextField
                      autoComplete="off"
                      name="occasionBgColorMobile"
                      label="Occasion Background Color"
                      sx={styles.textField}
                      value={mobileBackground}
                      type="text"
                    />
                    <Box sx={styles.colorBox} style={{ backgroundColor: mobileBackground }} />
                  </Box>
                  <Box sx={styles.colorPicker}>
                    {(mobileColorPicker) && (
                      <TwitterPicker
                        color={mobileBackground}
                        onChangeComplete={({ hex }: { hex: string }) => { handleMobileChange(hex); setFieldValue('occasionBgColorMobile', hex); }}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
              <ActionButtons>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={closeModal}
                  startIcon={<CancelIcon />}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={createMutation.isLoading || updateMutation.isLoading}
                  disabled={data && !(dirty || widgetsDirty)}
                  variant="contained"
                  type="submit"
                  size="large"
                  startIcon={<SaveIcon />}
                  onClick={() => {
                    setSubmitAttempted(true);
                    dndItemsHandleOnSubmit();
                    if (!isValid) {
                      openFormInvalidAlert();
                    }
                  }}
                >
                  Save
                </LoadingButton>
              </ActionButtons>
            </Form>
          )}
        </Formik>
        <Grid container spacing={3}>
          <Grid item xs={6} pt={2}>
            <Typography variant="subtitle1" my={3}>
              {`${desktopOccasions.length} Occasions in desktop:`}
            </Typography>
            <DndProvider backend={HTML5Backend}>
              <DndStackContainer
                itemType={DraggableItemType.OccasionWidget}
                items={desktopOccasions}
                setItems={setDesktopOccasions}
                columnHeaders={['', '']}
                pageType={pageType}
              />
            </DndProvider>
            <Button
              onClick={() => setDesktopOccasions(setOccasionsCallback)}
              sx={styles.getAddButton(
                isActiveDesktopOccasions && isSubmitWithEmptyOccasions(desktopOccasions),
              )}
              variant="outlined"
              startIcon={<AddIcon />}
              fullWidth
            >
              Add Occasion
            </Button>
          </Grid>
          <Grid item xs={6} pt={2}>
            <Typography variant="subtitle1" my={3}>
              {`${mobileOccasions.length} Occasions in mobile:`}
            </Typography>
            <DndProvider backend={HTML5Backend}>
              <DndStackContainer
                itemType={DraggableItemType.OccasionWidget}
                items={mobileOccasions}
                setItems={setMobileOccasions}
                columnHeaders={['', '']}
                pageType={pageType}
              />
            </DndProvider>
            <Button
              onClick={() => setMobileOccasions(setOccasionsCallback)}
              sx={styles.getAddButton(
                isActiveMobileOccasions && isSubmitWithEmptyOccasions(mobileOccasions),
              )}
              variant="outlined"
              startIcon={<AddIcon />}
              fullWidth
            >
              Add Occasion
            </Button>
          </Grid>
        </Grid>
      </Content>
    </Container>
  );
};

ShopByOccasionModalContent.defaultProps = {
  id: '',
};
