import * as styles from 'common/components/GenericModal/styles';
import {
  Box,
  Grid,
  MenuItem,
} from '@mui/material';
import { DEVICE_ACTIVATION_SWITCH_WARNING } from 'containers/HomePageContainer/components/PageConfigurator/constants';
import { LocalizedTextField } from 'containers/HomePageContainer/utils/LocalizedTextField';
import { ProductCarouselFormData, Sort } from 'containers/HomePageContainer/types/product-carousel';
import { Select } from 'common/components/Select';
import { Switch } from 'common/components/Switch';
import { TextField } from 'common/components/TextField';
import { useFormikContext } from 'formik';
import { useGlobalAlertStore } from 'common/store/client/global-alert/use-global-alert.store';
import React, { FC, useState } from 'react';

interface DeviceData {
  title: string;
  key: 'desktop' | 'mobile';
}

const devicesData: DeviceData[] = [{
  title: 'Desktop',
  key: 'desktop',
}, {
  title: 'Mobile',
  key: 'mobile',
}];

interface FieldToggle {
  desktop: string;
  mobile: string;
}

const titleTextToggle: FieldToggle = {
  desktop: 'en',
  mobile: 'en',
};

const viewAllButtonToggle: FieldToggle = {
  desktop: 'en',
  mobile: 'en',
};

export const ProductsConfiguration: FC = () => {
  const {
    values, setFieldValue, errors,
  } = useFormikContext<ProductCarouselFormData>();
  const openAlert = useGlobalAlertStore((store) => store.openAlert);
  const [titleText, setTitleText] = useState<FieldToggle>(titleTextToggle);
  const [viewAllButtonText, setViewAllButtonText] = useState<FieldToggle>(viewAllButtonToggle);

  const handleTitleTextLanguageChange = (key: string, newLang: string) => {
    setTitleText((prevState) => ({
      ...prevState,
      [key]: newLang,
    }));
  };

  const handleViewAllButtonLanguageChange = (key: string, newLang: string) => {
    setViewAllButtonText((prevState) => ({
      ...prevState,
      [key]: newLang,
    }));
  };

  return (
    <Grid container spacing={10}>
      {devicesData.map(({ title: boxTitle, key }) => {
        const disabled = !values.content[key].available;

        return (
          <Grid key={key} item xs={6}>
            <Box display="flex" flexDirection="column">
              <Switch
                name={`content.${key}.available`}
                label={boxTitle}
                labelPlacement="start"
                onChange={(event) => {
                  if (
                    event.target.checked
                    || (values.content.desktop.available && values.content.mobile.available)
                  ) {
                    setFieldValue(`content.${key}.available`, event.target.checked);
                  } else {
                    openAlert(DEVICE_ACTIVATION_SWITCH_WARNING);
                  }
                }}
                sx={styles.availabilitySwitch}
              />
              <LocalizedTextField
                label="Carousel Title"
                englishText={values.content[key].title || ''}
                frenchText={values.content[key].frTitle || ''}
                textLanguage={titleText[key]}
                setTextLanguage={(newLang) => handleTitleTextLanguageChange(key, newLang)}
                onBlur={(event) => {
                  if (titleText[key] === 'fr') {
                    setFieldValue(`content.${key}.frTitle`, event.target.value);
                  } else {
                    setFieldValue(`content.${key}.title`, event.target.value);
                  }
                }}
                variant="outlined"
                errors={key === 'desktop' ? errors?.content?.desktop?.title : errors?.content?.mobile?.title}
              />
              <TextField name={`content.${key}.url`} disabled={disabled} label="Carousel URL" sx={styles.textField} />
              <LocalizedTextField
                label="Button Text"
                englishText={values.content[key].viewAllButtonText || ''}
                frenchText={values.content[key].frViewAllButtonText || ''}
                textLanguage={viewAllButtonText[key]}
                setTextLanguage={(newLang) => handleViewAllButtonLanguageChange(key, newLang)}
                onBlur={(event) => {
                  if (viewAllButtonText[key] === 'fr') {
                    setFieldValue(`content.${key}.frViewAllButtonText`, event.target.value);
                  } else {
                    setFieldValue(`content.${key}.viewAllButtonText`, event.target.value);
                  }
                }}
                variant="outlined"
                errors={key === 'desktop' ? errors?.content?.desktop?.viewAllButtonText : errors?.content?.mobile?.viewAllButtonText}
              />
              <Select
                name={`content.${key}.filterParams.sort`}
                disabled={disabled}
                label="Sort"
                sx={styles.textField}
              >
                <MenuItem value={Sort.Sequence}>Sequence</MenuItem>
                <MenuItem value={Sort.PriceLowToHigh}>Price Low to High</MenuItem>
                <MenuItem value={Sort.PriceHighToLow}>Price High to Low</MenuItem>
              </Select>
              <TextField
                type="number"
                name={`content.${key}.filterParams.limit`}
                disabled={disabled}
                label="Item Limit"
                sx={styles.textField}
              />
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
