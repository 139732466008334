import {
  ShopByCategoryFormData,
} from 'containers/HomePageContainer/types/shop-by-category';
import { useInitCommonValues } from 'utils/hooks/use-init-common-values';

export const useDefaultInitialValues = (): ShopByCategoryFormData => ({
  ...useInitCommonValues(),
  categoryTitleDesktop: '',
  categoryTitleMobile: '',
  viewAllButtonTextDesktop: '',
  viewAllButtonTextMobile: '',
  frCategoryTitleDesktop: '',
  frCategoryTitleMobile: '',
  frViewAllButtonTextDesktop: '',
  frViewAllButtonTextMobile: '',
  catalogUrlDesktop: '',
  catalogUrlMobile: '',
});
