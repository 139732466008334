import * as styles from 'common/components/GenericModal/styles';
import { Box } from '@mui/material';
import { DndItem, DraggableSkinnyCard } from 'containers/DndSkinnyContainer/types';
import { DndItemWrapper } from 'containers/DndStackContainer/components/DndItemWrapper';
import { DndSkinnyBannerCardFieldType } from 'containers/SkinnyBannerContainer/components/PageConfigurator/SkinnyBannerModalContent/DndSkinnyBannerCard/field-type.enum';
import { Form, Formik } from 'formik';
import { FormikErrorsListener } from 'common/components/FormikErrorsListener';
import { FormikItemSubmitFormInitializer } from 'containers/DndStackContainer/utils/FormikSubmitFormInitializer';
import { LocalizedButtonText } from 'common/components/RichEditer/Localized-Button-Label';
import { RichEditor } from 'containers/SkinnyBannerContainer/components/PageConfigurator/SkinnyBannerModalContent/Editor/index';
import { TextField } from 'common/components/TextField';
import { WidgetLayout } from 'common/enums/widget-layout.enum';
import { deviceItemContentSchema } from 'containers/SkinnyBannerContainer/components/PageConfigurator/SkinnyBannerModalContent/skinny-banner-widget.schema';
import { isEmptyOrUndefined } from 'utils/string/is-empty-or-undefined';
import React, {
  FC, FocusEvent, useCallback, useEffect, useState,
} from 'react';

export interface DndSkinnyBannerCardProps extends DraggableSkinnyCard {
  isDragging: boolean;
  deleteItem: (id: string) => void;
  updateItemState: (
    id: string,
    state: DndItem
  ) => void;
}

export interface SkinnyBannerCardFormData {
  editorContent: string;
  frEditorContent: string;
  url: string;
}

const defaultInitialValues: SkinnyBannerCardFormData = {
  editorContent: '',
  frEditorContent: '',
  url: '',
};

export interface HandleUpdateItemProps {
  itemId: string;
  type: DndSkinnyBannerCardFieldType;
  value: string | boolean | (() => Promise<void>) | string[];
}

export const DndSkinnyBannerCard: FC<DndSkinnyBannerCardProps> = ({
  id, editorContent, frEditorContent, url, bgColor, updateItemState: updateItem,
  isDragging, deleteItem, layout, isValid: isInitiallyValid,
  onSubmitCallback,
}) => {
  const [isValid, setIsValid] = useState(Boolean(isInitiallyValid));
  const areInitialFieldValues = isEmptyOrUndefined(editorContent)
|| isEmptyOrUndefined(frEditorContent) || isEmptyOrUndefined(url);

  const newState = {
    editorContent, frEditorContent, url, layout, isValid, onSubmitCallback,
  };

  const isCA = window.COUNTRY === 'CA';

  const handleUpdateItem = ({
    itemId, type, value,
  }: HandleUpdateItemProps): void => {
    if (!updateItem) {
      return;
    }

    if (type === DndSkinnyBannerCardFieldType.EditorContent) {
      updateItem(itemId, { ...newState, editorContent: String(value) });
      return;
    }
    if (type === DndSkinnyBannerCardFieldType.FrEditorContent) {
      updateItem(itemId, { ...newState, frEditorContent: String(value) });
      return;
    }
    if (type === DndSkinnyBannerCardFieldType.Url) {
      updateItem(itemId, { ...newState, url: String(value) });
      return;
    }
    if (type === DndSkinnyBannerCardFieldType.IsValid) {
      updateItem(itemId, { ...newState, isValid: Boolean(value) });
      return;
    }
    if (type === DndSkinnyBannerCardFieldType.SubmitFormCallback) {
      updateItem(itemId, { ...newState, onSubmitCallback: value as (() => Promise<void>) });
    }
  };

  useEffect(() => {
    if (areInitialFieldValues) {
      handleUpdateItem({ itemId: id!, type: DndSkinnyBannerCardFieldType.IsValid, value: false });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!areInitialFieldValues) {
      handleUpdateItem({ itemId: id!, type: DndSkinnyBannerCardFieldType.IsValid, value: isValid });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  const onBlur = (fieldType: DndSkinnyBannerCardFieldType) => (
    { target: { value } }: FocusEvent<HTMLInputElement>,
  ) => {
    handleUpdateItem({ itemId: id!, type: fieldType, value: String(value) });
  };

  const setOnSubmitFormForItemCallback = useCallback((callback: () => Promise<void>) => {
    setTimeout(() => {
      handleUpdateItem({
        itemId: id!, type: DndSkinnyBannerCardFieldType.SubmitFormCallback, value: callback,
      });
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DndItemWrapper
      layout={layout ?? WidgetLayout.Common}
      isDragging={isDragging}
      isActive
      deleteItem={deleteItem}
      id={id!}
    >
      <Formik
        initialValues={id
          ? {
            id, editorContent, frEditorContent, url,
          }
          : defaultInitialValues}
        validationSchema={deviceItemContentSchema}
        onSubmit={() => {}}
      >
        {({ submitForm }) => (
          <Form>
            <Box p={2}>
              <FormikItemSubmitFormInitializer
                submitForm={submitForm}
                setOnSubmitFormForItem={setOnSubmitFormForItemCallback}
              />
              <FormikErrorsListener
                onErrors={() => setIsValid(false)}
                onNoErrors={() => setIsValid(true)}
              />
              <TextField name="url" label="Page Link URL" sx={styles.textField} onBlur={onBlur(DndSkinnyBannerCardFieldType.Url)} />
              {isCA
              && (
                <LocalizedButtonText labelText="En" />
              )}
              <RichEditor
                textEditorValue={updateItem}
                id={id || ''}
                newState={newState || ''}
                editorBackgroundColor={bgColor || ''}
                contentFieldName="editorContent"
                editorPlaceholder="Skinny Banner Text"
                editorValue={editorContent || ''}
              />

              {isCA
              && (
                <>
                  <LocalizedButtonText labelText="Fr" />
                  <RichEditor
                    textEditorValue={updateItem}
                    id={id || ''}
                    newState={newState || ''}
                    editorBackgroundColor={bgColor || ''}
                    contentFieldName="frEditorContent"
                    editorPlaceholder="Skinny Banner Text"
                    editorValue={frEditorContent || ''}
                  />
                </>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </DndItemWrapper>
  );
};
