import * as Yup from 'yup';

const stringShouldNotBeEmpty = 'This field is required';
const valueMustBeString = 'The value must be a string';
const valueMustBeBoolean = 'This field must be a boolean value';
const booleanShouldBeSet = 'This field is required, must be a boolean value';
const dateShouldBeSet = 'Date should be set';
const valueMustBeDate = 'The value must be a date';
const activeToMustBeAfterActiveFrom = 'Valid to must be after Valid from';
const activeToMustBeAfterCurrentDate = 'Valid to must be future date';
const valueMustBeNumber = 'This field must be a number 0 or greater';

const conditionOptions = {
  is: true,
  then: Yup.string().required(stringShouldNotBeEmpty),
};

export const deviceItemContentSchema = Yup.object().shape({
  editorContent: Yup.string().min(1)
    .required(stringShouldNotBeEmpty),
  frEditorContent: Yup.string().min(1)
    .required(stringShouldNotBeEmpty),
  url: Yup.string()
    .typeError(valueMustBeString)
    .when('available', conditionOptions),
});

const deviceContentSchema = Yup.object({
  available: Yup.boolean()
    .typeError(valueMustBeBoolean)
    .required(booleanShouldBeSet),
  bannerBackgroundColor: Yup.string()
    .typeError(valueMustBeString)
    .when('available', conditionOptions),
  autoSlideInterval: Yup.number().min(0)
    .typeError(valueMustBeNumber)
    .required(stringShouldNotBeEmpty),
});

export const skinnyBannerWidgetSchema = Yup.object().shape({
  name: Yup.string().min(1).required()
    .typeError(valueMustBeString)
    .required(stringShouldNotBeEmpty),
  activeFrom: Yup.date()
    .typeError(valueMustBeDate)
    .required(dateShouldBeSet),
  activeTo: Yup.date()
    .typeError(valueMustBeDate)
    .when('activeFrom', (activeFrom) => Yup.date().min(
      activeFrom,
      activeToMustBeAfterActiveFrom,
    ).min(new Date(), activeToMustBeAfterCurrentDate))
    .required(dateShouldBeSet),
  content: Yup.object({
    desktop: deviceContentSchema,
    mobile: deviceContentSchema,
  }),
});
