import { BaseTextFieldProps, SxProps, TextField as TextFieldMui } from '@mui/material';
import { useField } from 'formik';
import React, { FC, FocusEvent, HTMLInputTypeAttribute } from 'react';

interface TextFieldProps {
  name: string;
  type?: HTMLInputTypeAttribute | undefined;
  disabled?: boolean;
  label?: string;
  sx?: SxProps;
  onBlur?: ({ target: { value } }: FocusEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
  variant?: BaseTextFieldProps['variant'];
  'data-test'?: string;
  value?: string;
  autoComplete?: string;
}

export const TextField: FC<TextFieldProps> = ({
  label,
  sx,
  type,
  variant,
  disabled,
  fullWidth,
  onBlur,
  'data-test': dataTest,
  value,
  autoComplete,
  ...props
}) => {
  const [field, meta] = useField<string>(props);
  if (onBlur) {
    const defaultFieldOnBlur = field.onBlur;
    field.onBlur = (evt: FocusEvent<HTMLInputElement>) => {
      defaultFieldOnBlur(evt);
      onBlur(evt);
    };
  }
  return (
    <TextFieldMui
      label={label}
      sx={sx}
      type={type}
      variant={variant}
      disabled={disabled}
      fullWidth={fullWidth}
      inputProps={{
        'data-test': dataTest,
      }}
      error={Boolean(meta.touched && meta.error)}
      helperText={meta.touched ? meta.error : ''}
      {...field}
      value={value || field.value}
      autoComplete={autoComplete}
    />
  );
};

TextField.defaultProps = {
  value: '',
  label: '',
  variant: 'outlined',
  sx: {},
  disabled: false,
  fullWidth: false,
  type: undefined,
  onBlur: undefined,
  'data-test': undefined,
  autoComplete: 'off',
};
