import { BannerFormData, CreateBannerData, UpdateBannerData } from 'containers/HomePageContainer/types/banner';
import { HomePageWidgetType } from 'containers/HomePageContainer/enum/component.enum';
import { Locale } from 'common/enums/locale';
import { PageType } from 'common/enums/page-type.enum';
import { convertDefaultTimeToDaylightSavingsTime, convertDefaultZoneToUtc } from 'utils/date';

export const mapBannerFormDataToRequest = (
  data: BannerFormData,
  pageTypeValue: PageType,
  culture?: Locale,
): CreateBannerData | UpdateBannerData => {
  const { available: desktopAvailable, ...desktopItem } = data.content.desktop;
  const { available: mobileAvailable, ...mobileItem } = data.content.mobile;

  return ({
    ...data,
    activeFrom: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeFrom)),
    activeTo: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeTo)),
    eventName: data.name,
    sources: data.sources,
    position: data.position,
    pageType: pageTypeValue,
    type: HomePageWidgetType.Banner,
    content: {
      desktop: {
        available: desktopAvailable,
        items: [{ ...desktopItem, sequence: 0 }],
      },
      mobile: {
        available: mobileAvailable,
        items: [{ ...mobileItem, sequence: 0 }],
      },
    },
    ...(culture ? { culture } : null),
  });
};
