import * as styles from 'containers/HomePageContainer/components/PageConfigurator/NotificationBannerModalContent/DndNotificationBannerCard/styles';
import { BackgroundColorConfiguration } from 'containers/HomePageContainer/components/PageConfigurator/NotificationBannerModalContent/components/BackgroundColorConfiguration';
import { Box } from '@mui/material';
import { DndItem, DraggableNotificationBannerCard } from 'containers/DndStackContainer/types';
import { DndItemWrapper } from 'containers/DndStackContainer/components/DndItemWrapper';
import { DndNotificationBannerFieldType } from 'containers/HomePageContainer/components/PageConfigurator/NotificationBannerModalContent/DndNotificationBannerCard/field-type.enum';
import { Form, Formik } from 'formik';
import { FormikErrorsListener } from 'common/components/FormikErrorsListener';
import { FormikItemSubmitFormInitializer } from 'containers/DndStackContainer/utils/FormikSubmitFormInitializer';
import { LocalizedButtonText } from 'common/components/RichEditer/Localized-Button-Label';
import { NotificationBannerConfiguration } from 'containers/HomePageContainer/components/PageConfigurator/NotificationBannerModalContent/NotificationBannerConfiguration';
import { RichEditor } from 'containers/HomePageContainer/components/PageConfigurator/NotificationBannerModalContent/Editor';
import { TextField } from 'common/components/TextField';
import { WidgetLayout } from 'common/enums/widget-layout.enum';
import { dndNotificationBannerCardSchema } from 'containers/HomePageContainer/components/PageConfigurator/NotificationBannerModalContent/DndNotificationBannerCard/dnd-banner-card.schema';
import { isEmptyOrUndefined } from 'utils/string/is-empty-or-undefined';
import React, {
  FC, FocusEvent, useCallback, useEffect, useState,
} from 'react';

export interface DndNotificationBannerCardProps extends DraggableNotificationBannerCard {
  isDragging: boolean;
  deleteItem: (id: string) => void;
  updateItemState: (
    id: string,
    state: DndItem
  ) => void;
}

export interface NotificationBannerCardFormData {
  bannerText: string;
  frBannerText: string;
  frButtonText: string;
  bannerBGColor: string;
  buttonBGColor: string;
  buttonText: string;
  buttonCTALink: string;
  isDeliveryCutoffTime: boolean;
  isPromoMessage: boolean;
  cutoffTime: number;
  promoEndTime: string;
}

const defaultInitialValues: NotificationBannerCardFormData = {
  bannerText: '',
  frBannerText: '',
  frButtonText: '',
  bannerBGColor: '',
  buttonBGColor: '',
  buttonText: '',
  buttonCTALink: '',
  isDeliveryCutoffTime: true,
  isPromoMessage: false,
  cutoffTime: 4,
  promoEndTime: '',
};

export interface HandleUpdateItemProps {
  itemId: string;
  type: DndNotificationBannerFieldType;
  value: string | boolean | (() => Promise<void>) | string[];
}

export const DndNotificationBannerCard: FC<DndNotificationBannerCardProps> = ({
  id, bannerText, bannerBGColor, buttonBGColor, buttonText, buttonCTALink, isDeliveryCutoffTime,
  isPromoMessage, cutoffTime, promoEndTime, updateItemState: updateItem,
  isDragging, deleteItem, layout, isValid: isInitiallyValid,
  onSubmitCallback, sources, frBannerText, frButtonText,
}) => {
  const [isValid, setIsValid] = useState(Boolean(isInitiallyValid));
  const areInitialFieldValues = isEmptyOrUndefined(bannerText)
    || isEmptyOrUndefined(bannerBGColor)
    || isEmptyOrUndefined(frBannerText)
    || isEmptyOrUndefined(frButtonText)
    || isEmptyOrUndefined(buttonBGColor)
    || isEmptyOrUndefined(buttonText)
    || isEmptyOrUndefined(buttonCTALink);

  const newState = {
    bannerText,
    frBannerText,
    frButtonText,
    bannerBGColor,
    buttonBGColor,
    buttonText,
    buttonCTALink,
    isDeliveryCutoffTime,
    isPromoMessage,
    cutoffTime,
    promoEndTime,
    layout,
    isValid,
    onSubmitCallback,
    sources,
  };
  const handleUpdateItem = ({
    itemId, type, value,
  }: HandleUpdateItemProps): void => {
    if (!updateItem) {
      return;
    }
    if (type === DndNotificationBannerFieldType.BannerText) {
      updateItem(itemId, { ...newState, bannerText: String(value) });
      return;
    }
    if (type === DndNotificationBannerFieldType.FrBannerText) {
      updateItem(itemId, { ...newState, frBannerText: String(value) });
      return;
    }
    if (type === DndNotificationBannerFieldType.FrButtonText) {
      updateItem(itemId, { ...newState, frButtonText: String(value) });
      return;
    }
    if (type === DndNotificationBannerFieldType.BannerBGColor) {
      updateItem(itemId, { ...newState, bannerBGColor: String(value) });
      return;
    }
    if (type === DndNotificationBannerFieldType.ButtonBGColor) {
      updateItem(itemId, { ...newState, buttonBGColor: String(value) });
      return;
    }
    if (type === DndNotificationBannerFieldType.ButtonText) {
      updateItem(itemId, { ...newState, buttonText: String(value) });
      return;
    }
    if (type === DndNotificationBannerFieldType.ButtonCTALink) {
      updateItem(itemId, { ...newState, buttonCTALink: String(value) });
      return;
    }
    if (type === DndNotificationBannerFieldType.IsDeliveryCutoffTime) {
      updateItem(itemId, { ...newState, isDeliveryCutoffTime: Boolean(value) });
      return;
    }
    if (type === DndNotificationBannerFieldType.CutoffTime) {
      updateItem(itemId, { ...newState, cutoffTime: Number(value) });
      return;
    }
    if (type === DndNotificationBannerFieldType.IsPromoMessage) {
      updateItem(itemId, { ...newState, isPromoMessage: Boolean(value) });
      return;
    }
    if (type === DndNotificationBannerFieldType.PromoEndTime) {
      updateItem(itemId, { ...newState, promoEndTime: String(value) });
      return;
    }
    if (type === DndNotificationBannerFieldType.IsValid) {
      updateItem(itemId, { ...newState, isValid: Boolean(value) });
      return;
    }
    if (type === DndNotificationBannerFieldType.SubmitFormCallback) {
      updateItem(itemId, { ...newState, onSubmitCallback: value as (() => Promise<void>) });
    }
    if (type === DndNotificationBannerFieldType.Sources) {
      updateItem(itemId, { ...newState, sources: value as string[] });
    }
  };
  const isCA = window.COUNTRY === 'CA';

  useEffect(() => {
    if (!areInitialFieldValues) {
      handleUpdateItem({
        itemId: id!,
        type: DndNotificationBannerFieldType.IsValid,
        value: isValid,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  useEffect(() => {
    if (areInitialFieldValues) {
      handleUpdateItem({ itemId: id!, type: DndNotificationBannerFieldType.IsValid, value: false });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBlur = (fieldType: DndNotificationBannerFieldType) => (
    { target: { value } }: FocusEvent<HTMLInputElement>,
  ) => {
    handleUpdateItem({ itemId: id!, type: fieldType, value: String(value) });
  };

  const setOnSubmitFormForItemCallback = useCallback((callback: () => Promise<void>) => {
    setTimeout(() => {
      handleUpdateItem({
        itemId: id!, type: DndNotificationBannerFieldType.SubmitFormCallback, value: callback,
      });
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DndItemWrapper
      layout={layout ?? WidgetLayout.Common}
      isDragging={isDragging}
      isActive
      deleteItem={deleteItem}
      id={id!}
    >
      <Formik
        initialValues={id
          ? {
            id,
            bannerText,
            frBannerText,
            frButtonText,
            bannerBGColor,
            buttonBGColor,
            buttonText,
            buttonCTALink,
            isDeliveryCutoffTime,
            isPromoMessage,
            cutoffTime,
            promoEndTime,
            isValid,
          }
          : defaultInitialValues}
        validationSchema={dndNotificationBannerCardSchema}
        onSubmit={() => {}}
      >
        {({ submitForm }) => (
          <Form>
            <Box p={2}>
              <FormikItemSubmitFormInitializer
                submitForm={submitForm}
                setOnSubmitFormForItem={setOnSubmitFormForItemCallback}
              />
              <FormikErrorsListener
                onErrors={() => setIsValid(false)}
                onNoErrors={() => setIsValid(true)}
              />
              <BackgroundColorConfiguration
                setFieldValue={updateItem}
                id={id! || ''}
                newState={newState || ''}
                contentFieldName="bannerBGColor"
                backgroundColorPlaceholder="Banner Background Color"
              />
              {isCA
                && (
                  <LocalizedButtonText labelText="En" />
                )}
              <RichEditor
                textEditorValue={updateItem}
                id={id || ''}
                newState={newState || ''}
                editorBackgroundColor={bannerBGColor || ''}
                contentFieldName="bannerText"
                editorPlaceholder="Banner Text"
                editorValue={bannerText || ''}
              />
              {isCA
                && (
                  <>
                    <LocalizedButtonText labelText="Fr" />
                    <RichEditor
                      textEditorValue={updateItem}
                      id={id || ''}
                      newState={newState || ''}
                      editorBackgroundColor={bannerBGColor || ''}
                      contentFieldName="frBannerText"
                      editorPlaceholder="Banner Text"
                      editorValue={frBannerText || ''}
                    />
                  </>
                )}

              <BackgroundColorConfiguration
                setFieldValue={updateItem}
                id={id! || ''}
                newState={newState || ''}
                contentFieldName="buttonBGColor"
                backgroundColorPlaceholder="Button Background Color"
              />
              {isCA
                && (
                  <LocalizedButtonText labelText="En" />
                )}
              <RichEditor
                textEditorValue={updateItem}
                id={id || ''}
                newState={newState || ''}
                editorBackgroundColor={buttonBGColor || ''}
                contentFieldName="buttonText"
                editorPlaceholder="Button Text"
                editorValue={buttonText || ''}
              />
              {isCA
                && (
                  <>
                    <LocalizedButtonText labelText="Fr" />
                    <RichEditor
                      textEditorValue={updateItem}
                      id={id || ''}
                      newState={newState || ''}
                      editorBackgroundColor={buttonBGColor || ''}
                      contentFieldName="frButtonText"
                      editorPlaceholder="Button Text"
                      editorValue={frButtonText || ''}
                    />
                  </>
                )}
              <TextField
                label="Button CTA Link"
                name="buttonCTALink"
                variant="standard"
                sx={styles.textField}
                fullWidth
                onBlur={onBlur(DndNotificationBannerFieldType.ButtonCTALink)}
              />
              <NotificationBannerConfiguration
                setFieldValue={updateItem}
                id={id! || ''}
                newState={newState || ''}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </DndItemWrapper>
  );
};
