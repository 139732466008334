import { FC, useEffect } from 'react';

interface FormikErrorsListenerProps {
  setOnSubmitFormForItem: (callback: () => Promise<void>) => void;
  submitForm: () => Promise<void>;
}

export const FormikItemSubmitFormInitializer: FC<FormikErrorsListenerProps> = ({
  submitForm, setOnSubmitFormForItem: setSubmitFormOnItem,
}) => {
  useEffect(() => {
    setSubmitFormOnItem(submitForm);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
