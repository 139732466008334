import { BaseTextFieldProps, SxProps, TextField } from '@mui/material';
import { useField } from 'formik';
import React, { FC, FocusEvent, HTMLInputTypeAttribute } from 'react';

interface TextFieldProps {
  name: string;
  type?: HTMLInputTypeAttribute | undefined;
  disabled?: boolean;
  placeholder?: string;
  sx?: SxProps;
  onBlur?: ({ target: { value } }: FocusEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
  variant?: BaseTextFieldProps['variant'];
  'data-test'?: string;
  value?: string;
  autoComplete?: string;
  className?: string;
  minRows?: number;
  error?: boolean;
  helperText?: string;
  label?: string;
}

export const TextArea: FC<TextFieldProps> = ({
  placeholder,
  sx,
  className,
  minRows,
  error,
  helperText,
  type,
  variant,
  label,
  disabled,
  fullWidth,
  onBlur,
  'data-test': dataTest,
  value,
  autoComplete,
  ...props
}) => {
  const [field] = useField<string>(props);
  if (onBlur) {
    const defaultFieldOnBlur = field.onBlur;
    field.onBlur = (evt: FocusEvent<HTMLInputElement>) => {
      defaultFieldOnBlur(evt);
      onBlur(evt);
    };
  }
  return (
    <TextField
      placeholder={placeholder}
      className={className}
      disabled={disabled}
      multiline
      fullWidth
      label={label}
      rows={minRows}
      error={error}
      helperText={helperText}
      {...field}
      value={value || field.value}
      autoComplete={autoComplete}
    />
  );
};

TextArea.defaultProps = {
  value: '',
  placeholder: '',
  variant: 'outlined',
  sx: {},
  className: '',
  disabled: false,
  fullWidth: false,
  type: undefined,
  onBlur: undefined,
  'data-test': undefined,
  autoComplete: 'off',
  minRows: 5,
  error: false,
  helperText: '',
  label: '',
};
