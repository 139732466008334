import {
  CreateNotificationBannerData,
  NotificationBannerFormData,
  UpdateNotificationBannerData,
} from 'containers/HomePageContainer/types/notification-banner';
import { DraggableNotificationBannerCard } from 'containers/DndStackContainer/types';
import { HomePageWidgetType } from 'containers/HomePageContainer/enum/component.enum';
import { Locale } from 'common/enums/locale';
import { convertDefaultTimeToDaylightSavingsTime, convertDefaultZoneToUtc } from 'utils/date';

const deviceItemsMapper = (notification: DraggableNotificationBannerCard, index: number) => ({
  id: notification.id!,
  sequence: index,
  bannerText: notification.bannerText!,
  frBannerText: notification.frBannerText!,
  frButtonText: notification.frButtonText!,
  bannerBGColor: notification.bannerBGColor!,
  buttonBGColor: notification.buttonBGColor!,
  buttonText: notification.buttonText!,
  buttonCTALink: notification.buttonCTALink!,
  isDeliveryCutoffTime: notification.cutoffTime! >= 0 && notification.promoEndTime! === '',
  isPromoMessage: notification.promoEndTime! !== '',
  cutoffTime: notification.cutoffTime!,
  promoEndTime: notification.promoEndTime!,
});

export const mapNotificationBannerFormDataToRequest = (
  data: NotificationBannerFormData,
  desktopNotificationBanner: DraggableNotificationBannerCard[],
  mobileNotificationBanner: DraggableNotificationBannerCard[],
  culture?: Locale,
): CreateNotificationBannerData | UpdateNotificationBannerData => ({
  activeFrom: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeFrom)),
  activeTo: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeTo)),
  sources: ['organic'],
  name: data.name,
  eventName: data.name,
  type: HomePageWidgetType.NotificationBannerWidget,
  ...(culture ? { culture } : null),
  content: {
    desktop: {
      available: data.isActiveDesktop,
      page: data.pageDesktop,
      autoSlideInterval: +data.autoSlideIntervalDesktop,
      items: desktopNotificationBanner.map(deviceItemsMapper),
    },
    mobile: {
      available: data.isActiveMobile,
      page: data.pageMobile,
      autoSlideInterval: +data.autoSlideIntervalMobile,
      items: mobileNotificationBanner.map(deviceItemsMapper),
    },
  },
});
