import { DraggableBannerCard } from 'containers/DndStackContainer/types';
import {
  HeroBanner,
  HeroBannerDeviceDataItem,
  HeroBannerFormData,
} from 'containers/HomePageContainer/types/hero-banner';
import { convertDaylightSavingsTimeToDefaultTime, convertUtcToDefaultZone } from 'utils/date';

export const mapItemsToInitialValues = (
  items: HeroBannerDeviceDataItem[] = [],
  sources: string[] = ['organic'],
): DraggableBannerCard[] => (items || []).map((item) => ({
  id: item.id,
  name: item.name,
  image: item.src,
  frImage: item.frSrc,
  link: item.url,
  altText: item.altText,
  frAltText: item.frAltText,
  headerText: item.headerText,
  frHeaderText: item.frHeaderText,
  contentText: item.contentText,
  frContentText: item.frContentText,
  smallText: item.smallText,
  frSmallText: item.frSmallText,
  buttonText: item.buttonText,
  frButtonText: item.frButtonText,
  textColor: item.textColor,
  buttonTextColor: item.buttonTextColor,
  textAlignment: item.textAlignment,
  isValid: true,
  sources,
}));

export const mapWidgetDataToFormInitialValues = (
  data: HeroBanner,
): HeroBannerFormData => ({
  name: data.name,
  activeFrom: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeFrom)),
  activeTo: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeTo)),
  isActiveDesktop: data.content.desktop.available,
  isActiveMobile: data.content.mobile.available,
  autoSlideIntervalDesktop: data.content.desktop.autoSlideInterval,
  autoSlideIntervalMobile: data.content.mobile.autoSlideInterval,
  sources: data.sources,
});
