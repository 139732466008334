import { Widget } from 'containers/HomePageContainer/types';
import { WidgetLayout } from 'common/enums/widget-layout.enum';

export function getWidgetLayout(widget: Widget) {
  if (widget.content.desktop.available && widget.content.mobile.available) {
    return WidgetLayout.Common;
  }
  if (widget.content.desktop.available && !widget.content.mobile.available) {
    return WidgetLayout.Desktop;
  }
  if (!widget.content.desktop.available && widget.content.mobile.available) {
    return WidgetLayout.Mobile;
  }
  return WidgetLayout.None;
}
