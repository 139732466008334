import * as Yup from 'yup';
import { PageType } from 'common/enums/page-type.enum';

const stringShouldNotBeEmpty = 'This field is required, must be a non empty string';
const valueMustBeString = 'The value must be a string';
const valueMustBeBoolean = 'This field must be a boolean value';
const booleanShouldBeSet = 'This field is required, must be a boolean value';
const dateShouldBeSet = 'Date should be set';
const valueMustBeDate = 'The value must be a date';
const activeToMustBeAfterActiveFrom = 'Valid to must be after Valid from';
const altTextMinimumCharacterLengthError = 'Alt text should be at least 10 characters long';
const titleTextMinimumCharacterLengthError = 'Title text should be at most 48 characters long';
// eslint-disable-next-line max-len
// const descriptionTextMinimumCharacterLengthError = 'Description text should be at most 200 characters long';
const buttonTextMinimumCharacterLengthError = 'Button text should be at most 24 characters long';
const sourceArrayShouldNotBeEmpty = 'Source must contain at least one item';
const sourceIsRequired = 'Source is required';
const positionIsRequired = 'Position is required';
const valueMustBeNumber = 'The value must be a number';

const deviceContentSchema = Yup.object().shape({
  available: Yup.boolean()
    .typeError(valueMustBeBoolean)
    .required(booleanShouldBeSet),
  hasImageToggle: Yup.boolean().typeError(valueMustBeBoolean).required(booleanShouldBeSet),
  src: Yup.string()
    .typeError(valueMustBeString)
    .when(['available'], {
      // eslint-disable-next-line max-len
      is: (available: boolean) => (available),
      then: Yup.string().required(stringShouldNotBeEmpty),
    }),
  altText: Yup.string()
    .min(10, altTextMinimumCharacterLengthError)
    .typeError(valueMustBeString)
    .when(['available'], {
      // eslint-disable-next-line max-len
      is: (available: boolean) => (available),
      then: Yup.string().required(stringShouldNotBeEmpty),
    }),
  title: Yup.string()
    .max(48, titleTextMinimumCharacterLengthError)
    .typeError(valueMustBeString),
  description: Yup.string()
    .typeError(valueMustBeString),
  buttonText: Yup.string()
    .max(24, buttonTextMinimumCharacterLengthError)
    .typeError(valueMustBeString),
  buttonLink: Yup.string()
    .typeError(valueMustBeString),
}, []);

export const videoTextWidgetSchema = Yup.object().shape({
  name: Yup.string().min(1).required()
    .typeError(valueMustBeString)
    .required(stringShouldNotBeEmpty),
  sources: Yup.array().of(Yup.string())
    .min(1, sourceArrayShouldNotBeEmpty)
    .required(sourceIsRequired),
  position: Yup.number()
    .typeError(valueMustBeNumber)
    .when('pageType', {
      is: PageType.Plp,
      then: Yup.number().required(positionIsRequired),
    }),
  pageType: Yup.string().min(1)
    .typeError(valueMustBeString),
  activeFrom: Yup.date()
    .typeError(valueMustBeDate)
    .required(dateShouldBeSet),
  activeTo: Yup.date()
    .typeError(valueMustBeDate)
    .when('activeFrom', (activeFrom) => Yup.date().min(
      activeFrom,
      activeToMustBeAfterActiveFrom,
    ))
    .required(dateShouldBeSet),
  content: Yup.object({
    desktop: deviceContentSchema,
    mobile: deviceContentSchema,
  }),
});
