import {
  Box,
  Checkbox,
  Chip,
  FormHelperText,
  ListItemText,
  OutlinedInput,
  SxProps,
} from '@mui/material';
import { useField } from 'formik';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import React, { FC } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface DateTimePickerFieldProps {
  name: string;
  label?: string;
  sx?: SxProps;
}

const pageOptions = [
  { value: 'Home Page', label: 'Home Page' },
  { value: 'Product Listing Page', label: 'Product Listing Page' },
];

export const PageSelect: FC<DateTimePickerFieldProps> = ({
  label, sx, ...props
}) => {
  const [field, meta, helpers] = useField<string[]>(props);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];
    helpers.setValue(value);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
  };

  const getSelectedOptionLabel = (selectedOptionValue: string): string => {
    const option = pageOptions.find((pageOption) => pageOption.value === selectedOptionValue);
    if (!option) { throw new Error(`Label not found for value: ${selectedOptionValue}`); }
    return option.label;
  };
  return (
    <Box>
      <FormControl sx={{ ml: 2, minWidth: '150px' }} error={!!meta.error}>
        <InputLabel>Page(s)</InputLabel>
        <Select
          multiple
          {...field}
          name="Pages"
          label="Page(s)"
          onChange={handleChange}
          onBlur={handleBlur}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selectedOptions) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selectedOptions.map((selectedOption) => (
                <Chip key={selectedOption} label={getSelectedOptionLabel(selectedOption)} />))}
            </Box>
          )}
        >
          {pageOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={field.value.includes(option.value)} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {meta.touched && meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
    </Box>
  );
};

PageSelect.defaultProps = {
  label: '',
  sx: {},
};
