import { AppRoutes } from 'common/enums/app-routes.enum';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUnauthorizedRequestStore } from 'containers/AppContainer/store/client/unauthorized-request/use-unauthorized-request.store';

export const useNotAuthorizedRedirect = (): void => {
  const isUnauthorized = useUnauthorizedRequestStore((store) => store.isUnauthorized);
  const navigate = useNavigate();

  useEffect(() => {
    if (isUnauthorized) {
      navigate(AppRoutes.NotAuthorized);
    }
  }, [isUnauthorized, navigate]);
};
