import * as styles from 'common/components/GenericModal/styles';
import {
  ActionButtons,
  Container,
  Content,
  Image,
} from 'common/components/GenericModal/styles';
import {
  Box,
  Button, Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import {
  CreateShopByCategoryData,
  ShopByCategoryFormData,
  ShopByCategoryWidget, UpdateShopByCategoryData,
} from 'containers/HomePageContainer/types/shop-by-category';
import { DEVICE_ACTIVATION_SWITCH_WARNING } from 'containers/HomePageContainer/components/PageConfigurator/constants';
import { DateTimePickerField } from 'common/components/DateTimePickerField';
import { DndProvider } from 'react-dnd';
import { DndStackContainer } from 'containers/DndStackContainer/DndStackContainer';
import { DraggableCategoryCard } from 'containers/DndStackContainer/types';
import { DraggableItemType } from 'containers/DndStackContainer/enums/draggable-item-type.enum';
import {
  Field, FieldProps, Form, Formik,
} from 'formik';
import { FormikErrorsListener } from 'common/components/FormikErrorsListener';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Loader } from 'common/components/Loader';
import { LoadingButton } from '@mui/lab';
import { LocalizedTextField } from 'containers/HomePageContainer/utils/LocalizedTextField';
import { PageType } from 'common/enums/page-type.enum';
import { SourceSelect } from 'common/components/SourceSelect';
import { TextField } from 'common/components/TextField/index';
import { areWidgetItemsEqualToInitialItems } from 'containers/HomePageContainer/utils/are-widget-items-equal-to-initial-items';
import { mapItemsToInitialValues, mapWidgetDataToFormInitialValues } from 'containers/HomePageContainer/components/PageConfigurator/ShopByCategoryModalContent/mappers/map-widget-data-to-form-initial-values';
import { mapShopByCategoryFormDataToRequest } from 'containers/HomePageContainer/components/PageConfigurator/ShopByCategoryModalContent/mappers/map-shop-by-category-form-data-to-request';
import { shopByCategoryWidgetSchema } from 'containers/HomePageContainer/components/PageConfigurator/ShopByCategoryModalContent/shop-by-category-widget.schema';
import { useCreateWidgetMutation } from 'containers/HomePageContainer/store/server/use-create-widget-mutation';
import {
  useDefaultInitialValues,
} from 'containers/HomePageContainer/components/PageConfigurator/ShopByCategoryModalContent/hooks/use-default-initial-values';
import { useGlobalAlertStore } from 'common/store/client/global-alert/use-global-alert.store';
import { useSearch } from 'containers/HomePageContainer/hooks/use-search';
import { useUpdateWidgetMutation } from 'containers/HomePageContainer/store/server/use-update-widget-mutation';
import { useWidget } from 'containers/HomePageContainer/store/server/use-widget';
import { v4 as uuidv4 } from 'uuid';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import React, {
  Dispatch,
  FC, SetStateAction, useCallback, useEffect, useMemo, useState,
} from 'react';
import SaveIcon from '@mui/icons-material/Save';
import ShopByCategoryImage from 'assets/widgets/thumbnails/Shop_by_Category_Widget.png';

export interface ShopByCategoryModalContentProps {
  id?: string;
  closeModal: () => void;
  pageType: PageType;
}

export const ShopByCategoryModalContent: FC<ShopByCategoryModalContentProps> = ({
  closeModal,
  id,
  pageType,
}) => {
  const defaultInitialValues = useDefaultInitialValues();
  const { culture } = useSearch();
  const { data, isLoading } = useWidget<ShopByCategoryWidget>(id);
  const createMutation = useCreateWidgetMutation(closeModal);
  const updateMutation = useUpdateWidgetMutation(closeModal);
  const [desktopCategories, setDesktopCategories] = useState<DraggableCategoryCard[]>([]);
  const [mobileCategories, setMobileCategories] = useState<DraggableCategoryCard[]>([]);
  const [isValid, setIsValid] = useState(true);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const openAlert = useGlobalAlertStore((store) => store.openAlert);
  const [isActiveDesktopCategories, setIsActiveDesktopCategories] = useState(true);
  const [isActiveMobileCategories, setIsActiveMobileCategories] = useState(true);
  const [desktopTitleLanguage, setDesktopTitleLanguage] = useState('en');
  const [mobileTitleLanguage, setMobileTitleLanguage] = useState('en');
  const [desktopButtonLanguage, setDesktopButtonLanguage] = useState('en');
  const [mobileButtonLanguage, setMobileButtonLanguage] = useState('en');

  const widgetsDirty = useMemo(
    () => !areWidgetItemsEqualToInitialItems(
      desktopCategories,
      mapItemsToInitialValues,
      data?.content?.desktop?.items,
    )
      || !areWidgetItemsEqualToInitialItems(
        mobileCategories,
        mapItemsToInitialValues,
        data?.content?.mobile?.items,
      ),
    [data, desktopCategories, mobileCategories],
  );

  useEffect(() => {
    if (data) {
      const { sources } = data;
      setIsActiveDesktopCategories(Boolean(data.content?.desktop?.available));
      setIsActiveMobileCategories(Boolean(data.content?.mobile?.available));
      setDesktopCategories(mapItemsToInitialValues(data.content?.desktop?.items, sources));
      setMobileCategories(mapItemsToInitialValues(data.content?.mobile?.items, sources));
    }
  }, [data]);

  const setCategoriesCallback = useCallback((prev: DraggableCategoryCard[]) => [...prev, {
    id: uuidv4(),
    name: '',
    image: '',
    link: '',
    isValid: false,
    onSubmitCallback: undefined,
    sources: ['organic'],
  }], []);

  const openFormInvalidAlert = useCallback(() => {
    openAlert({
      text: 'Highlighted fields are invalid, they should be filled with proper values',
      severity: 'error',
    });
  }, [openAlert]);

  const openCategoriesInvalidAlert = useCallback(() => {
    openAlert({
      text: 'Highlighted fields in categories are invalid, they should be filled with proper values',
      severity: 'error',
    });
  }, [openAlert]);

  const openCategoriesEmptyAlert = useCallback(() => {
    openAlert({
      text: 'Highlighted fields in categories are empty, please add at least 1 banner in active device categories',
      severity: 'error',
    });
  }, [openAlert]);

  const dndItemsHandleOnSubmit = useCallback(() => {
    desktopCategories.forEach((banner) => { banner.onSubmitCallback?.(); });
    mobileCategories.forEach((banner) => { banner.onSubmitCallback?.(); });
  }, [desktopCategories, mobileCategories]);

  const areSomeCategoriesInvalid = useCallback(
    () => {
      if (desktopCategories.some((banner) => !banner.isValid)
            || mobileCategories.some((banner) => !banner.isValid)) {
        return true;
      }
      return false;
    },
    [desktopCategories, mobileCategories],
  );

  const areSomeCategoriesEmpty = useCallback(
    () => {
      if (isActiveDesktopCategories && desktopCategories.length === 0) {
        return true;
      }
      if (isActiveMobileCategories && mobileCategories.length === 0) {
        return true;
      }
      return false;
    },
    [desktopCategories, mobileCategories, isActiveDesktopCategories, isActiveMobileCategories],
  );

  const isSubmitWithEmptyCategories = useCallback((
    banners: DraggableCategoryCard[],
  ) => submitAttempted && banners.length === 0, [submitAttempted]);

  const handleActivityChange = useCallback((
    value: boolean,
    setState: Dispatch<SetStateAction<boolean>>,
  ) => {
    if (value || (isActiveDesktopCategories && isActiveMobileCategories)) {
      setState(value);
    } else {
      openAlert(DEVICE_ACTIVATION_SWITCH_WARNING);
    }
  }, [isActiveDesktopCategories, isActiveMobileCategories, openAlert]);

  const handleSubmit = (values: ShopByCategoryFormData) => {
    if (!isValid) {
      openFormInvalidAlert();
    }
    if (areSomeCategoriesInvalid()) {
      openCategoriesInvalidAlert();
      return;
    }
    if (areSomeCategoriesEmpty()) {
      openCategoriesEmptyAlert();
      return;
    }

    if (id) {
      updateMutation.mutate({
        id,
        data: mapShopByCategoryFormDataToRequest(
          values,
          desktopCategories,
          mobileCategories,
          culture,
        ) as UpdateShopByCategoryData,
      });
    } else {
      createMutation.mutate(mapShopByCategoryFormDataToRequest(
        values,
        desktopCategories,
        mobileCategories,
        culture,
      ) as CreateShopByCategoryData);
    }
  };

  if (id && isLoading) {
    return <Container><Loader isLoading /></Container>;
  }

  return (
    <Container>
      <Content>
        <Formik
          initialValues={data ? mapWidgetDataToFormInitialValues(data) : defaultInitialValues}
          validationSchema={shopByCategoryWidgetSchema}
          onSubmit={handleSubmit}
        >
          {({
            dirty, setFieldValue, values, errors,
          }) => (
            <Form>
              <FormikErrorsListener
                onErrors={() => setIsValid(false)}
                onNoErrors={() => setIsValid(true)}
              />
              <Box display="flex">
                <Image src={ShopByCategoryImage} alt="shop by category widget" />
                <Box pl={3.75}>
                  <TextField name="name" label="Widget Name" fullWidth />
                  <Box display="flex">
                    <DateTimePickerField name="activeFrom" label="Valid from" sx={styles.dateTimePicker} />
                    <DateTimePickerField name="activeTo" label="Valid to" sx={styles.dateTimePicker} />
                    {pageType !== PageType.Plp && (
                      <SourceSelect name="sources" />
                    )}
                  </Box>
                </Box>
              </Box>
              <Divider sx={styles.divider} />
              <Grid container spacing={10}>
                <Grid item xs={6} pt={2}>
                  <Field name="isActiveDesktop">
                    {({ field }: FieldProps<boolean>) => (
                      <FormControlLabel
                        control={(
                          <Switch
                            checked={isActiveDesktopCategories}
                            onClick={() => handleActivityChange(
                              !isActiveDesktopCategories,
                              setIsActiveDesktopCategories,
                            )}
                            {...field}
                          />
                        )}
                        label="Desktop"
                        labelPlacement="start"
                        sx={styles.availabilitySwitch}
                        {...field}
                      />
                    )}
                  </Field>
                  <LocalizedTextField
                    label="Category Title"
                    englishText={values.categoryTitleDesktop || ''}
                    frenchText={values.frCategoryTitleDesktop || ''}
                    textLanguage={desktopTitleLanguage}
                    setTextLanguage={setDesktopTitleLanguage}
                    onBlur={(event) => {
                      if (desktopTitleLanguage === 'en') {
                        setFieldValue('categoryTitleDesktop', event.target.value);
                      } else {
                        setFieldValue('frCategoryTitleDesktop', event.target.value);
                      }
                    }}
                    errors={errors?.categoryTitleDesktop}
                  />
                  <LocalizedTextField
                    label="Button Text"
                    englishText={values.viewAllButtonTextDesktop || ''}
                    frenchText={values.frViewAllButtonTextDesktop || ''}
                    textLanguage={desktopButtonLanguage}
                    setTextLanguage={setDesktopButtonLanguage}
                    onBlur={(event) => {
                      if (desktopButtonLanguage === 'en') {
                        setFieldValue('viewAllButtonTextDesktop', event.target.value);
                      } else {
                        setFieldValue('frViewAllButtonTextDesktop', event.target.value);
                      }
                    }}
                    errors={errors?.viewAllButtonTextDesktop}
                  />
                  <TextField
                    label="Catalog URL"
                    name="catalogUrlDesktop"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field name="isActiveMobile">
                    {({ field }: FieldProps<boolean>) => (
                      <FormControlLabel
                        control={(
                          <Switch
                            checked={isActiveMobileCategories}
                            onClick={() => handleActivityChange(
                              !isActiveMobileCategories,
                              setIsActiveMobileCategories,
                            )}
                            {...field}
                          />
                        )}
                        label="Mobile"
                        labelPlacement="start"
                        sx={styles.availabilitySwitch}
                        {...field}
                      />
                    )}
                  </Field>
                  <LocalizedTextField
                    label="Category Title"
                    englishText={values.categoryTitleMobile || ''}
                    frenchText={values.frCategoryTitleMobile || ''}
                    textLanguage={mobileTitleLanguage}
                    setTextLanguage={setMobileTitleLanguage}
                    onBlur={(event) => {
                      if (mobileTitleLanguage === 'en') {
                        setFieldValue('categoryTitleMobile', event.target.value);
                      } else {
                        setFieldValue('frCategoryTitleMobile', event.target.value);
                      }
                    }}
                    errors={errors?.categoryTitleMobile}
                  />
                  <LocalizedTextField
                    label="Button Text"
                    englishText={values.viewAllButtonTextMobile || ''}
                    frenchText={values.frViewAllButtonTextMobile || ''}
                    textLanguage={mobileButtonLanguage}
                    setTextLanguage={setMobileButtonLanguage}
                    onBlur={(event) => {
                      if (mobileButtonLanguage === 'en') {
                        setFieldValue('viewAllButtonTextMobile', event.target.value);
                      } else {
                        setFieldValue('frViewAllButtonTextMobile', event.target.value);
                      }
                    }}
                    errors={errors?.viewAllButtonTextMobile}
                  />
                  <TextField
                    label="Catalog URL"
                    name="catalogUrlMobile"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <ActionButtons>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={closeModal}
                  startIcon={<CancelIcon />}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={createMutation.isLoading || updateMutation.isLoading}
                  disabled={data && !(dirty || widgetsDirty)}
                  variant="contained"
                  type="submit"
                  size="large"
                  startIcon={<SaveIcon />}
                  onClick={() => {
                    setSubmitAttempted(true);
                    dndItemsHandleOnSubmit();
                    if (!isValid) {
                      openFormInvalidAlert();
                    }
                  }}
                >
                  Save
                </LoadingButton>
              </ActionButtons>
            </Form>
          )}
        </Formik>
        <Grid container spacing={10}>
          <Grid item xs={6} pt={2}>
            <Typography variant="subtitle1" my={3}>
              {`${desktopCategories.length} Categories in desktop:`}
            </Typography>
            <DndProvider backend={HTML5Backend}>
              <DndStackContainer
                itemType={DraggableItemType.CategoryWidget}
                items={desktopCategories}
                setItems={setDesktopCategories}
                columnHeaders={['', '']}
                pageType={pageType}
              />
            </DndProvider>
            <Button
              onClick={() => setDesktopCategories(setCategoriesCallback)}
              sx={styles.getAddButton(
                isActiveDesktopCategories && isSubmitWithEmptyCategories(desktopCategories),
              )}
              variant="outlined"
              startIcon={<AddIcon />}
              fullWidth
            >
              Add Category
            </Button>
          </Grid>
          <Grid item xs={6} pt={2}>
            <Typography variant="subtitle1" my={3}>
              {`${mobileCategories.length} Categories in mobile:`}
            </Typography>
            <DndProvider backend={HTML5Backend}>
              <DndStackContainer
                itemType={DraggableItemType.CategoryWidget}
                items={mobileCategories}
                setItems={setMobileCategories}
                columnHeaders={['', '']}
                pageType={pageType}
              />
            </DndProvider>
            <Button
              onClick={() => setMobileCategories(setCategoriesCallback)}
              sx={styles.getAddButton(
                isActiveMobileCategories && isSubmitWithEmptyCategories(mobileCategories),
              )}
              variant="outlined"
              startIcon={<AddIcon />}
              fullWidth
            >
              Add Category
            </Button>
          </Grid>
        </Grid>
      </Content>
    </Container>
  );
};

ShopByCategoryModalContent.defaultProps = {
  id: '',
};
