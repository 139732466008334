import * as styles from 'containers/HomePageContainer/components/PageConfigurator/NotificationBannerModalContent/DndNotificationBannerCard/styles';
import { Box } from '@mui/material';
import { DndItem, DraggableNotificationBannerCard } from 'containers/DndStackContainer/types';
import { TextField } from 'common/components/TextField';
import { TwitterPicker } from 'react-color';
import React, { FC, useState } from 'react';

interface BackgroundColorConfigurationProps {
  setFieldValue: (id: string, state: DndItem) => void;
  id: string;
  newState: DraggableNotificationBannerCard;
  contentFieldName: string;
  backgroundColorPlaceholder: string;
}

export const BackgroundColorConfiguration: FC<BackgroundColorConfigurationProps> = (
  {
    newState, setFieldValue, id, contentFieldName, backgroundColorPlaceholder,
  },
) => {
  const [bannerTextColorPicker, setBannerTextColorPicker] = useState<boolean>(false);
  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        id="BannerBackgroundColorContainer"
        onClick={() => { setBannerTextColorPicker(!bannerTextColorPicker); }}
      >
        <TextField
          label={backgroundColorPlaceholder}
          name={contentFieldName}
          variant="standard"
          sx={styles.textField}
          value={contentFieldName === 'bannerBGColor' ? newState.bannerBGColor : newState.buttonBGColor}
          fullWidth
          type="text"
        />
        <Box sx={styles.colorBox} style={{ backgroundColor: contentFieldName === 'bannerBGColor' ? newState.bannerBGColor : newState.buttonBGColor }} />
      </Box>
      <Box sx={styles.colorPicker}>
        {bannerTextColorPicker && (
          <Box>
            {contentFieldName === 'bannerBGColor'
              ? (
                <TwitterPicker
                  color={newState.bannerBGColor}
                  onChangeComplete={({ hex }: { hex: string }) => {
                    setFieldValue(id, { ...newState, bannerBGColor: String(hex) });
                    setBannerTextColorPicker(false);
                  }}
                />
              )
              : (
                <TwitterPicker
                  color={newState.buttonBGColor}
                  onChangeComplete={({ hex }: { hex: string }) => {
                    setFieldValue(id, { ...newState, buttonBGColor: String(hex) });
                    setBannerTextColorPicker(false);
                  }}
                />
              )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
