import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';

export const useHandleAuthRedirect = (): void => {
  const { instance } = useMsal();

  useEffect(() => {
    if (!window.DISABLE_AUTH_GUARD) {
      instance.handleRedirectPromise().then((authenticationResult) => {
        if (authenticationResult !== null) {
          instance.setActiveAccount(authenticationResult.account);
        }

        return authenticationResult;
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
