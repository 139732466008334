import * as styles from 'containers/HomePageContainer/components/PageConfigurator/CreateNewWidgetModalContent/styles';
import {
  Box, Card, CardActionArea,
  Grid,
  Typography,
} from '@mui/material';
import { Container } from 'common/components/GenericModal/styles';
import { PageType } from 'common/enums/page-type.enum';
import { camelCaseToTitleCase } from 'utils/string/title-case';
import { mappedWidgetsProperties } from 'containers/HomePageContainer/components/PageConfigurator/CreateNewWidgetModalContent/mapped-widgets-properties';
import { useModal } from 'common/components/GenericModal/store/client/use-modal.store';
import React, { FC } from 'react';

export interface Props {
  pageType: PageType;
}

export const CreateNewWidgetModalContent: FC<Props> = ({ pageType }) => {
  const openModal = useModal((store) => store.openModal);

  return (
    <Container>
      <Grid container rowSpacing={2} columnSpacing={2} position="relative">
        {Object.values(mappedWidgetsProperties).map((actionButton) => (
          <Grid
            key={`${String(actionButton.title)}`}
            item
            xs={4}
            lg={3}
            onClick={() => openModal({
              type: actionButton.modalContentType,
              title: camelCaseToTitleCase(String(actionButton.modalContentType)),
              pageType,
            })}
          >
            <CardActionArea>
              <Card sx={styles.item}>
                <Box
                  component="img"
                  sx={{ width: 1 }}
                  alt={`Create ${actionButton.title}`}
                  src={actionButton.img}
                />
                <Typography pt={1.5} pb={2} textAlign="center">
                  {actionButton.title}
                </Typography>
              </Card>
            </CardActionArea>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
