import * as styles from 'common/components/GenericModal/styles';
import { TextField, styled } from '@mui/material';
import React, { FocusEventHandler, useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

interface LocalizedTextFieldProps {
  label: string;
  englishText: string;
  frenchText: string;
  textLanguage: string;
  setTextLanguage: (lang: string) => void;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  variant?: string;
  disabled?: boolean;
  errors?: string;
}

const LowercaseToggleButton = styled(ToggleButton)({
  textTransform: 'none',
});

const LocalizedTextField: React.FC<LocalizedTextFieldProps> = ({
  label, englishText, frenchText, textLanguage, setTextLanguage, onBlur, variant = 'standard', disabled, errors,
}) => {
  const [localEnglishText, setLocalEnglishText] = useState(englishText);
  const [localFrenchText, setLocalFrenchText] = useState(frenchText);
  const isCA = window.COUNTRY === 'CA';

  return (
    <TextField
      label={label}
      name={textLanguage === 'en' ? 'englishText' : 'frenchText'}
      value={textLanguage === 'en' ? localEnglishText : localFrenchText}
      variant={variant as 'standard' | 'filled' | 'outlined' | undefined}
      sx={styles.textField}
      fullWidth
      onBlur={onBlur}
      InputLabelProps={{ shrink: true }}
      disabled={disabled}
      error={errors !== undefined}
      helperText={errors}
      InputProps={{
        readOnly: false,
        endAdornment: (
          isCA && (
            <ToggleButtonGroup
              value={textLanguage}
              exclusive
              onChange={(event, newLang: string | null) => {
                if (newLang !== null) { setTextLanguage(newLang); }
              }}
              aria-label="text language"
            >
              <LowercaseToggleButton value="en" aria-label="english language">
                En
              </LowercaseToggleButton>
              <LowercaseToggleButton value="fr" aria-label="french language">
                Fr
              </LowercaseToggleButton>
            </ToggleButtonGroup>
          )
        ),
      }}
      onChange={(event) => {
        if (textLanguage === 'en') {
          setLocalEnglishText(event.target.value);
        } else {
          setLocalFrenchText(event.target.value);
        }
      }}
    />
  );
};

LocalizedTextField.defaultProps = {
  variant: undefined,
  disabled: false,
  onBlur: undefined,
  errors: undefined,
};

export { LocalizedTextField };
