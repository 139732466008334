import {
  CreateShopByCategoryData,
  ShopByCategoryFormData,
  UpdateShopByCategoryData,
} from 'containers/HomePageContainer/types/shop-by-category';
import { DraggableCategoryCard } from 'containers/DndStackContainer/types';
import { HomePageWidgetType } from 'containers/HomePageContainer/enum/component.enum';
import { Locale } from 'common/enums/locale';
import { convertDefaultTimeToDaylightSavingsTime, convertDefaultZoneToUtc } from 'utils/date';

const deviceItemsMapper = (category: DraggableCategoryCard, index: number) => ({
  id: category.id!,
  sequence: index,
  name: category.name!,
  frName: category.frName!,
  src: category.image!,
  frSrc: category.frImage!,
  fallbackSrc: category.image!,
  url: category.link!,
});

export const mapShopByCategoryFormDataToRequest = (
  data: ShopByCategoryFormData,
  desktopCategories: DraggableCategoryCard[],
  mobileCategories: DraggableCategoryCard[],
  culture?: Locale,
): CreateShopByCategoryData | UpdateShopByCategoryData => ({
  activeFrom: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeFrom)),
  activeTo: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeTo)),
  name: data.name,
  sources: data.sources,
  eventName: data.name,
  type: HomePageWidgetType.GridWidget,
  ...(culture ? { culture } : null),
  content: {
    desktop: {
      available: data.isActiveDesktop,
      title: data.categoryTitleDesktop,
      frTitle: data.frCategoryTitleDesktop,
      viewAllButtonText: data.viewAllButtonTextDesktop,
      frViewAllButtonText: data.frViewAllButtonTextDesktop,
      url: data.catalogUrlDesktop,
      items: desktopCategories.map(deviceItemsMapper),
    },
    mobile: {
      available: data.isActiveMobile,
      title: data.categoryTitleMobile,
      frTitle: data.frCategoryTitleMobile,
      viewAllButtonText: data.viewAllButtonTextMobile,
      frViewAllButtonText: data.frViewAllButtonTextMobile,
      url: data.catalogUrlMobile,
      items: mobileCategories.map(deviceItemsMapper),
    },
  },
});
