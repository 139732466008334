import * as Yup from 'yup';

const stringShouldNotBeEmpty = 'This field is required, must be a non empty string';
const valueMustBeString = 'The value must be a string';
const valueMustBeBoolean = 'This field must be a boolean value';
const booleanShouldBeSet = 'This field is required, must be a boolean value';

export const dndNotificationBannerCardSchema = Yup.object().shape({
  bannerText: Yup.string().min(1).required()
    .typeError(valueMustBeString)
    .required(stringShouldNotBeEmpty),
  buttonText: Yup.string().min(1)
    .typeError(valueMustBeString)
    .required(stringShouldNotBeEmpty),
  frBannerText: Yup.string().min(1).required()
    .typeError(valueMustBeString)
    .required(stringShouldNotBeEmpty),
  frButtonText: Yup.string().min(1)
    .typeError(valueMustBeString)
    .required(stringShouldNotBeEmpty),
  buttonCTALink: Yup.string().required()
    .typeError(valueMustBeString)
    .required(stringShouldNotBeEmpty),
  isDeliveryCutoffTime: Yup.boolean().typeError(valueMustBeBoolean).required(booleanShouldBeSet),
  isPromoMessage: Yup.boolean().typeError(valueMustBeBoolean).required(booleanShouldBeSet),
  cutoffTime: Yup.number().required()
    .typeError(valueMustBeString)
    .required(stringShouldNotBeEmpty),
  promoEndTime: Yup.string().required()
    .typeError(valueMustBeString)
    .required(stringShouldNotBeEmpty),
});
