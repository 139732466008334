import {
  Box, IconButton, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { FC } from 'react';

export interface DialogHeaderProps {
  title: string;
  onClose: () => void;
}

export const DialogHeader: FC<DialogHeaderProps> = ({ onClose, title }) => (
  <Box sx={{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: 4,
  }}
  >
    <Typography variant="h5">{title}</Typography>
    <IconButton
      edge="end"
      onClick={onClose}
      aria-label="close"
    >
      <CloseIcon />
    </IconButton>
  </Box>
);
