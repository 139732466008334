export enum DndBannerCardFieldType {
  Name = 'Name',
  Image = 'Image',
  Link = 'Link',
  IsValid = 'IsValid',
  AltText = 'AltText',
  HeaderText = 'HeaderText',
  ContentText = 'ContentText',
  SmallText = 'SmallText',
  ButtonText = 'ButtonText',
  FrAltText = 'FrAltText',
  FrHeaderText = 'FrHeaderText',
  FrContentText = 'FrContentText',
  FrSmallText = 'FrSmallText',
  FrButtonText = 'FrButtonText',
  FrImage = 'FrImage',
  TextColor = 'TextColor',
  ButtonTextColor = 'ButtonTextColor',
  TextAlignment = 'TextAlignment',
  SubmitFormCallback = 'SubmitFormCallback',
  Sources = 'Sources',
}
