import * as styles from 'common/components/GenericModal/styles';
import { Box } from '@mui/material';
import { DndCategoryCardFieldType } from 'containers/HomePageContainer/components/PageConfigurator/ShopByCategoryModalContent/DndCategoryCard/field-type.enum';
import { DndItem, DraggableOccasionCard as DraggableCategoryCard } from 'containers/DndStackContainer/types';
import { DndItemWrapper } from 'containers/DndStackContainer/components/DndItemWrapper';
import { Form, Formik } from 'formik';
import { FormikErrorsListener } from 'common/components/FormikErrorsListener';
import { FormikItemSubmitFormInitializer } from 'containers/DndStackContainer/utils/FormikSubmitFormInitializer';
import { LocalizedTextField } from 'containers/HomePageContainer/utils/LocalizedTextField';
import { TextField } from 'common/components/TextField';
import { WidgetLayout } from 'common/enums/widget-layout.enum';
import { dndOccasionCardSchema as dndCategoryCardSchema } from 'containers/HomePageContainer/components/PageConfigurator/ShopByOccasionModalContent/DndOccasionCard/dnd-banner-card.schema';
import { isEmptyOrUndefined } from 'utils/string/is-empty-or-undefined';
import React, {
  FC, FocusEvent, useCallback, useEffect, useState,
} from 'react';

export interface DndCategoryCardProps extends DraggableCategoryCard {
  isDragging: boolean;
  deleteItem: (id: string) => void;
  updateItemState: (
    id: string,
    state: DndItem
  ) => void;
}

export interface CategoryCardFormData {
  name: string;
  frName?: string;
  image: string;
  frImage?: string;
  link: string;
}

const defaultInitialValues: CategoryCardFormData = {
  name: '',
  frName: '',
  image: '',
  frImage: '',
  link: '',
};

export interface HandleUpdateItemProps {
  itemId: string;
  type: DndCategoryCardFieldType;
  value: string | boolean | (() => Promise<void>) | string[];
}

export const DndCategoryCard: FC<DndCategoryCardProps> = ({
  id, name, frName, image, frImage, link, updateItemState: updateItem,
  isDragging, deleteItem, layout, isValid: isInitiallyValid,
  onSubmitCallback, sources,
}) => {
  const [nameTextLanguage, setNameTextLanguage] = useState('en');
  const [imageTextLanguage, setImageTextLanguage] = useState('en');
  const [isValid, setIsValid] = useState(Boolean(isInitiallyValid));
  const areInitialFieldValues = isEmptyOrUndefined(name)
    || isEmptyOrUndefined(frName)
    || isEmptyOrUndefined(frImage)
    || isEmptyOrUndefined(image)
    || isEmptyOrUndefined(link);

  const handleUpdateItem = ({
    itemId, type, value,
  }: HandleUpdateItemProps): void => {
    if (!updateItem) {
      return;
    }
    const newState = {
      name, frName, image, frImage, link, layout, isValid, onSubmitCallback, sources,
    };
    if (type === DndCategoryCardFieldType.Name) {
      updateItem(itemId, { ...newState, name: String(value) });
      return;
    }
    if (type === DndCategoryCardFieldType.FrName) {
      updateItem(itemId, { ...newState, frName: String(value) });
      return;
    }
    if (type === DndCategoryCardFieldType.Image) {
      updateItem(itemId, { ...newState, image: String(value) });
      return;
    }
    if (type === DndCategoryCardFieldType.FrImage) {
      updateItem(itemId, { ...newState, frImage: String(value) });
      return;
    }
    if (type === DndCategoryCardFieldType.IsValid) {
      updateItem(itemId, { ...newState, isValid: Boolean(value) });
      return;
    }
    if (type === DndCategoryCardFieldType.SubmitFormCallback) {
      updateItem(itemId, { ...newState, onSubmitCallback: value as (() => Promise<void>) });
    }
    if (type === DndCategoryCardFieldType.Link) {
      updateItem(itemId, { ...newState, link: String(value) });
    }
    if (type === DndCategoryCardFieldType.Sources) {
      updateItem(itemId, { ...newState, sources: value as string[] });
    }
  };

  useEffect(() => {
    if (areInitialFieldValues) {
      handleUpdateItem({ itemId: id!, type: DndCategoryCardFieldType.IsValid, value: false });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!areInitialFieldValues) {
      handleUpdateItem({ itemId: id!, type: DndCategoryCardFieldType.IsValid, value: isValid });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  const onBlur = (fieldType: DndCategoryCardFieldType) => (
    { target: { value } }: FocusEvent<HTMLInputElement>,
  ) => {
    handleUpdateItem({ itemId: id!, type: fieldType, value: String(value) });
  };

  const setOnSubmitFormForItemCallback = useCallback((callback: () => Promise<void>) => {
    setTimeout(() => {
      handleUpdateItem({
        itemId: id!, type: DndCategoryCardFieldType.SubmitFormCallback, value: callback,
      });
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DndItemWrapper
      layout={layout ?? WidgetLayout.Common}
      isDragging={isDragging}
      isActive
      deleteItem={deleteItem}
      id={id!}
    >
      <Formik
        initialValues={id
          ? {
            id, name, frName, image, frImage, link, isValid,
          }
          : defaultInitialValues}
        validationSchema={dndCategoryCardSchema}
        onSubmit={() => {}}
      >
        {({ submitForm, errors, setFieldValue }) => (
          <Form>
            <Box p={2}>
              <FormikItemSubmitFormInitializer
                submitForm={submitForm}
                setOnSubmitFormForItem={setOnSubmitFormForItemCallback}
              />
              <FormikErrorsListener
                onErrors={() => setIsValid(false)}
                onNoErrors={() => setIsValid(true)}
              />
              <LocalizedTextField
                label="Category Name"
                englishText={name || ''}
                frenchText={frName || ''}
                textLanguage={nameTextLanguage}
                setTextLanguage={setNameTextLanguage}
                onBlur={(event) => {
                  if (nameTextLanguage === 'en') {
                    setFieldValue('name', event.target.value);
                    handleUpdateItem({
                      itemId: id!,
                      type: DndCategoryCardFieldType.Name,
                      value: event.target.value,
                    });
                  } else {
                    handleUpdateItem({
                      itemId: id!,
                      type: DndCategoryCardFieldType.FrName,
                      value: event.target.value,
                    });
                  }
                }}
                errors={errors?.name}
              />
              <LocalizedTextField
                label="Category Image"
                englishText={image || ''}
                frenchText={frImage || ''}
                textLanguage={imageTextLanguage}
                setTextLanguage={setImageTextLanguage}
                onBlur={(event) => {
                  if (imageTextLanguage === 'en') {
                    setFieldValue('image', event.target.value);
                    handleUpdateItem({
                      itemId: id!,
                      type: DndCategoryCardFieldType.Image,
                      value: event.target.value,
                    });
                  } else {
                    handleUpdateItem({
                      itemId: id!,
                      type: DndCategoryCardFieldType.FrImage,
                      value: event.target.value,
                    });
                  }
                }}
                errors={errors?.image}
              />
              <TextField
                label="Category Link"
                name="link"
                variant="standard"
                sx={styles.textField}
                fullWidth
                onBlur={onBlur(DndCategoryCardFieldType.Link)}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </DndItemWrapper>
  );
};
