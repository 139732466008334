import { AppInsightsProvider } from 'utils/telemetry/app-insights-provider';
import {
  Widget,
  WidgetUpdateParameters,
} from 'containers/SkinnyBannerContainer/types';
import { api } from 'api/apibase.service';
import { getWidgetLayout } from 'containers/SkinnyBannerContainer/utils/get-widget-layout';
import { useGlobalAlertStore } from 'common/store/client/global-alert/use-global-alert.store';
import { useMutation } from '@tanstack/react-query';
import { useSkinnyBannerWidgetsStore } from 'containers/SkinnyBannerContainer/store/client/use-skinny-banner-widgets.store';

interface UpdateWidgetSuccessResponse<T> {
  data: T;
}

export const useUpdateWidgetMutation = <T extends Widget>(
  closeModal: () => void,
) => {
  const updateWidget = useSkinnyBannerWidgetsStore((store) => store.updateWidget);
  const openAlert = useGlobalAlertStore((store) => store.openAlert);

  return useMutation<UpdateWidgetSuccessResponse<T>, Error, WidgetUpdateParameters>(
    ({ id, data }: WidgetUpdateParameters) => api.base.post(`/widgets/${id}`, data),
    {
      onSuccess: ({ data }) => {
        updateWidget(
          data.id,
          {
            name: data.name,
            layout: getWidgetLayout(data),
            activeFrom: data.activeFrom,
            activeTo: data.activeTo,
            sources: data.sources,
          },
        );
        openAlert({
          text: 'Widget was updated successfully',
          severity: 'success',
        });
        closeModal();
      },
      onError: (error) => {
        AppInsightsProvider.trackError(error);
        openAlert({
          text: `Error occurred during update, please try again. Error: ${String(error)}`,
          severity: 'error',
        });
      },
    },
  );
};
