import * as styles from 'common/components/GenericModal/styles';
import {
  ActionButtons,
  Container,
  Content,
  Image,
} from 'common/components/GenericModal/styles';
import {
  Box,
  Button, Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import {
  BubbleTextFormData,
  BubbleTextWidget,
  CreateBubbleTextData, UpdateBubbleTextData,
} from 'containers/HomePageContainer/types/bubble-text';
import { DEVICE_ACTIVATION_SWITCH_WARNING } from 'containers/HomePageContainer/components/PageConfigurator/constants';
import { DateTimePickerField } from 'common/components/DateTimePickerField';
import { DndProvider } from 'react-dnd';
import { DndStackContainer } from 'containers/DndStackContainer/DndStackContainer';
import { DraggableBubbleTextCard } from 'containers/DndStackContainer/types';
import { DraggableItemType } from 'containers/DndStackContainer/enums/draggable-item-type.enum';
import {
  Field, FieldProps, Form, Formik,
} from 'formik';
import { FormikErrorsListener } from 'common/components/FormikErrorsListener';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Loader } from 'common/components/Loader';
import { LoadingButton } from '@mui/lab';
import { LocalizedTextField } from 'containers/HomePageContainer/utils/LocalizedTextField';
import { PageType } from 'common/enums/page-type.enum';
import { PositionSelect } from 'common/components/StaticWidgetSelect';
import { SourceSelect } from 'common/components/SourceSelect';
import { TextField } from 'common/components/TextField/index';
import { areWidgetItemsEqualToInitialItems } from 'containers/HomePageContainer/utils/are-widget-items-equal-to-initial-items';
import { bubbleTextWidgetSchema } from 'containers/HomePageContainer/components/PageConfigurator/BubbleTextWidgetModalContent/bubble-text-widget.schema';
import { mapBubbleTextFormDataToRequest } from 'containers/HomePageContainer/components/PageConfigurator/BubbleTextWidgetModalContent/mappers/map-bubble-text-form-data-to-request';
import { mapItemsToInitialValues, mapWidgetDataToFormInitialValues } from 'containers/HomePageContainer/components/PageConfigurator/BubbleTextWidgetModalContent/mappers/map-widget-data-to-form-initial-values';
import { useCreateWidgetMutation } from 'containers/HomePageContainer/store/server/use-create-widget-mutation';
import {
  useDefaultInitialValues,
} from 'containers/HomePageContainer/components/PageConfigurator/BubbleTextWidgetModalContent/hooks/use-default-initial-values';
import { useGlobalAlertStore } from 'common/store/client/global-alert/use-global-alert.store';
import { useSearch } from 'containers/HomePageContainer/hooks/use-search';
import { useUpdateWidgetMutation } from 'containers/HomePageContainer/store/server/use-update-widget-mutation';
import { useWidget } from 'containers/HomePageContainer/store/server/use-widget';
import { v4 as uuidv4 } from 'uuid';
import AddIcon from '@mui/icons-material/Add';
import BubbleTextWidgetImage from 'assets/widgets/thumbnails/Bubble_Text_Widget.png';
import CancelIcon from '@mui/icons-material/Cancel';
import React, {
  Dispatch,
  FC, SetStateAction, useCallback, useEffect, useMemo, useState,
} from 'react';
import SaveIcon from '@mui/icons-material/Save';

export interface BubbleTextWidgetModalContentProps {
  id?: string;
  closeModal: () => void;
  pageType: PageType;
}

export const BubbleTextWidgetModalContent: FC<BubbleTextWidgetModalContentProps> = ({
  closeModal,
  id,
  pageType,
}) => {
  const defaultInitialValues = useDefaultInitialValues();
  const { culture } = useSearch();
  const { data, isLoading } = useWidget<BubbleTextWidget>(id);
  const createMutation = useCreateWidgetMutation(closeModal);
  const updateMutation = useUpdateWidgetMutation(closeModal);
  const [desktopBubbleText, setDesktopBubbleText] = useState<DraggableBubbleTextCard[]>([]);
  const [mobileBubbleText, setMobileBubbleText] = useState<DraggableBubbleTextCard[]>([]);
  const [isValid, setIsValid] = useState(true);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const openAlert = useGlobalAlertStore((store) => store.openAlert);
  const [isActiveDesktopBubbleText, setIsActiveDesktopBubbleText] = useState(true);
  const [isActiveMobileBubbleText, setIsActiveMobileBubbleText] = useState(true);
  const [desktopTextLanguage, setDesktopTextLanguage] = useState('en');
  const [mobileTextLanguages, setMobileTextLanguages] = useState('en');
  const widgetsDirty = useMemo(
    () => !areWidgetItemsEqualToInitialItems(
      desktopBubbleText,
      mapItemsToInitialValues,
      data?.content?.desktop?.items,
    )
      || !areWidgetItemsEqualToInitialItems(
        mobileBubbleText,
        mapItemsToInitialValues,
        data?.content?.mobile?.items,
      ),
    [data, desktopBubbleText, mobileBubbleText],
  );

  useEffect(() => {
    if (data) {
      const { sources } = data;
      setIsActiveDesktopBubbleText(Boolean(data.content?.desktop?.available));
      setIsActiveMobileBubbleText(Boolean(data.content?.mobile?.available));
      setDesktopBubbleText(mapItemsToInitialValues(data.content?.desktop?.items, sources));
      setMobileBubbleText(mapItemsToInitialValues(data.content?.mobile?.items, sources));
    }
  }, [data]);

  const setBubbleTextCallback = useCallback((prev: DraggableBubbleTextCard[]) => [...prev, {
    id: uuidv4(),
    title: '',
    frTitle: '',
    link: '',
    isValid: false,
    onSubmitCallback: undefined,
    sources: ['organic'],
  }], []);

  const openFormInvalidAlert = useCallback(() => {
    openAlert({
      text: 'Highlighted fields are invalid, they should be filled with proper values',
      severity: 'error',
    });
  }, [openAlert]);

  const openBubbleTextInvalidAlert = useCallback(() => {
    openAlert({
      text: 'Highlighted fields in bubble texts are invalid, they should be filled with proper values',
      severity: 'error',
    });
  }, [openAlert]);

  const openBubbleTextEmptyAlert = useCallback(() => {
    openAlert({
      text: 'Highlighted fields in bubble texts are empty, please add at least 1 Bubble Widget in active device categories',
      severity: 'error',
    });
  }, [openAlert]);

  const dndItemsHandleOnSubmit = useCallback(() => {
    desktopBubbleText.forEach((chipWidget) => { chipWidget.onSubmitCallback?.(); });
    mobileBubbleText.forEach((chipWidget) => { chipWidget.onSubmitCallback?.(); });
  }, [desktopBubbleText, mobileBubbleText]);

  const areSomeBubbleTextsInvalid = useCallback(
    () => {
      if (desktopBubbleText.some((chipWidget) => !chipWidget.isValid)
            || mobileBubbleText.some((chipWidget) => !chipWidget.isValid)) {
        return true;
      }
      return false;
    },
    [desktopBubbleText, mobileBubbleText],
  );

  const areSomeBubbleTextsEmpty = useCallback(
    () => {
      if (isActiveDesktopBubbleText && desktopBubbleText.length === 0) {
        return true;
      }
      if (isActiveMobileBubbleText && mobileBubbleText.length === 0) {
        return true;
      }
      return false;
    },
    [desktopBubbleText, mobileBubbleText, isActiveDesktopBubbleText, isActiveMobileBubbleText],
  );

  const isSubmitWithEmptyBubbleText = useCallback((
    chipWidget: DraggableBubbleTextCard[],
  ) => submitAttempted && chipWidget.length === 0, [submitAttempted]);

  const handleActivityChange = useCallback((
    value: boolean,
    setState: Dispatch<SetStateAction<boolean>>,
  ) => {
    if (value || (isActiveDesktopBubbleText && isActiveMobileBubbleText)) {
      setState(value);
    } else {
      openAlert(DEVICE_ACTIVATION_SWITCH_WARNING);
    }
  }, [isActiveDesktopBubbleText, isActiveMobileBubbleText, openAlert]);

  const handleSubmit = (values: BubbleTextFormData) => {
    if (!isValid) {
      openFormInvalidAlert();
    }
    if (areSomeBubbleTextsInvalid()) {
      openBubbleTextInvalidAlert();
      return;
    }
    if (areSomeBubbleTextsEmpty()) {
      openBubbleTextEmptyAlert();
      return;
    }

    if (id) {
      updateMutation.mutate({
        id,
        data: mapBubbleTextFormDataToRequest(
          values,
          pageType,
          desktopBubbleText,
          mobileBubbleText,
        ) as UpdateBubbleTextData,
      });
    } else {
      createMutation.mutate(mapBubbleTextFormDataToRequest(
        values,
        pageType,
        desktopBubbleText,
        mobileBubbleText,
        culture,
      ) as CreateBubbleTextData);
    }
  };

  if (pageType === PageType.Plp) {
    defaultInitialValues.position = 1;
  }
  if (id && isLoading) {
    return <Container><Loader isLoading /></Container>;
  }

  return (
    <Container>
      <Content>
        <Formik
          initialValues={data ? mapWidgetDataToFormInitialValues(data) : defaultInitialValues}
          validationSchema={bubbleTextWidgetSchema}
          onSubmit={handleSubmit}
        >
          {({
            dirty, setFieldValue, values, errors,
          }) => (
            <Form>
              <FormikErrorsListener
                onErrors={() => setIsValid(false)}
                onNoErrors={() => setIsValid(true)}
              />
              <Box display="flex">
                <Image src={BubbleTextWidgetImage} alt="bubble text widget" />
                <Box pl={3.75}>
                  <TextField name="name" label="Widget Name" fullWidth />
                  <Box display="flex">
                    <DateTimePickerField name="activeFrom" label="Valid from" sx={styles.dateTimePicker} />
                    <DateTimePickerField name="activeTo" label="Valid to" sx={styles.dateTimePicker} />
                    {pageType !== PageType.Plp && (
                      <SourceSelect name="sources" />
                    )}
                    {pageType === PageType.Plp && (
                      <PositionSelect name="position" />
                    )}
                  </Box>
                </Box>
              </Box>
              <Divider sx={styles.divider} />
              <Grid container spacing={10}>
                <Grid item xs={6}>
                  <Field name="isActiveDesktop">
                    {({ field }: FieldProps<boolean>) => (
                      <FormControlLabel
                        control={(
                          <Switch
                            checked={isActiveDesktopBubbleText}
                            onClick={() => handleActivityChange(
                              !isActiveDesktopBubbleText,
                              setIsActiveDesktopBubbleText,
                            )}
                            {...field}
                          />
                        )}
                        label="Desktop"
                        labelPlacement="start"
                        sx={styles.availabilitySwitch}
                        {...field}
                      />
                    )}
                  </Field>
                  <LocalizedTextField
                    label="Bubble Text Title"
                    englishText={values.bubbleTextTitleDesktop || ''}
                    frenchText={values.bubbleTextFrTitleDesktop || ''}
                    textLanguage={desktopTextLanguage}
                    setTextLanguage={setDesktopTextLanguage}
                    onBlur={(event) => {
                      if (desktopTextLanguage === 'fr') {
                        setFieldValue('bubbleTextFrTitleDesktop', event.target.value);
                      } else {
                        setFieldValue('bubbleTextTitleDesktop', event.target.value);
                      }
                    }}
                    errors={errors?.bubbleTextTitleDesktop}
                  />
                </Grid>
                <Grid item xs={6} pt={2}>
                  <Field name="isActiveMobile">
                    {({ field }: FieldProps<boolean>) => (
                      <FormControlLabel
                        control={(
                          <Switch
                            checked={isActiveMobileBubbleText}
                            onClick={() => handleActivityChange(
                              !isActiveMobileBubbleText,
                              setIsActiveMobileBubbleText,
                            )}
                            {...field}
                          />
                        )}
                        label="Mobile"
                        labelPlacement="start"
                        sx={styles.availabilitySwitch}
                        {...field}
                      />
                    )}
                  </Field>
                  <LocalizedTextField
                    label="Bubble Text Title"
                    englishText={values.bubbleTextTitleMobile || ''}
                    frenchText={values.bubbleTextFrTitleMobile || ''}
                    textLanguage={mobileTextLanguages}
                    setTextLanguage={setMobileTextLanguages}
                    onBlur={(event) => {
                      if (mobileTextLanguages === 'fr') {
                        setFieldValue('bubbleTextFrTitleMobile', event.target.value);
                      } else {
                        setFieldValue('bubbleTextTitleMobile', event.target.value);
                      }
                    }}
                    errors={errors?.bubbleTextTitleMobile}
                  />
                </Grid>
              </Grid>
              <ActionButtons>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={closeModal}
                  startIcon={<CancelIcon />}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={createMutation.isLoading || updateMutation.isLoading}
                  disabled={data && !(dirty || widgetsDirty)}
                  variant="contained"
                  type="submit"
                  size="large"
                  startIcon={<SaveIcon />}
                  onClick={() => {
                    setSubmitAttempted(true);
                    dndItemsHandleOnSubmit();
                    if (!isValid) {
                      openFormInvalidAlert();
                    }
                  }}
                >
                  Save
                </LoadingButton>
              </ActionButtons>
            </Form>
          )}
        </Formik>
        <Grid container spacing={10}>
          <Grid item xs={6} pt={2}>
            <Typography variant="subtitle1" my={3}>
              {`${desktopBubbleText.length} Bubble Texts in desktop:`}
            </Typography>
            <DndProvider backend={HTML5Backend}>
              <DndStackContainer
                itemType={DraggableItemType.BubbleTextWidget}
                items={desktopBubbleText}
                setItems={setDesktopBubbleText}
                columnHeaders={['', '']}
                pageType={pageType}
              />
            </DndProvider>
            <Button
              onClick={() => setDesktopBubbleText(setBubbleTextCallback)}
              sx={styles.getAddButton(
                isActiveDesktopBubbleText && isSubmitWithEmptyBubbleText(desktopBubbleText),
              )}
              variant="outlined"
              startIcon={<AddIcon />}
              fullWidth
            >
              Add Bubble Text Widget
            </Button>
          </Grid>
          <Grid item xs={6} pt={2}>
            <Typography variant="subtitle1" my={3}>
              {`${mobileBubbleText.length} Bubble Texts in mobile:`}
            </Typography>
            <DndProvider backend={HTML5Backend}>
              <DndStackContainer
                itemType={DraggableItemType.BubbleTextWidget}
                items={mobileBubbleText}
                setItems={setMobileBubbleText}
                columnHeaders={['', '']}
                pageType={pageType}
              />
            </DndProvider>
            <Button
              onClick={() => setMobileBubbleText(setBubbleTextCallback)}
              sx={styles.getAddButton(
                isActiveMobileBubbleText && isSubmitWithEmptyBubbleText(mobileBubbleText),
              )}
              variant="outlined"
              startIcon={<AddIcon />}
              fullWidth
            >
              Add Bubble Text Widget
            </Button>
          </Grid>
        </Grid>
      </Content>
    </Container>
  );
};

BubbleTextWidgetModalContent.defaultProps = {
  id: '',
};
