import { DndBannerCard } from 'containers/HomePageContainer/components/PageConfigurator/HeroBannerModalContent/DndBannerCard';
import { DndBubbleTextCard } from 'containers/HomePageContainer/components/PageConfigurator/BubbleTextWidgetModalContent/DndBubbleTextCard';
import { DndCategoryCard } from 'containers/HomePageContainer/components/PageConfigurator/ShopByCategoryModalContent/DndCategoryCard';
import { DndItem } from 'containers/DndStackContainer/types';
import { DndNotificationBannerCard } from 'containers/HomePageContainer/components/PageConfigurator/NotificationBannerModalContent/DndNotificationBannerCard';
import { DndOccasionCard } from 'containers/HomePageContainer/components/PageConfigurator/ShopByOccasionModalContent/DndOccasionCard';
import { DndPageConfigCard } from 'containers/DndStackContainer/components/DndPageConfigCard';
import { DraggableItemType } from 'containers/DndStackContainer/enums/draggable-item-type.enum';
import { PageType } from 'common/enums/page-type.enum';
import React from 'react';

export function createElementByType(props: {
  item: DndItem;
  itemType: DraggableItemType;
  updateItemState: (
    id: string,
    state: DndItem
  ) => void;
  isDragging: boolean;
  deleteItem: (id: string) => void;
  pageType: PageType;
}) {
  if (props.itemType === DraggableItemType.PageConfigWidget) {
    return React.createElement(DndPageConfigCard, { ...props, ...props.item });
  }
  if (props.itemType === DraggableItemType.OccasionWidget) {
    return React.createElement(DndOccasionCard, { ...props, ...props.item });
  }
  if (props.itemType === DraggableItemType.CategoryWidget) {
    return React.createElement(DndCategoryCard, { ...props, ...props.item });
  }
  if (props.itemType === DraggableItemType.BubbleTextWidget) {
    return React.createElement(DndBubbleTextCard, { ...props, ...props.item });
  }
  if (props.itemType === DraggableItemType.NotificationBannerWidget) {
    return React.createElement(DndNotificationBannerCard, { ...props, ...props.item });
  }
  return React.createElement(DndBannerCard, { ...props, ...props.item });
}
