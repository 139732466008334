import { Locale } from 'common/enums/locale';
import { QueryParams } from 'api/types/common.types';
import { Sources } from 'common/enums/source';
import { isCulture } from 'utils/is-culture';
import { isSource } from 'utils/is-source';
import { isValid } from 'date-fns';

const defaultDate = new Date();

export const parseSearch = (search: string): Required<QueryParams> => {
  const searchParams = new URLSearchParams(search);

  const culture = searchParams.get('culture') || '';
  const date = new Date(searchParams.get('date') || '');
  const validDate = isValid(date) ? date : defaultDate;
  const source = searchParams.get('source') || '';
  const filterId = searchParams.get('filterId') || '0';

  return {
    culture: isCulture(culture) ? culture : Locale.EnUs,
    source: isSource(source) ? source : Sources.organic,
    date: validDate.toISOString(),
    filterId,
  };
};
