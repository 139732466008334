import {
  FormControlLabel,
  Switch as SwitchMui,
  SxProps,
} from '@mui/material';
import { FormControlLabelProps } from '@mui/material/FormControlLabel/FormControlLabel';
import { useField } from 'formik';
import React, { ChangeEvent, FC } from 'react';

interface SwitchProps {
  name: string;
  label?: string;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  sx?: SxProps;
  disabled?: boolean;
  onChange?: (a: ChangeEvent<HTMLInputElement>) => void;
}

export const Switch: FC<SwitchProps> = ({
  label,
  labelPlacement,
  sx,
  disabled,
  onChange,
  ...props
}) => {
  const [field] = useField<boolean>(props);

  return (
    <FormControlLabel
      control={(
        <SwitchMui
          checked={field.value}
          {...field}
          {...onChange ? { onChange } : null}
        />
      )}
      label={label}
      labelPlacement={labelPlacement}
      sx={sx}
      disabled={disabled}
      {...field}
    />
  );
};

Switch.defaultProps = {
  label: '',
  labelPlacement: 'end',
  sx: {},
  onChange: undefined,
  disabled: false,
};
