import { CreateSkinnyBannerData, SkinnyBannerFormData, UpdateSkinnyBannerData } from 'containers/SkinnyBannerContainer/types/skinny-banner';
import { DraggableSkinnyCard } from 'containers/DndSkinnyContainer/types';
import { Locale } from 'common/enums/locale';
import { SkinnyBannerWidgetType } from 'containers/SkinnyBannerContainer/enum/component.enum';
import { convertDefaultTimeToDaylightSavingsTime, convertDefaultZoneToUtc } from 'utils/date';

export const mapSkinnyBannerFormDataToRequest = (
  data: SkinnyBannerFormData,
  culture?: Locale,
): CreateSkinnyBannerData | UpdateSkinnyBannerData => {
  const deviceItemsMapper = (skinny: DraggableSkinnyCard, index: number) => ({
    id: skinny.id!,
    sequence: index,
    editorContent: skinny.editorContent!,
    frEditorContent: skinny.frEditorContent!,
    url: skinny.url!,
  });

  return ({
    ...data,
    activeFrom: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeFrom)),
    activeTo: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeTo)),
    eventName: data.name,
    type: SkinnyBannerWidgetType.SkinnyBanner,
    content: {
      desktop: {
        available: data.content.desktop.available,
        autoSlideInterval: Number(data.content.desktop.autoSlideInterval),
        bannerBackgroundColor: data.content.desktop.bannerBackgroundColor,
        items: data.content.desktop.items?.map(deviceItemsMapper),
      },
      mobile: {
        available: data.content.mobile.available,
        autoSlideInterval: Number(data.content.mobile.autoSlideInterval),
        bannerBackgroundColor: data.content.mobile.bannerBackgroundColor,
        items: data.content.mobile.items?.map(deviceItemsMapper),
      },
    },
    ...(culture ? { culture } : null),
  });
};
