import { useFormikContext } from 'formik';
import React, { FC, useEffect, useMemo } from 'react';
import isEqual from 'lodash/isEqual';

interface FormikErrorsListenerProps {
  onErrors: (...args: unknown[]) => unknown;
  onNoErrors: (...args: unknown[]) => unknown;
  shouldTriggerErrors?: (e: unknown, n: unknown) => boolean;
}

const defaultShouldTriggerErrors = (
  errors: unknown,
  nextErrors: unknown,
) => !isEqual(errors, nextErrors);

export const FormikErrorsListener: FC<FormikErrorsListenerProps> = ({
  onErrors,
  onNoErrors,
  shouldTriggerErrors,
}) => {
  const areErrors = shouldTriggerErrors || defaultShouldTriggerErrors;
  const formik = useFormikContext();
  const [errors, updateErrors] = React.useState(formik.errors);
  const formikErrors = useMemo(() => formik.errors, [formik.errors]);

  useEffect(() => {
    if (!areErrors(errors, formikErrors)) {
      onNoErrors();
      updateErrors({});
      return;
    }
    onErrors(formikErrors);
    updateErrors(errors);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikErrors]);

  return null;
};

FormikErrorsListener.defaultProps = {
  shouldTriggerErrors: defaultShouldTriggerErrors,
};
