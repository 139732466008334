import { BannerWithProductList, BannerWithProductListFormData } from 'containers/HomePageContainer/types/banner-with-product-list';
import { convertDaylightSavingsTimeToDefaultTime, convertUtcToDefaultZone } from 'utils/date';

export const mapWidgetDataToFormInitialValues = (
  data: BannerWithProductList,
): BannerWithProductListFormData => {
  const sourcesArray = Array.isArray(data.sources) ? data.sources : [data.sources];
  const initializeArrayIfUndefined = !sourcesArray || sourcesArray[0] === undefined ? ['organic'] : sourcesArray;
  return {
    name: data.name,
    activeFrom: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeFrom)),
    activeTo: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeTo)),
    sources: initializeArrayIfUndefined,
    content: data.content,
  };
};
