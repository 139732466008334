import { ModalContentType } from 'common/enums/modal-content-type';
import { SkinnyBannerWidgetType } from 'containers/SkinnyBannerContainer/enum/component.enum';
import { SkinnyBannerWidgetTypes } from 'containers/SkinnyBannerContainer/types/component.type';

const widgetTypeToModalTypeMap: Partial<Record<SkinnyBannerWidgetType, ModalContentType>> = {
  [SkinnyBannerWidgetType.SkinnyBanner]: ModalContentType.ModifySkinnyBannerWidget,
};

export const getEditModalType = (
  widgetType?: SkinnyBannerWidgetTypes,
): ModalContentType => (
  widgetType ? widgetTypeToModalTypeMap[widgetType] || ModalContentType.Empty
    : ModalContentType.Empty
);
