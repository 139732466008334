import { AppInsightsProvider } from 'utils/telemetry/app-insights-provider';
import { Configuration, LogLevel } from '@azure/msal-browser';
import { replaceTenantIdPlaceholder } from 'utils/replaceTenantIdPlaceholder';

export const configuration: Configuration = {
  auth: {
    clientId: window.AD_CLIENT_ID || '',
    redirectUri: window.AD_REDIRECT_URI,
    authority: replaceTenantIdPlaceholder(window.AD_AUTHORITY),
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          if (LogLevel.Error) {
            // eslint-disable-next-line no-console
            console.error(message);
            AppInsightsProvider.trackMessage('Some error happened with MSAL, but message can\'t be displayed in telemetry because it has PII');
          }
          return;
        }
        if (level === LogLevel.Error) {
          // eslint-disable-next-line no-console
          console.error(message);
          AppInsightsProvider.trackError(new Error(message));
          return;
        }
        if (level === LogLevel.Warning) {
          // eslint-disable-next-line no-console
          console.warn(message);
          AppInsightsProvider.trackMessage(message);
        }
      },
      piiLoggingEnabled: true,
    },
  },
};
