import * as styles from 'common/components/GenericModal/styles';
import {
  ActionButtons,
  Container,
  Content,
  Image,
} from 'common/components/GenericModal/styles';
import {
  Box, Button, Divider,
} from '@mui/material';
import { DateTimePickerField } from 'common/components/DateTimePickerField';
import { Form, Formik } from 'formik';
import { Loader } from 'common/components/Loader';
import { LoadingButton } from '@mui/lab';
import { PageType } from 'common/enums/page-type.enum';
import {
  ProductCarousel,
  ProductCarouselFormData,
} from 'containers/HomePageContainer/types/product-carousel';
import { ProductsConfiguration } from 'containers/HomePageContainer/components/PageConfigurator/ProductCarouselModalContent/ProductsConfiguration';
import { SourceSelect } from 'common/components/SourceSelect';
import { TextField } from 'common/components/TextField';
import {
  mapProductCarouselCreationFormDataToRequest,
} from 'containers/HomePageContainer/components/PageConfigurator/ProductCarouselModalContent/mappers/map-product-carousel-creation-form-data-to-request';
import { mapProductCarouselUpdateFormDataToRequest } from 'containers/HomePageContainer/components/PageConfigurator/ProductCarouselModalContent/mappers/map-product-carousel-update-form-data-to-request';
import { mapWidgetDataToFormInitialValues } from 'containers/HomePageContainer/components/PageConfigurator/ProductCarouselModalContent/mappers/map-widget-data-to-form-initial-values';
import {
  productCarouselWidgetSchema,
} from 'containers/HomePageContainer/components/PageConfigurator/ProductCarouselModalContent/product-carousel-widget.schema';
import { useCreateWidgetMutation } from 'containers/HomePageContainer/store/server/use-create-widget-mutation';
import { useDefaultInitialValues } from 'containers/HomePageContainer/components/PageConfigurator/ProductCarouselModalContent/hooks/use-default-initial-values';
import { useSearch } from 'containers/HomePageContainer/hooks/use-search';
import { useUpdateWidgetMutation } from 'containers/HomePageContainer/store/server/use-update-widget-mutation';
import { useWidget } from 'containers/HomePageContainer/store/server/use-widget';
import CancelIcon from '@mui/icons-material/Cancel';
import ProductCarouselImage from 'assets/widgets/thumbnails/Product_Carousel_Widget.png';
import React, { FC, useCallback } from 'react';
import SaveIcon from '@mui/icons-material/Save';

export interface ProductCarouselModalContentProps {
  closeModal: () => void;
  id?: string;
  pageType: PageType;
}

export const ProductCarouselModalContent: FC<ProductCarouselModalContentProps> = ({
  closeModal,
  id,
  pageType,
}) => {
  const defaultInitialValues = useDefaultInitialValues();
  const { culture } = useSearch();
  const { data: widget, isLoading } = useWidget<ProductCarousel>(id);
  const createMutation = useCreateWidgetMutation(closeModal);
  const updateMutation = useUpdateWidgetMutation(closeModal);

  const handleSubmit = useCallback((values: ProductCarouselFormData) => {
    if (id) {
      updateMutation.mutate({
        id,
        data: mapProductCarouselUpdateFormDataToRequest(values),
      });
    } else {
      createMutation.mutate(mapProductCarouselCreationFormDataToRequest(values, culture));
    }
  }, [id, culture, createMutation, updateMutation]);

  if (id && isLoading) {
    return <Container><Loader isLoading /></Container>;
  }

  return (
    <Formik
      initialValues={widget ? mapWidgetDataToFormInitialValues(widget) : defaultInitialValues}
      validationSchema={productCarouselWidgetSchema}
      onSubmit={handleSubmit}
    >
      {({ dirty }) => (
        <Form>
          <Container>
            <Content>
              <Box display="flex">
                <Image src={ProductCarouselImage} alt="product carousel widget" />
                <Box pl={3.75}>
                  <TextField name="name" label="Widget Name" fullWidth />
                  <Box display="flex">
                    <DateTimePickerField name="activeFrom" label="Valid from" sx={styles.dateTimePicker} />
                    <DateTimePickerField name="activeTo" label="Valid to" sx={styles.dateTimePicker} />
                    {pageType !== PageType.Plp && (
                      <SourceSelect name="sources" />
                    )}
                  </Box>
                </Box>
              </Box>
              <Divider sx={styles.divider} />
              <ProductsConfiguration />
            </Content>
            <ActionButtons>
              <Button
                onClick={closeModal}
                size="large"
                variant="outlined"
                startIcon={<CancelIcon />}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={createMutation.isLoading || updateMutation.isLoading}
                disabled={widget && !dirty}
                variant="contained"
                type="submit"
                size="large"
                startIcon={<SaveIcon />}
              >
                Save
              </LoadingButton>
            </ActionButtons>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

ProductCarouselModalContent.defaultProps = {
  id: '',
};
