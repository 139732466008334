import { FilterProperties, filterWidgetItemsProperties } from 'containers/HomePageContainer/utils/filter-widget-items-properties';
import isEqual from 'lodash/isEqual';

export function areWidgetItemsEqualToInitialItems<T extends FilterProperties, I>(
  currentItems: T[],
  mapItemsToInitialValues: (items?: I[]) => T[],
  contentItems?: I[],
): boolean {
  const filteredCurrentItems = currentItems.map(filterWidgetItemsProperties);
  const initialItems = mapItemsToInitialValues(contentItems);

  return isEqual(filteredCurrentItems, initialItems);
}
