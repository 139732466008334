import { ProductCarouselDeviceContent, ProductCarouselFormData, Sort } from 'containers/HomePageContainer/types/product-carousel';
import { useInitCommonValues } from 'utils/hooks/use-init-common-values';

const deviceContentDefaultInitialValues: ProductCarouselDeviceContent = {
  available: true,
  title: '',
  frTitle: '',
  url: '',
  viewAllButtonText: '',
  frViewAllButtonText: '',
  filterParams: {
    sort: Sort.Sequence,
    limit: 10,
  },
};

export const useDefaultInitialValues = (): ProductCarouselFormData => ({
  ...useInitCommonValues(),
  content: {
    desktop: deviceContentDefaultInitialValues,
    mobile: deviceContentDefaultInitialValues,
  },
});
