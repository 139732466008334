import { DndItem } from 'containers/DndSkinnyContainer/types';
import { DndPageConfigCard } from 'containers/DndSkinnyContainer/components/DndPageConfigCard';
import { DndSkinnyBannerCard } from 'containers/SkinnyBannerContainer/components/PageConfigurator/SkinnyBannerModalContent/DndSkinnyBannerCard';
import { DraggableItemType } from 'containers/DndSkinnyContainer/enums/draggable-item-type.enum';
import React from 'react';

export function createElementByType(props: {
  item: DndItem;
  itemType: DraggableItemType;
  updateItemState: (
    id: string,
    state: DndItem
  ) => void;
  isDragging: boolean;
  deleteItem: (id: string) => void;
  bgColor: string;
}) {
  if (props.itemType === DraggableItemType.SkinnyBannerWidget) {
    return React.createElement(DndSkinnyBannerCard, { ...props, ...props.item });
  }
  return React.createElement(DndPageConfigCard, { ...props, ...props.item });
}
