export enum DndOccasionCardFieldType {
  Name = 'Name',
  FrName = 'FrName',
  Image = 'Image',
  FrImage = 'FrImage',
  Link = 'Link',
  IsValid = 'IsValid',
  SubmitFormCallback = 'SubmitFormCallback',
  Sources = 'Sources',
}
