export enum ModalContentType {
  SelectWidget = 'SelectWidget',
  CreateHeroBannerWidget = 'CreateHeroBannerWidget',
  ModifyHeroBannerWidget = 'ModifyHeroBannerWidget',
  CreateWidget = 'SelectWidgetType',
  CreateProductCarouselWidget = 'CreateProductCarouselWidget',
  ModifyProductCarouselWidget = 'ModifyProductCarouselWidget',
  CreateShopByCategoryWidget = 'CreateShopByCategoryWidget',
  ModifyShopByCategoryWidget = 'ModifyShopByCategoryWidget',
  CreateShopByOccasionWidget = 'CreateShopByOccasionWidget',
  ModifyShopByOccasionWidget = 'ModifyShopByOccasionWidget',
  CreateBubbleTextWidget = 'CreateBubbleTextWidget',
  ModifyBubbleTextWidget = 'ModifyBubbleTextWidget',
  CreateChipWidget = 'CreateChipWidget',
  ModifyChipWidget = 'ModifyChipWidget',
  CreateBannerWidget = 'CreateBannerWidget',
  ModifyBannerWidget = 'ModifyBannerWidget',
  CreateSecondaryBannerWidget = 'CreateSecondaryBannerWidget',
  ModifySecondaryBannerWidget = 'ModifySecondaryBannerWidget',
  CreateSkinnyBannerWidget = 'CreateSkinnyBannerWidget',
  ModifySkinnyBannerWidget = 'ModifySkinnyBannerWidget',
  Empty = 'Empty',
  CreateGeneralTextWidget = 'CreateGeneralTextWidget',
  ModifyGeneralTextWidget = 'ModifyGeneralTextWidget',
  CreateNotificationBannerWidget = 'CreateNotificationBannerWidget',
  ModifyNotificationBannerWidget = 'ModifyNotificationBannerWidget',
  CreateBVSocialWidget = 'CreateBVSocialWidget',
  ModifyBVSocialWidget = 'ModifyBVSocialWidget',
  CreateBannerWithProductListWidget = 'CreateBannerWithProductListWidget',
  ModifyBannerWithProductListWidget = 'ModifyBannerWithProductListWidget',
  CreateVideoWithTextWidget = 'CreateVideoWithTextWidget',
  ModifyVideoWithTextWidget = 'ModifyVideoWithTextWidget',
}
