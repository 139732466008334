import { HomePageWidgetType } from 'containers/HomePageContainer/enum/component.enum';
import {
  ProductCarouselFormData, UpdateProductCarouselData,
} from 'containers/HomePageContainer/types/product-carousel';
import { convertDefaultTimeToDaylightSavingsTime, convertDefaultZoneToUtc } from 'utils/date';

export const mapProductCarouselUpdateFormDataToRequest = (
  data: ProductCarouselFormData,
): UpdateProductCarouselData => ({
  ...data,
  activeFrom: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeFrom)),
  activeTo: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeTo)),
  eventName: data.name,
  type: HomePageWidgetType.CarouselDetailed,
});
