export enum Sources {
  organic = 'organic',
  email = 'email',
  social = 'social',
  user = 'user',
  birthday = 'birthday',
  holiday = 'holiday',
  anniversary = 'anniversary',
  freeDelivery = 'freeDelivery',
  sameDayDelivery = 'sameDayDelivery',
}
