import {
  CreateHeroBannerData,
  HeroBannerFormData,
  UpdateHeroBannerData,
} from 'containers/HomePageContainer/types/hero-banner';
import { DraggableBannerCard } from 'containers/DndStackContainer/types';
import { HomePageWidgetType } from 'containers/HomePageContainer/enum/component.enum';
import { Locale } from 'common/enums/locale';
import { convertDefaultTimeToDaylightSavingsTime, convertDefaultZoneToUtc } from 'utils/date';

const deviceItemsMapper = (banner: DraggableBannerCard, index: number) => ({
  id: banner.id!,
  sequence: index,
  name: banner.name!,
  src: banner.image!,
  frSrc: banner.frImage,
  fallbackSrc: banner.image!,
  altText: banner.altText!,
  frAltText: banner.frAltText,
  url: banner.link!,
  headerText: banner.headerText!,
  frHeaderText: banner.frHeaderText,
  contentText: banner.contentText!,
  frContentText: banner.frContentText,
  smallText: banner.smallText!,
  frSmallText: banner.frSmallText,
  buttonText: banner.buttonText!,
  frButtonText: banner.frButtonText,
  textColor: banner.textColor!,
  buttonTextColor: banner.buttonTextColor!,
  textAlignment: banner.textAlignment!,
});

export const mapHeroBannerFormDataToRequest = (
  data: HeroBannerFormData,
  desktopBanners: DraggableBannerCard[],
  mobileBanners: DraggableBannerCard[],
  culture?: Locale,
): CreateHeroBannerData | UpdateHeroBannerData => ({
  activeFrom: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeFrom)),
  activeTo: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeTo)),
  sources: data.sources,
  name: data.name,
  eventName: data.name,
  type: HomePageWidgetType.CarouselBanners,
  ...(culture ? { culture } : null),
  content: {
    desktop: {
      available: data.isActiveDesktop,
      autoSlideInterval: +data.autoSlideIntervalDesktop!,
      items: desktopBanners.map(deviceItemsMapper),
    },
    mobile: {
      available: data.isActiveMobile,
      autoSlideInterval: +data.autoSlideIntervalMobile!,
      items: mobileBanners.map(deviceItemsMapper),
    },
  },
});
