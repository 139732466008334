import { ModalContentType } from 'common/enums/modal-content-type';
import { SkinnyBannerWidgetType } from 'containers/SkinnyBannerContainer/enum/component.enum';
import SkinnyBanner from 'assets/widgets/thumbnails/Skinny_Banner.png';

export const mappedWidgetsProperties = {
  [SkinnyBannerWidgetType.SkinnyBanner]: {
    title: 'Skinny Banner',
    modalContentType: ModalContentType.CreateSkinnyBannerWidget,
    widgetName: SkinnyBannerWidgetType.SkinnyBanner,
    img: SkinnyBanner,
  },
};

export type MappedWidgetsPropertiesType
  = keyof typeof mappedWidgetsProperties;
