import { AxiosRequestConfig } from 'axios';
import { authService } from 'api/auth/auth.api.service';

export const appendAuthHeader = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const token = await authService.getToken();

  const headers = config.headers || {};
  headers.Authorization = `Bearer ${token || ''}`;

  return {
    ...config,
    headers,
  };
};
