import {
  HeroBannerFormData,
} from 'containers/HomePageContainer/types/hero-banner';
import { useInitCommonValues } from 'utils/hooks/use-init-common-values';

export const useDefaultInitialValues = (): HeroBannerFormData => ({
  ...useInitCommonValues(),
  autoSlideIntervalDesktop: 0,
  autoSlideIntervalMobile: 0,
});
