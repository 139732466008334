import { HomePageWidgetTypes } from 'containers/HomePageContainer/types/component.type';
import { Locale } from 'common/enums/locale';

/*
* For some reason SP uses highToLow as lowToHigh and vice versa,
* so we need to swap the sort values
*/
export enum Sort {
  Sequence = 'Sequence',
  PriceLowToHigh = 'PriceHighToLow',
  PriceHighToLow = 'PriceLowToHigh',
}

export interface ProductCarouselDeviceContent {
  available: boolean;
  title?: string;
  frTitle?: string;
  url?: string;
  viewAllButtonText?: string;
  frViewAllButtonText?: string;
  filterParams?: {
    sort: Sort;
    limit: number;
  };
}

export interface ProductCarousel {
  id: string;
  widgetId: string;
  culture: Locale;
  eventName: string;
  name: string;
  type: HomePageWidgetTypes;
  activeFrom: string;
  activeTo: string;
  sources: string[];
  content: {
    desktop: ProductCarouselDeviceContent;
    mobile: ProductCarouselDeviceContent;
  };
}

export type ProductCarouselFormData = Omit<ProductCarousel, 'id' | 'widgetId' | 'eventName' | 'type' | 'culture'>;
export type CreateProductCarouselData = Omit<ProductCarousel, 'id' | 'widgetId'>;
export type UpdateProductCarouselData = Omit<ProductCarousel, 'id' | 'widgetId' | 'culture'>;
