import { SxProps } from '@mui/material';

export const dndItemBoxWrap: SxProps = {
  width: 1,
  mb: 2,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
};
