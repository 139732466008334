import * as Yup from 'yup';

const stringShouldNotBeEmpty = 'This field is required, must be a non empty string';
const valueMustBeString = 'The value must be a string';
const dateShouldBeSet = 'Date should be set';
const valueMustBeDate = 'The value must be a date';
const activeToMustBeAfterActiveFrom = 'Valid to must be after Valid from';
const valueMustBeBoolean = 'This field must be a boolean value';
const booleanShouldBeSet = 'This field is required, must be a boolean value';
const sourceArrayShouldNotBeEmpty = 'Source must contain at least one item';
const sourceIsRequired = 'Source is required';

const deviceContentSchema = Yup.object({
  available: Yup.boolean()
    .typeError(valueMustBeBoolean)
    .required(booleanShouldBeSet),
});
export const bvSocialWidgetSchema = Yup.object().shape({
  name: Yup.string().min(1).required()
    .typeError(valueMustBeString)
    .required(stringShouldNotBeEmpty),
  activeFrom: Yup.date()
    .typeError(valueMustBeDate)
    .required(dateShouldBeSet),
  activeTo: Yup.date()
    .typeError(valueMustBeDate)
    .when('activeFrom', (activeFrom) => Yup.date().min(
      activeFrom,
      activeToMustBeAfterActiveFrom,
    ))
    .required(dateShouldBeSet),
  sources: Yup.array().of(Yup.string())
    .min(1, sourceArrayShouldNotBeEmpty)
    .required(sourceIsRequired),
  content: Yup.object({
    desktop: deviceContentSchema,
    mobile: deviceContentSchema,
  }),
});
