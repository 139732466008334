import * as styles from 'containers/DndStackContainer/styles';
import { Box, Grid, Typography } from '@mui/material';
import { DndItem } from 'containers/DndStackContainer/types';
import { DraggableItem } from 'containers/DndStackContainer/components/DraggableItem';
import { DraggableItemType } from 'containers/DndStackContainer/enums/draggable-item-type.enum';
import { PageType } from 'common/enums/page-type.enum';
import { isWidgetType } from 'utils/is-widget';
import { parseSearch } from 'containers/HomePageContainer/utils/parse-search';
import { useLocation } from 'react-router-dom';
import React, { FC, useCallback } from 'react';

interface DndStackContainerProps {
  itemType: DraggableItemType;
  items: DndItem[];
  columnHeaders: string[];
  setItems: (DraggableWidgets: DndItem[]) => void;
  pageType: PageType;
}

export const DndStackContainer: FC<DndStackContainerProps> = ({
  itemType, items, setItems, columnHeaders, pageType,
}) => {
  const { search } = useLocation();
  const { source } = parseSearch(search);
  let filterWidgets = items;
  if (pageType === PageType.HomePage) {
    filterWidgets = items.filter((item) => item.sources.includes(source));
  }
  const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    const newItems = [...items];
    const item = newItems[dragIndex];
    newItems.splice(dragIndex, 1);
    newItems.splice(hoverIndex, 0, item);
    setItems(newItems);
  }, [setItems, items]);

  const updateItemState = useCallback((
    id: string,
    newItemState: DndItem,
  ) => {
    const itemIndex = items.findIndex((item) => item.id === id);
    const newItems = [...items];
    if (itemIndex === undefined) {
      setItems(newItems);
    } else {
      newItems.splice(itemIndex, 1);
      newItems.splice(itemIndex, 0, { ...newItemState, id });
    }

    setItems(newItems);
  }, [setItems, items]);

  const deleteItem = useCallback((id: string) => {
    setItems(items.filter((item) => item.id !== id));
  }, [setItems, items]);

  return (
    <Box width={1}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={1}>
              <Typography align="center" variant="h6">{columnHeaders[0] ?? ''}</Typography>
            </Grid>
            <Grid item xs={columnHeaders.length > 2 ? 5.5 : 11}>
              <Typography align="center" variant="h6">{columnHeaders[1] ?? ''}</Typography>
            </Grid>
            {columnHeaders.length > 2 && (
              <Grid item xs={5.5}>
                <Typography align="center" variant="h6">{columnHeaders[2] ?? ''}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {
            items.map(
              (item, index) => (
                <>
                  {filterWidgets.map((filterItem, filterIndex) => (item.id === filterItem.id && (
                    <Box key={item.id} sx={styles.dndItemBoxWrap}>
                      <Grid container alignItems="center">
                        <Grid item xs={1}>
                          <Box display="flex" justifyContent="center">
                            <Typography m={2}>
                              {filterIndex + 1}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={11}>
                          <DraggableItem
                            index={index}
                            data-test={isWidgetType(itemType) ? `draggable-widget-${item.type!}` : 'draggable-item'}
                            moveItem={moveItem}
                            item={item}
                            itemType={itemType}
                            updateItemState={updateItemState}
                            deleteItem={deleteItem}
                            pageType={pageType}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )))}
                </>
              ),
            )
          }
        </Grid>
      </Grid>
    </Box>
  );
};
