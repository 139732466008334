import { BannerModalContent } from 'containers/HomePageContainer/components/PageConfigurator/BannerModalContent';
import { Box } from '@mui/material';
import { BubbleTextWidgetModalContent } from 'containers/HomePageContainer/components/PageConfigurator/BubbleTextWidgetModalContent';
import { CreateNewWidgetModalContent } from 'containers/PlpPageContainer/components/PageConfigurator/CreateNewWidgetModalContent';
import { ModalContentType } from 'common/enums/modal-content-type';
import { PageType } from 'common/enums/page-type.enum';
import { SecondaryBannerModalContent } from 'containers/HomePageContainer/components/PageConfigurator/SecondaryBannerModalContent';
import { ShopByOccasionModalContent } from 'containers/HomePageContainer/components/PageConfigurator/ShopByOccasionModalContent';
import { VideoTextModalContent } from 'containers/HomePageContainer/components/PageConfigurator/VideoWithTextModalContent';
import { WidgetList } from 'containers/PlpPageContainer/components/WidgetList';
import React from 'react';

interface Parameters {
  type: ModalContentType;
  closeModal: () => void;
  id?: string;
  modalPageType: PageType;
}

export function getPlpModalContent({
  type,
  closeModal,
  id,
  modalPageType,
}: Parameters) {
  if (
    type === ModalContentType.CreateVideoWithTextWidget
    || type === ModalContentType.ModifyVideoWithTextWidget
  ) {
    return (
      <VideoTextModalContent closeModal={closeModal} id={id} pageType={modalPageType} />
    );
  }
  if (
    type === ModalContentType.CreateBannerWidget
    || type === ModalContentType.ModifyBannerWidget
  ) {
    return (
      <BannerModalContent closeModal={closeModal} id={id} pageType={modalPageType} />
    );
  }
  if (
    type === ModalContentType.CreateSecondaryBannerWidget
    || type === ModalContentType.ModifySecondaryBannerWidget
  ) {
    return (
      <SecondaryBannerModalContent closeModal={closeModal} id={id} pageType={modalPageType} />
    );
  }
  if (
    type === ModalContentType.CreateShopByOccasionWidget
    || type === ModalContentType.ModifyShopByOccasionWidget
  ) {
    return (
      <ShopByOccasionModalContent closeModal={closeModal} id={id} pageType={modalPageType} />
    );
  }
  if (
    type === ModalContentType.CreateChipWidget
    || type === ModalContentType.ModifyChipWidget
  ) {
    return (
      <BubbleTextWidgetModalContent closeModal={closeModal} id={id} pageType={modalPageType} />
    );
  }
  if (type === ModalContentType.CreateWidget) {
    return (
      <CreateNewWidgetModalContent pageType={modalPageType} />
    );
  }
  if (type === ModalContentType.SelectWidget) {
    return (
      <WidgetList handleClose={closeModal} />
    );
  }
  return <Box>{type}</Box>;
}
