import { DesktopSort, MobileSort, VideoTextFormDeviceContent } from 'containers/HomePageContainer/types/video-text';

export const desktopContentDefaultInitialValues: VideoTextFormDeviceContent = {
  available: true,
  hasImageToggle: false,
  altText: '',
  frAltText: '',
  src: '',
  frSrc: '',
  title: '',
  frTitle: '',
  description: '',
  frDescription: '',
  buttonText: '',
  buttonLink: '',
  frButtonText: '',
  frButtonLink: '',
  textPosition: DesktopSort.Right,
};

export const mobileContentDefaultInitialValues: VideoTextFormDeviceContent = {
  available: true,
  hasImageToggle: false,
  altText: '',
  frAltText: '',
  src: '',
  frSrc: '',
  title: '',
  frTitle: '',
  description: '',
  frDescription: '',
  buttonText: '',
  buttonLink: '',
  frButtonText: '',
  frButtonLink: '',
  textPosition: MobileSort.Bottom,
};
