import { DraggableBannerCard } from 'containers/DndStackContainer/types';
import {
  ShopByCategoryDeviceDataItem,
  ShopByCategoryFormData,
  ShopByCategoryWidget,
} from 'containers/HomePageContainer/types/shop-by-category';
import { convertDaylightSavingsTimeToDefaultTime, convertUtcToDefaultZone } from 'utils/date';

export const mapItemsToInitialValues = (
  items: ShopByCategoryDeviceDataItem[] = [],
  sources: string[] = ['organic'],
): DraggableBannerCard[] => (items || []).map((item) => ({
  id: item.id,
  name: item.name,
  frName: item.frName,
  image: item.src,
  frImage: item.frSrc,
  link: item.url,
  isValid: true,
  sources,
}));

export const mapWidgetDataToFormInitialValues = (
  data: ShopByCategoryWidget,
): ShopByCategoryFormData => ({
  name: data.name,
  activeFrom: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeFrom)),
  activeTo: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeTo)),
  isActiveDesktop: Boolean(data.content?.desktop?.available),
  isActiveMobile: Boolean(data.content?.mobile?.available),
  categoryTitleDesktop: data.content?.desktop?.title,
  categoryTitleMobile: data.content?.mobile?.title,
  frCategoryTitleDesktop: data.content?.desktop?.frTitle,
  frCategoryTitleMobile: data.content?.mobile?.frTitle,
  viewAllButtonTextDesktop: data.content?.desktop?.viewAllButtonText,
  viewAllButtonTextMobile: data.content?.mobile?.viewAllButtonText,
  frViewAllButtonTextDesktop: data.content?.desktop?.frViewAllButtonText,
  frViewAllButtonTextMobile: data.content?.mobile?.frViewAllButtonText,
  catalogUrlDesktop: data.content?.desktop?.url,
  catalogUrlMobile: data.content?.mobile?.url,
  sources: data.sources,
});
