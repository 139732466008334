import { AppRoutes } from 'common/enums/app-routes.enum';
import { Box } from '@mui/material';
import { DefaultSuspense } from 'common/components/DefaultSuspense';
import { LeftPanel } from 'common/components/LeftPanel';
import { Route, Routes } from 'react-router-dom';
import {
  useNotAuthorizedRedirect,
} from 'containers/AppContainer/hooks/use-not-authorized-redirect';
import React, { FC, lazy } from 'react';

const HomePage = lazy(() => import('containers/HomePageContainer'));
const NotAuthorized = lazy(() => import('containers/NotAuthorizedContainer'));
const SkinnyBanner = lazy(() => import('containers/SkinnyBannerContainer'));
const PlpPage = lazy(() => import('containers/PlpPageContainer'));

export const MainContainer: FC = () => {
  useNotAuthorizedRedirect();
  return (
    <Box height="100vh" maxWidth="1440px" mx="auto" display="flex" flexDirection="row">
      <Box width="120px">
        <LeftPanel />
      </Box>
      <Box width={1}>
        <Routes>
          <Route
            path={AppRoutes.Home}
            element={(
              <DefaultSuspense>
                <HomePage />
              </DefaultSuspense>
            )}
          />
          <Route
            path={AppRoutes.SkinnyBanner}
            element={(
              <DefaultSuspense>
                <SkinnyBanner />
              </DefaultSuspense>
            )}
          />
          <Route
            path={AppRoutes.Plp}
            element={(
              <DefaultSuspense>
                <PlpPage />
              </DefaultSuspense>
            )}
          />
          <Route
            path={AppRoutes.NotAuthorized}
            element={(
              <DefaultSuspense>
                <NotAuthorized />
              </DefaultSuspense>
            )}
          />
        </Routes>
      </Box>
    </Box>
  );
};
