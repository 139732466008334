import * as Yup from 'yup';
import { PageType } from 'common/enums/page-type.enum';

const stringShouldNotBeEmpty = 'This field is required, must be a non empty string';
const valueMustBeString = 'The value must be a string';
const booleanShouldBeSet = 'This field is required, must be a boolean value';
const dateShouldBeSet = 'Date should be set';
const valueMustBeDate = 'The value must be a date';
const activeToMustBeAfterActiveFrom = 'Valid to must be after Valid from';
const altTextMinimumCharacterLengthError = 'Alt text should be at least 10 characters long';
const sourceArrayShouldNotBeEmpty = 'Source must contain at least one item';
const sourceIsRequired = 'Source is required';
const positionIsRequired = 'Position is required';
const valueMustBeNumber = 'The value must be a number';

export const secondaryBannerWidgetSchema = Yup.object().shape({
  name: Yup.string().min(1).required()
    .typeError(valueMustBeString)
    .required(stringShouldNotBeEmpty),
  sources: Yup.array().of(Yup.string())
    .min(1, sourceArrayShouldNotBeEmpty)
    .required(sourceIsRequired),
  position: Yup.number()
    .typeError(valueMustBeNumber)
    .when('pageType', {
      is: PageType.Plp,
      then: Yup.number().required(positionIsRequired),
    }),
  pageType: Yup.string().min(1)
    .typeError(valueMustBeString),
  activeFrom: Yup.date()
    .typeError(valueMustBeDate)
    .required(dateShouldBeSet),
  activeTo: Yup.date()
    .typeError(valueMustBeDate)
    .when('activeFrom', (activeFrom) => Yup.date().min(
      activeFrom,
      activeToMustBeAfterActiveFrom,
    ))
    .required(dateShouldBeSet),
  content: Yup.object({
    desktop: Yup.object().shape({
      available: Yup.boolean().required(booleanShouldBeSet),
      items: Yup.array().when('available', {
        is: true,
        then: Yup.array().of(Yup.object().shape({
          bannerName: Yup.string().required(stringShouldNotBeEmpty),
          src: Yup.string().typeError(stringShouldNotBeEmpty),
          url: Yup.string().required(stringShouldNotBeEmpty),
          altText: Yup.string().min(10, altTextMinimumCharacterLengthError),
        })),
        otherwise: Yup.array().of(Yup.object().shape({
          bannerName: Yup.string(),
          src: Yup.string(),
          url: Yup.string(),
          altText: Yup.string(),
        })),
      }),
    }),
    mobile: Yup.object().shape({
      available: Yup.boolean().required(booleanShouldBeSet),
      items: Yup.array().when('available', {
        is: true,
        then: Yup.array().of(Yup.object().shape({
          bannerName: Yup.string().required(stringShouldNotBeEmpty),
          src: Yup.string().required(stringShouldNotBeEmpty),
          url: Yup.string().required(stringShouldNotBeEmpty),
          altText: Yup.string().min(10, altTextMinimumCharacterLengthError),
        })),
        otherwise: Yup.array().of(Yup.object().shape({
          bannerName: Yup.string(),
          src: Yup.string(),
          url: Yup.string(),
          altText: Yup.string(),
        })),
      }),
    }),
  }),
});
