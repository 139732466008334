import * as styles from 'common/components/GenericModal/styles';
import {
  Box,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DEVICE_ACTIVATION_SWITCH_WARNING } from 'containers/HomePageContainer/components/PageConfigurator/constants';
import { DesktopSort, MobileSort, VideoTextFormData } from 'containers/HomePageContainer/types/video-text';
import { LocalizedButtonText } from 'common/components/RichEditer/Localized-Button-Label';
import { LocalizedTextField } from 'containers/HomePageContainer/utils/LocalizedTextField';
import { Switch } from 'common/components/Switch';
import { TextArea } from 'common/components/TextArea';
import { useFormikContext } from 'formik';
import { useGlobalAlertStore } from 'common/store/client/global-alert/use-global-alert.store';
import React, { FC, useState } from 'react';

interface DeviceData {
  title: string;
  key: 'desktop' | 'mobile';
}

interface FieldToggle {
  desktop: string;
  mobile: string;
}

const devicesData: DeviceData[] = [{
  title: 'Desktop',
  key: 'desktop',
}, {
  title: 'Mobile',
  key: 'mobile',
}];

const altTextToggle: FieldToggle = {
  desktop: 'en',
  mobile: 'en',
};

const titleToggle: FieldToggle = {
  desktop: 'en',
  mobile: 'en',
};

const buttonTextToggle: FieldToggle = {
  desktop: 'en',
  mobile: 'en',
};

const isCA = window.COUNTRY === 'CA';

export const VideoTextConfiguration: FC = () => {
  const { values, setFieldValue, errors } = useFormikContext<VideoTextFormData>();
  const openAlert = useGlobalAlertStore((store) => store.openAlert);
  const [altText, setAltText] = useState<FieldToggle>(altTextToggle);
  const [srcText, setSrcText] = useState<FieldToggle>(altTextToggle);
  const [titleText, setTitleText] = useState<FieldToggle>(titleToggle);
  const [buttonText, setButtonText] = useState<FieldToggle>(buttonTextToggle);
  const handleTextPositionChange = (val: string, key: string) => {
    setFieldValue(`content.${key}.textPosition`, val);
  };

  const setSelected = (checked: boolean, keySelected: string) => {
    setFieldValue(`content.${keySelected}.hasImageToggle`, checked);
    setFieldValue(`content.${keySelected}.src`, '');
    setFieldValue(`content.${keySelected}.altText`, '');
  };

  const handleAltTextLanguageChange = (key: string, newLang: string) => {
    setAltText((prevState) => ({
      ...prevState,
      [key]: newLang,
    }));
  };

  const handleSrcTextLanguageChange = (key: string, newLang: string) => {
    setSrcText((prevState) => ({
      ...prevState,
      [key]: newLang,
    }));
  };

  const handleTitleLanguageChange = (key: string, newLang: string) => {
    setTitleText((prevState) => ({
      ...prevState,
      [key]: newLang,
    }));
  };

  const handleButtonTextLanguageChange = (key: string, newLang: string) => {
    setButtonText((prevState) => ({
      ...prevState,
      [key]: newLang,
    }));
  };

  return (
    <Grid container spacing={10}>
      {devicesData.map(({ title, key }) => {
        const disabled = !values.content[key].available;
        const textDescription = values.content[key].description || '';
        const frTextDescription = values.content[key].frDescription || '';
        const imageWidgetToggleCheck = values.content[key].hasImageToggle;
        const urlLabelText = imageWidgetToggleCheck ? 'Image URL' : 'Video URL';

        return (
          <Grid key={key} item xs={6}>
            <Box flexDirection="column">
              <Switch
                name={`content.${key}.available`}
                label={title}
                labelPlacement="start"
                onChange={(event) => {
                  if (
                    event.target.checked
                    || (values.content.desktop.available && values.content.mobile.available)
                  ) {
                    setFieldValue(`content.${key}.available`, event.target.checked);
                  } else {
                    openAlert(DEVICE_ACTIVATION_SWITCH_WARNING);
                  }
                }}
                sx={styles.availabilitySwitch}
              />
              {!disabled
                && (
                  <>
                    <Switch name={`content.${key}.hasImageToggle`} label="Image" labelPlacement="start" sx={styles.hasImageToggle} onChange={(event) => setSelected(event.target.checked, key)} disabled={disabled} />
                    <LocalizedTextField
                      englishText={values.content[key].src || ''}
                      frenchText={values.content[key].frSrc || ''}
                      textLanguage={srcText[key]}
                      setTextLanguage={(newLang) => handleSrcTextLanguageChange(key, newLang)}
                      disabled={disabled}
                      label={urlLabelText}
                      onBlur={(event) => {
                        if (srcText[key]) {
                          setFieldValue(`content.${key}.src`, event.target.value);
                        } else {
                          setFieldValue(`content.${key}.frSrc`, event.target.value);
                        }
                      }}
                      errors={key === 'desktop' ? errors?.content?.desktop?.src : errors?.content?.mobile?.src}
                    />
                    <Box>
                      {imageWidgetToggleCheck && (
                        <Typography sx={styles.imageResolutionHint}>
                          Image resolution as 1136 x 640px
                        </Typography>
                      )}
                    </Box>
                    <LocalizedTextField
                      englishText={values.content[key].altText || ''}
                      frenchText={values.content[key].frAltText || ''}
                      textLanguage={altText[key]}
                      setTextLanguage={(newLang) => handleAltTextLanguageChange(key, newLang)}
                      disabled={disabled}
                      label="Alt Text"
                      onBlur={(event) => {
                        if (altText[key]) {
                          setFieldValue(`content.${key}.altText`, event.target.value);
                        } else {
                          setFieldValue(`content.${key}.frAltText`, event.target.value);
                        }
                      }}
                      errors={key === 'desktop' ? errors?.content?.desktop?.altText : errors?.content?.mobile?.altText}
                    />
                    <Typography variant="h6">Text Configuration</Typography>
                    <LocalizedTextField
                      englishText={values.content[key].title || ''}
                      frenchText={values.content[key].frTitle || ''}
                      textLanguage={titleText[key]}
                      setTextLanguage={(newLang) => handleTitleLanguageChange(key, newLang)}
                      disabled={disabled}
                      label="Title"
                      onBlur={(event) => {
                        if (titleText[key]) {
                          setFieldValue(`content.${key}.title`, event.target.value);
                        } else {
                          setFieldValue(`content.${key}.frTitle`, event.target.value);
                        }
                      }}
                      errors={key === 'desktop' ? errors?.content?.desktop?.title : errors?.content?.mobile?.title}
                    />
                    {isCA && (
                      <LocalizedButtonText labelText="En" />
                    )}
                    <Box sx={styles.videoTextContent}>
                      <TextArea className="textDescription" name={`content.${key}.description`} value={textDescription} disabled={disabled} minRows={8} placeholder="Description" onBlur={(event) => setFieldValue(`content.${key}.description`, event.target.value)} />
                    </Box>

                    {isCA && (
                      <>
                        <LocalizedButtonText labelText="Fr" />
                        <Box sx={styles.videoTextContent}>
                          <TextArea className="textDescription" name={`content.${key}.frDescription`} value={frTextDescription} disabled={disabled} minRows={8} placeholder="Description" onBlur={(event) => setFieldValue(`content.${key}.frDescription`, event.target.value)} />
                        </Box>
                      </>
                    )}
                    <LocalizedTextField
                      englishText={values.content[key].buttonText || ''}
                      frenchText={values.content[key].frButtonText || ''}
                      textLanguage={buttonText[key]}
                      setTextLanguage={(newLang) => handleButtonTextLanguageChange(key, newLang)}
                      disabled={disabled}
                      label="Button Text"
                      onBlur={(event) => {
                        if (buttonText[key]) {
                          setFieldValue(`content.${key}.buttonText`, event.target.value);
                        } else {
                          setFieldValue(`content.${key}.frButtonText`, event.target.value);
                        }
                      }}
                      errors={key === 'desktop' ? errors?.content?.desktop?.buttonText : errors?.content?.mobile?.buttonText}
                    />

                    <TextField name={`content.${key}.buttonLink`} disabled={disabled} label="Button Link" sx={styles.textField} />
                  </>
                )}
              {key === 'desktop' && !disabled
                && (
                  <Select name={`content.${key}.textPosition`} defaultValue={values.content[key].textPosition ? values.content[key].textPosition : DesktopSort.Right} disabled={disabled} label="textPosition" sx={styles.textField} onChange={(event) => handleTextPositionChange(event.target.value, key)}>
                    <MenuItem value={DesktopSort.Right}>{DesktopSort.Right}</MenuItem>
                    <MenuItem value={DesktopSort.Left}>{DesktopSort.Left}</MenuItem>
                  </Select>
                )}
              {key === 'mobile' && !disabled
                && (
                  <Select name={`content.${key}.textPosition`} defaultValue={values.content[key].textPosition ? values.content[key].textPosition : MobileSort.Bottom} disabled={disabled} label="textPosition" sx={styles.textField} onChange={(event) => handleTextPositionChange(event.target.value, key)}>
                    <MenuItem value={MobileSort.Bottom}>{MobileSort.Bottom}</MenuItem>
                    <MenuItem value={MobileSort.Top}>{MobileSort.Top}</MenuItem>
                  </Select>
                )}
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
