import { SxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { theme } from 'utils/theme';

export const Container = styled('div')({
  display: 'flex',
  width: '100%',
  height: '75vh',
  flexDirection: 'column',
});

export const Content = styled('div')({
  flexGrow: 1,
  overflowY: 'auto',
  padding: theme.spacing(1, 1, 11.25),
});

export const ActionButtons = styled('div')({
  display: 'flex',
  position: 'absolute',
  left: '50%',
  bottom: '20px',
  zIndex: 2,
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  background: theme.palette.common.white,
  borderRadius: 16,
  transform: 'translateX(-50%)',
  '& > .MuiButton-root:not(:first-of-type)': {
    marginLeft: theme.spacing(2),
  },
});

export const Image = styled('img')({
  width: '136px',
  height: '136px',
});

export const dateTimePicker: SxProps = {
  mt: 3,
  '&:not(:first-of-type)': {
    ml: 2,
  },
};

export const divider: SxProps = {
  my: 3.75,
};

export const availabilitySwitch: SxProps = {
  ml: 0,
  pb: 2,
  width: '100%',
  justifyContent: 'flex-end',
};

export const hasGiftFinder: SxProps = {
  flexDirection: 'row',
  marginLeft: 0,
  marginBottom: '20px',
  marginTop: '10px',
};

export const hasImageToggle: SxProps = {
  flexDirection: 'row',
  marginLeft: 0,
  marginBottom: '20px',
  marginTop: '10px',
};

export const imageResolutionHint: SxProps = {
  fontSize: '12px',
  marginBottom: '20px',
  marginTop: '-20px',
  textAlign: 'right',
};

export const textField: SxProps = {
  my: 1,
  width: '100%',
  height: '56px',
  pb: 2,
};
export const editorContainer: SxProps = {
  margin: '0 auto',
  width: '100%',
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: '5px',
};

export const validatedEditorContainer: SxProps = {
  ...editorContainer,
  border: '1px solid #d23f2f',
};

export const editorWrapperClass = {
  '& .editor': {
    backgroundColor: 'lightgray',
    padding: '0px',
    borderRadius: '4px',
    '& .sun-editor': {
      '& .se-container': {
        '& .se-wrapper': {
          '& .se-placeholder': {
            fontSize: '20px',
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
          },
          '& .se-wrapper-inner': {
            height: 'auto !important',
            fontSize: '16px',
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
          },
        },
      },
    },
    '& .public-DraftEditorPlaceholder-root': {
      margin: '0px',
      padding: '8px',
      '& .public-DraftEditorPlaceholder-inner': {
        fontSize: '20px',
        color: '#00000060',
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      },
    },
    '& .public-DraftEditor-content': {
      padding: '8px',
      margin: '0px',
    },
    '& .public-DraftStyleDefault-ltr': {
      margin: 0,
      fontSize: '20px',
    },
  },
};
export const editorValidationMessage = {
  color: '#d32f2f',
  fontWeight: 400,
  fontSize: '0.75rem',
  marginLeft: '14px',
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  marginTop: '3px',
  lineHeight: '1.66',
  letterSpacing: '0.03333em',
};

export const colorPicker: SxProps = {
  zIndex: 999,
  position: 'relative',
  top: '5px',
};

export const colorBox: SxProps = {
  my: 1,
  height: '56px',
  width: '150px',
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: '5px',
  ml: 2,
};

export const getAddButton = (isError: boolean): SxProps => ({
  padding: theme.spacing(1.5),
  borderRadius: 4,
  border: `1px dashed ${theme.palette.divider}`,
  color: isError ? theme.palette.common.white : '#00000099',
  '&:hover': {
    border: `1px dashed ${theme.palette.primary.main}`,
  },
});

export const widgetOutline: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  position: 'relative',
  padding: '16px 12px',
  border: '2px solid rgba(0, 0, 0, 0.23)',
};

export const closeWidgetButton: SxProps = {
  position: 'absolute',
  top: '-16px',
  right: '-32px',
  padding: '0px',
};

export const pageSelectWrapper: SxProps = {
  display: 'flex',
  '& #mui-component-select-Pages': {
    padding: '12px',
  },
  '& legend span': {
    paddingRight: '25px',
  },
};

export const videoTextContent: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: '8px',
  marginBottom: '8px',
  paddingBottom: '16px',
  '& .textDescription': {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.4375em',
    letterSpacing: '0.00938em',
    color: '#00000087',
    boxSizing: 'border-box',
    position: 'relative',
    cursor: 'text',
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '4px',
    background: 'none',
  },
};
