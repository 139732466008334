import { SecondaryBanner, SecondaryBannerFormData } from 'containers/HomePageContainer/types/secondary-banner';
import { convertDaylightSavingsTimeToDefaultTime, convertUtcToDefaultZone } from 'utils/date';
import { deviceContentDefaultInitialValues } from 'containers/HomePageContainer/components/PageConfigurator/SecondaryBannerModalContent/default-initial-values';

export const mapWidgetDataToFormInitialValues = (
  data: SecondaryBanner,
): SecondaryBannerFormData => {
  const desktopItems = data.content.desktop.items.length
  && data.content.desktop.items[0].bannerName.length > 0
    ? data.content.desktop.items.map((item, index) => ({ ...item, sequence: index }))
    : deviceContentDefaultInitialValues.items;

  const mobileItems = data.content.mobile.items.length > 0
  && data.content.mobile.items[0].bannerName.length > 0
    ? data.content.mobile.items.map((item, index) => ({ ...item, sequence: index }))
    : deviceContentDefaultInitialValues.items;

  return {
    name: data.name,
    activeFrom: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeFrom)),
    activeTo: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeTo)),
    sources: data.sources,
    position: data.position,
    pageType: data.pageType,
    content: {
      desktop: {
        available: data.content.desktop.available,
        items: desktopItems,
      },
      mobile: {
        available: data.content.mobile.available,
        items: mobileItems,
      },
    },
  };
};
