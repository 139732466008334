export enum DndNotificationBannerFieldType {
  BannerText = 'BannerText',
  FrBannerText = 'FrBannerText',
  FrButtonText = 'FrButtonText',
  BannerBGColor = 'BannerBGColor',
  ButtonBGColor = 'ButtonBGColor',
  ButtonText = 'ButtonText',
  ButtonCTALink = 'buttonCTALink',
  IsDeliveryCutoffTime = 'IsDeliveryCutoffTime',
  IsPromoMessage = 'IsPromoMessage',
  CutoffTime = 'CutoffTime',
  PromoEndTime = 'PromoEndTime',
  IsValid = 'IsValid',
  SubmitFormCallback = 'SubmitFormCallback',
  Sources = 'Sources',
}
