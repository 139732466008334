import { HomePageWidgetType } from 'containers/HomePageContainer/enum/component.enum';
import { HomePageWidgetTypes } from 'containers/HomePageContainer/types/component.type';
import { ModalContentType } from 'common/enums/modal-content-type';

const widgetTypeToModalTypeMap: Partial<Record<HomePageWidgetType, ModalContentType>> = {
  [HomePageWidgetType.CarouselBanners]: ModalContentType.ModifyHeroBannerWidget,
  [HomePageWidgetType.CarouselDetailed]: ModalContentType.ModifyProductCarouselWidget,
  [HomePageWidgetType.Banner]: ModalContentType.ModifyBannerWidget,
  [HomePageWidgetType.SecondaryBanner]: ModalContentType.ModifySecondaryBannerWidget,
  [HomePageWidgetType.CarouselSimplified]: ModalContentType.ModifyShopByOccasionWidget,
  [HomePageWidgetType.GridWidget]: ModalContentType.ModifyShopByCategoryWidget,
  [HomePageWidgetType.ChipWidget]: ModalContentType.ModifyChipWidget,
  [HomePageWidgetType.GeneralTextWidget]: ModalContentType.ModifyGeneralTextWidget,
  [HomePageWidgetType.NotificationBannerWidget]: ModalContentType.ModifyNotificationBannerWidget,
  [HomePageWidgetType.BVSocial]: ModalContentType.ModifyBVSocialWidget,
  [HomePageWidgetType.BannerWithProductList]: ModalContentType.ModifyBannerWithProductListWidget,
  [HomePageWidgetType.VideoWithText]: ModalContentType.ModifyVideoWithTextWidget,
};

export const getEditModalType = (
  widgetType?: HomePageWidgetTypes,
): ModalContentType => (
  widgetType ? widgetTypeToModalTypeMap[widgetType] || ModalContentType.Empty
    : ModalContentType.Empty
);
