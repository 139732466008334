import {
  Box,
  FormHelperText,
  ListItemText,
  SxProps,
} from '@mui/material';
import { useField } from 'formik';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import React, { FC } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface PositionOptionsFieldProps {
  name: string;
  label?: string;
  sx?: SxProps;
}

const positionOptions = [
  { value: 1, label: 'Start' },
  { value: 2, label: 'Bottom' },
];

export const PositionSelect: FC<PositionOptionsFieldProps> = ({
  label, sx, ...props
}) => {
  const [field, meta, helpers] = useField<string[]>(props);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];
    helpers.setValue(value);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
  };

  return (
    <Box>
      <FormControl sx={{ mt: 3, ml: 2, minWidth: '150px' }} error={!!meta.error}>
        <InputLabel>Position</InputLabel>
        <Select
          {...field}
          name="position"
          label="Position"
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {positionOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {meta.touched && meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
    </Box>
  );
};

PositionSelect.defaultProps = {
  label: '',
  sx: {},
};
