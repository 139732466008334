import { NotificationBannerFormData } from 'containers/HomePageContainer/types/notification-banner';
import { useInitCommonValues } from 'utils/hooks/use-init-common-values';

export const useDefaultInitialValues = (): NotificationBannerFormData => ({
  ...useInitCommonValues(),
  autoSlideIntervalDesktop: 0,
  autoSlideIntervalMobile: 0,
  pageDesktop: ['Home Page'],
  pageMobile: ['Home Page'],
});
