import { SxProps } from '@mui/material';
import { theme } from 'utils/theme';

export const item: SxProps = {
  padding: theme.spacing(1, 1, 2),
  border: '2px solid transparent',
  borderRadius: 4,
  transition: 'border-color .2s',
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
};
