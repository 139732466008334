import { VideoTextFormData } from 'containers/HomePageContainer/types/video-text';
import { desktopContentDefaultInitialValues, mobileContentDefaultInitialValues } from 'containers/HomePageContainer/components/PageConfigurator/VideoWithTextModalContent/default-initial-values';
import { useInitCommonValues } from 'utils/hooks/use-init-common-values';

export const useDefaultInitialValues = (): VideoTextFormData => ({
  ...useInitCommonValues(),
  content: {
    desktop: desktopContentDefaultInitialValues,
    mobile: mobileContentDefaultInitialValues,
  },
});
