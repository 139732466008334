export enum DraggableItemType {
  PageConfigWidget,
  HeroBannerWidget,
  SecondaryBannerWidget,
  OccasionWidget,
  CategoryWidget,
  BubbleTextWidget,
  NotificationBannerWidget,
  SkinnyBannerWidget,
}

export type DraggableItemTypes = typeof DraggableItemType[keyof typeof DraggableItemType];
