import { BubbleTextFormData, CreateBubbleTextData, UpdateBubbleTextData } from 'containers/HomePageContainer/types/bubble-text';
import { DraggableBubbleTextCard } from 'containers/DndStackContainer/types';
import { HomePageWidgetType } from 'containers/HomePageContainer/enum/component.enum';
import { Locale } from 'common/enums/locale';
import { PageType } from 'common/enums/page-type.enum';
import { convertDefaultTimeToDaylightSavingsTime, convertDefaultZoneToUtc } from 'utils/date';

const deviceItemsMapper = (bubbleText: DraggableBubbleTextCard, index: number) => ({
  id: bubbleText.id!,
  sequence: index,
  title: bubbleText.title!,
  frTitle: bubbleText.frTitle!,
  url: bubbleText.link!,
});

export const mapBubbleTextFormDataToRequest = (
  data: BubbleTextFormData,
  pageTypeValue: PageType,
  desktopBubbleText: DraggableBubbleTextCard[],
  mobileBubbleText: DraggableBubbleTextCard[],
  culture?: Locale,
): CreateBubbleTextData | UpdateBubbleTextData => ({
  activeFrom: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeFrom)),
  activeTo: convertDefaultZoneToUtc(convertDefaultTimeToDaylightSavingsTime(data.activeTo)),
  name: data.name,
  sources: data.sources,
  position: data.position,
  pageType: pageTypeValue,
  eventName: data.name,
  type: HomePageWidgetType.ChipWidget,
  ...(culture ? { culture } : null),
  content: {
    desktop: {
      available: data.isActiveDesktop,
      title: data.bubbleTextTitleDesktop,
      frTitle: data.bubbleTextFrTitleDesktop,
      items: desktopBubbleText.map(deviceItemsMapper),
    },
    mobile: {
      available: data.isActiveMobile,
      title: data.bubbleTextTitleMobile,
      frTitle: data.bubbleTextFrTitleMobile,
      items: mobileBubbleText.map(deviceItemsMapper),
    },
  },
});
