import { DraggableSkinnyCard, DraggableSkinnyContentCard } from 'containers/DndSkinnyContainer/types';
import { SkinnyBanner, SkinnyBannerFormData } from 'containers/SkinnyBannerContainer/types/skinny-banner';
import { convertDaylightSavingsTimeToDefaultTime, convertUtcToDefaultZone } from 'utils/date';
import { v4 as uuidv4 } from 'uuid';

const deviceItemsMapper = (skinny: DraggableSkinnyCard, index: number) => ({
  id: skinny.bannerBackgroundColor && skinny.id ? skinny.id : uuidv4(),
  sequence: index,
  editorContent: skinny.editorContent!,
  frEditorContent: skinny.frEditorContent!,
  url: skinny.url!,
});

const deviceContentMapper = (content: DraggableSkinnyContentCard) => ({
  items: content.items?.map(deviceItemsMapper),
  available: content.available,
  autoSlideInterval: content.autoSlideInterval,
  bannerBackgroundColor: content.items?.length
    && content.items[0].bannerBackgroundColor
    ? content.items[0].bannerBackgroundColor : content.bannerBackgroundColor,
});

export const mapWidgetDataToFormInitialValues = (
  data: SkinnyBanner,
): SkinnyBannerFormData => ({
  name: data.name,
  overrideTag: data.overrideTag,
  activeFrom: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeFrom)),
  activeTo: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeTo)),
  sources: ['organic'],
  content: {
    desktop: deviceContentMapper(data.content.desktop || []),
    mobile: deviceContentMapper(data.content.mobile || []),
  },
});
