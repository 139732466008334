import { DraggableNotificationBannerCard } from 'containers/DndStackContainer/types';
import {
  NotificationBannerDeviceDataItem,
  NotificationBannerFormData,
  NotificationBannerWidget,
} from 'containers/HomePageContainer/types/notification-banner';
import { convertDaylightSavingsTimeToDefaultTime, convertUtcToDefaultZone } from 'utils/date';

export const mapItemsToInitialValues = (
  items: NotificationBannerDeviceDataItem[] = [],
): DraggableNotificationBannerCard[] => (items || []).map((item) => ({
  id: item.id,
  bannerText: item.bannerText,
  frBannerText: item.frBannerText,
  frButtonText: item.frButtonText,
  bannerBGColor: item.bannerBGColor,
  buttonBGColor: item.buttonBGColor,
  buttonText: item.buttonText,
  buttonCTALink: item.buttonCTALink,
  isDeliveryCutoffTime: item.isDeliveryCutoffTime,
  isPromoMessage: item.isPromoMessage,
  cutoffTime: Number(item.cutoffTime),
  promoEndTime: item.promoEndTime,
  isValid: true,
  sources: ['organic'],
}));

export const mapWidgetDataToFormInitialValues = (
  data: NotificationBannerWidget,
): NotificationBannerFormData => ({
  name: data.name,
  activeFrom: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeFrom)),
  activeTo: convertUtcToDefaultZone(convertDaylightSavingsTimeToDefaultTime(data.activeTo)),
  isActiveDesktop: Boolean(data.content?.desktop?.available),
  isActiveMobile: Boolean(data.content?.mobile?.available),
  autoSlideIntervalDesktop: data.content?.desktop?.autoSlideInterval,
  autoSlideIntervalMobile: data.content?.mobile?.autoSlideInterval,
  pageDesktop: data.content?.desktop?.page,
  pageMobile: data.content?.mobile?.page,
  sources: ['organic'],
});
