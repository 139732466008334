import { ToggleButton, styled } from '@mui/material';
import React, { FC } from 'react';

export interface LocalizedButtonTextProp {
  labelText: string;
}

export const LocalizedButtonText: FC<LocalizedButtonTextProp> = ({
  labelText,
}) => {
  const LocalizedButtonLabel = styled(ToggleButton)({
    textTransform: 'none',
    width: '15%',
    marginTop: '20px',
    backgroundColor: '#fafafa',
  });
  const displayText = labelText === 'Fr' ? 'French' : 'English';

  return (
    <LocalizedButtonLabel value={displayText}>
      {displayText}
    </LocalizedButtonLabel>
  );
};
