import * as Yup from 'yup';
import { Sort } from 'containers/HomePageContainer/types/product-carousel';

const stringShouldNotBeEmpty = 'This field is required, must be a non empty string';
const valueMustBeString = 'The value must be a string';
const valueMustBeNumber = 'This field must be a number 0 or greater';
const valueMustBeBoolean = 'This field must be a boolean value';
const booleanShouldBeSet = 'This field is required, must be a boolean value';
const dateShouldBeSet = 'Date should be set';
const valueMustBeDate = 'The value must be a date';
const sortShouldBeSet = 'Sort method value should be set';
const activeToMustBeAfterActiveFrom = 'Valid to must be after Valid from';
const sourceArrayShouldNotBeEmpty = 'Source must contain at least one item';
const sourceIsRequired = 'Source is required';

const conditionOptions = {
  is: true,
  then: Yup.string().required(stringShouldNotBeEmpty),
};

const deviceContentSchema = Yup.object({
  available: Yup.boolean()
    .typeError(valueMustBeBoolean)
    .required(booleanShouldBeSet),
  src: Yup.string()
    .typeError(valueMustBeString)
    .when('available', conditionOptions),
  ctaLink: Yup.string()
    .typeError(valueMustBeString)
    .when('available', conditionOptions),
  altText: Yup.string()
    .typeError(valueMustBeString)
    .when('available', conditionOptions),
  url: Yup.string()
    .typeError(valueMustBeString)
    .when('available', conditionOptions),
  filterParams: Yup.object()
    .when('available', {
      is: true,
      then: Yup.object({
        limit: Yup.number().min(0)
          .typeError(valueMustBeNumber)
          .required(valueMustBeNumber),
        sort: Yup.mixed()
          .oneOf(Object.values(Sort))
          .required(sortShouldBeSet),
      }),
      otherwise: Yup.object({
        limit: Yup.number().min(0)
          .typeError(valueMustBeNumber),
        sort: Yup.mixed()
          .oneOf(Object.values(Sort)),
      }),
    }),
});

export const productCarouselWidgetSchema = Yup.object().shape({
  name: Yup.string().min(1).required()
    .typeError(valueMustBeString)
    .required(stringShouldNotBeEmpty),
  activeFrom: Yup.date()
    .typeError(valueMustBeDate)
    .required(dateShouldBeSet),
  activeTo: Yup.date()
    .typeError(valueMustBeDate)
    .when('activeFrom', (activeFrom) => Yup.date().min(
      activeFrom,
      activeToMustBeAfterActiveFrom,
    ))
    .required(dateShouldBeSet),
  sources: Yup.array().of(Yup.string())
    .min(1, sourceArrayShouldNotBeEmpty)
    .required(sourceIsRequired),
  content: Yup.object({
    desktop: deviceContentSchema,
    mobile: deviceContentSchema,
  }),
});
