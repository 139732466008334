import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import * as styles from 'common/components/GenericModal/styles';
import {
  Box,
  Grid,
} from '@mui/material';
import { DEVICE_ACTIVATION_SWITCH_WARNING } from 'containers/HomePageContainer/components/PageConfigurator/constants';
import { GeneralTextWidgetFormData } from 'containers/HomePageContainer/types/general-text-widgets';
import { LocalizedButtonText } from 'common/components/RichEditer/Localized-Button-Label';
import { LocalizedTextField } from 'containers/HomePageContainer/utils/LocalizedTextField';
import { RichEditor } from 'containers/HomePageContainer/components/PageConfigurator/generalTextModalContent/Editor';
import { Switch } from 'common/components/Switch';
import { useFormikContext } from 'formik';
import { useGlobalAlertStore } from 'common/store/client/global-alert/use-global-alert.store';
import React, { FC, useState } from 'react';

interface DeviceData {
  title: string;
  frTitle: string;
  key: 'desktop' | 'mobile';
}

const devicesData: DeviceData[] = [{
  title: 'Desktop',
  frTitle: 'Desktop',
  key: 'desktop',
}, {
  title: 'Mobile',
  frTitle: 'Mobile',
  key: 'mobile',
}];

interface FieldToggle {
  desktop: string;
  mobile: string;
}

const titleTextToggle: FieldToggle = {
  desktop: 'en',
  mobile: 'en',
};

export const GeneralTextWidgetConfiguration: FC = () => {
  const { values, setFieldValue, errors } = useFormikContext<GeneralTextWidgetFormData>();
  const openAlert = useGlobalAlertStore((store) => store.openAlert);
  const isCA = window.COUNTRY === 'CA';
  const [titleText, setTitleText] = useState<FieldToggle>(titleTextToggle);

  const handleTitleTextLanguageChange = (key: string, newLang: string) => {
    setTitleText((prevState) => ({
      ...prevState,
      [key]: newLang,
    }));
  };

  return (
    <Grid container spacing={10}>
      {devicesData.map(({ title, key }) => {
        const disabled = !values.content[key].available;
        const d1 = !(values.content[key].title !== '');
        const d2 = !(values.content[key].accordian);
        return (
          <Grid key={key} item xs={6}>
            <Box display="flex" flexDirection="column">
              <Switch
                name={`content.${key}.available`}
                label={title}
                labelPlacement="start"
                onChange={(event) => {
                  if (
                    event.target.checked
                    || (values.content.desktop.available && values.content.mobile.available)
                  ) {
                    setFieldValue(`content.${key}.available`, event.target.checked);
                  } else {
                    openAlert(DEVICE_ACTIVATION_SWITCH_WARNING);
                  }
                }}
                sx={styles.availabilitySwitch}
              />
              <LocalizedTextField
                label="Title"
                englishText={values.content[key].title || ''}
                frenchText={values.content[key].frTitle || ''}
                textLanguage={titleText[key]}
                setTextLanguage={(newLang) => handleTitleTextLanguageChange(key, newLang)}
                onBlur={(event) => {
                  if (titleText[key] === 'en') {
                    setFieldValue(`content.${key}.title`, event.target.value);
                  } else {
                    setFieldValue(`content.${key}.frTitle`, event.target.value);
                  }
                  if (!(values.content[key].title !== '') || !(values.content[key].frTitle !== '')) {
                    setFieldValue(`content.${key}.accordian`, false);
                    setFieldValue(`content.${key}.expandAccordian`, false);
                  }
                }}
                variant="outlined"
                disabled={disabled}
                errors={key === 'desktop' ? errors?.content?.desktop?.title : errors?.content?.mobile?.title}
              />
              <Box display="flex" flexDirection="row">
                <Switch
                  name={`content.${key}.accordian`}
                  label="accordian"
                  labelPlacement="start"
                  onChange={(event) => {
                    setFieldValue(`content.${key}.accordian`, event.target.checked);
                  }}
                  sx={styles.availabilitySwitch}
                  disabled={d1 || disabled}
                />
                <Switch
                  name={`content.${key}.expandAccordian`}
                  label="expandAccordian"
                  labelPlacement="start"
                  onChange={(event) => {
                    setFieldValue(`content.${key}.expandAccordian`, event.target.checked);
                  }}
                  sx={styles.availabilitySwitch}
                  disabled={d2 || disabled}
                />
              </Box>
              {key === 'mobile' && !disabled
                && (
                  <>
                    {isCA
                && (
                  <LocalizedButtonText labelText="En" />
                )}
                    <RichEditor
                      formKey={key}
                      contentFieldName="body"
                      textEditorValue={setFieldValue}
                      contentData={values.content.mobile.body || ''}
                    />
                    {isCA
                && (
                  <>
                    <LocalizedButtonText labelText="Fr" />
                    <RichEditor
                      formKey={key}
                      contentFieldName="frBody"
                      textEditorValue={setFieldValue}
                      contentData={values.content.mobile.frBody || ''}
                    />
                  </>
                )}
                  </>
                )}
              {key === 'desktop' && !disabled
                && (
                  <>
                    {isCA
                && (
                  <LocalizedButtonText labelText="En" />
                )}
                    <RichEditor
                      formKey={key}
                      contentFieldName="body"
                      textEditorValue={setFieldValue}
                      contentData={values.content.desktop.body || ''}
                    />
                    {isCA
                && (
                  <>
                    <LocalizedButtonText labelText="Fr" />
                    <RichEditor
                      formKey={key}
                      contentFieldName="frBody"
                      textEditorValue={setFieldValue}
                      contentData={values.content.desktop.frBody || ''}
                    />
                  </>
                )}
                  </>
                )}
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
